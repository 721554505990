








































































import { Component, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';

@Component({
    components: {
    },
})
export default class PriceIndex extends Vue {
    @State((state) => state.common.agentConfig) agentConfig: any;

    form: any = {};

    addRules: any = {
        freeTime: {
            required: true,
            pattern: /^(0|[1-9][0-9]*)$/,
            message: this.$t('setting.price.freeTimeTip'),
        },
        pricePeriod: {
            required: true,
            message: this.$t('setting.price.pricePeriodTip'),
        },
        unitPrice: {
            required: true,
            pattern: /^(0|[1-9][0-9]*)(\.[0-9]+)?$/,
            message: this.$t('setting.price.commonTip'),
        },
        dayCappedPrice: {
            required: true,
            pattern: /^(0|[1-9][0-9]*)(\.[0-9]+)?$/,
            message: this.$t('setting.price.commonTip'),
        },
        cappedPrice: {
            required: true,
            pattern: /^(0|[1-9][0-9]*)(\.[0-9]+)?$/,
            message: this.$t('setting.price.commonTip'),
        },
    };

    created() {
        this.getPrice();
    }

    async getPrice() {
        const { error, data } = await this.$request.get('/mapi/manager/price/query-agent-price');
        if (!error && data) {
            this.form = { ...data };
        }
    }

    async add() {
        let url = '/mapi/manager/price/create-agent-price';
        if (this.form.id) {
            url = '/mapi/manager/price/update-agent-price';
        }
        this.$mtd.confirm({
            title: this.$t('setting.price.tip') as string,
            message: this.$t('setting.price.saveTip') as string,
            okButtonText: this.$t('common.confirm') as string,
            onOk: async () => {
                const { error, data } = await this.$request.post(url, { ...this.form });
                if (!error && data) {
                    this.$mtd.message.success(this.$t('setting.price.saveSuccess') as string);
                    this.getPrice();
                }
            },
        });
    }
}
