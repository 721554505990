


























































import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
    },
})
export default class LoginIndex extends Vue {
    loginForm: any = {
        account: '',
        password: '',
    }

    passwordType: string = 'password';

    year = (new Date()).getFullYear();

    get passwordSuffix() {
        return this.passwordType === 'password' ? 'mtdicon mtdicon-visibility-on-o' : 'mtdicon mtdicon-visibility-off-o';
    }

    changePasswordType() {
        this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
    }

    async handleSubmit() {
        const { account, password } = this.loginForm;
        if (!account || !password) {
            this.$mtd.message.error(this.$t('login.errorTip') as any);
            return;
        }
        const { data } = await this.$request.post('/mapi/manager/login/manager-login', {
            ...this.loginForm,
        });
        if (data === true) {
            if (window.history.length > 1) {
                window.location.href = '/';
            }
        }
    }
}
