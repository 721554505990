export default {
    common: {
        menu: {
            agent: 'Agent',
            agentList: 'Agent Management',
            EmployeeManagement: 'Employee Management',
            store: 'Store',
            storeList: 'Store List',
            storeType: 'Store Type',
            collapseText: 'Collapse',
            storeAccount: 'Merchant Account',
            permit: 'Permissions',
            permitList: 'Permissions List',
            device: 'Device',
            asset: 'Device List',
            bill: 'Settlement',
            benefit: 'Order Profit Sharing',
            cashout: 'Withdrawal Records',
            orderManage: 'Order Management',
            orderList: 'Order List',
            setting: 'General Configuration',
            faq: 'FAQ',
            material: 'Display Materials',
            price: 'Price Template',
        },
        login: {
            name: 'Power Bank Backend Management System',
            title: 'Login',
            placehloder: {
                username: 'Please enter account/phone number',
                password: 'Please enter password',
            },
            errorTip: 'Please enter your account/phone number and password',
            logout: 'Logout',
        },
        selectPlaceholder: 'Please select',
        emptyText: 'No data available',
        pleaseFillInCompletely: 'Please fill in completely',
        confirm: 'Confirm',
    },
    agentCreate: {
        agentBasicInfo: 'Agent Basic Information',
        agentName: 'Agent Name',
        principalName: 'Person in Charge Name',
        phoneNumber: 'Phone Number',
        email: 'Email',
        detailedAddress: 'Detailed Address',
        agentInfo: 'Agent Information',
        contractFile: 'Contract Documents',
        clickUpload: 'Click to upload',
        chargeInfo: 'Fee information',
        headCompanyProfit: 'Head office profit sharing',
        bankAccountInfo: 'Bank account information',
        bankName: 'Bank name',
        branchName: 'Bank branch',
        bankType: 'Bank type',
        ordinary: 'Normal',
        savings: 'Savings',
        bankAccount: 'Bank account',
        accountName: 'Title of account',
        cancel: 'Cancel',
        submit: 'Submit',
        pleaseEnterAgentName: 'Please enter the name of the agent',
        pleaseEnterPrincipalName: 'Please enter the name of the person in charge',
        pleaseEnterCorrectPhoneNumber: 'Please enter the correct phone number',
        pleaseEnterCorrectEmailFormat: 'Please enter the correct email format',
        pleaseEnterDetailedAddress: 'Please enter detailed address',
        pleaseEnterContractFile: 'Please enter the contract document',
        pleaseEnterBankName: 'Please enter the bank name',
        pleaseEnterBranchName: 'Please enter the branch name',
        pleaseEnterBankAccount: 'Please enter your bank account',
        pleaseEnterAccountName: 'Please enter the account name',
        pleaseFillInCompletely: 'Please fill in the form completely',
        pleaseSelectBankType: 'Please select bank type',
        addSuccess: 'Added successfully',
        parentName: 'Superior agent',
    },
    agentList: {
        agentId: 'Agent ID',
        agentName: 'Agent Name',
        agentLevel: 'Agent level',
        headCompany: 'Headquarters',
        agent: 'Agent',
        agentAccount: 'Agent account',
        principalName: 'Name of the person in charge',
        type: 'Agent type',
        query: 'Query',
        create: 'Establish',
        operation: 'Operation',
        modifyProfitInfo: 'Modify profit sharing information',
        headCompanyProfit: 'Head office profit sharing',
        cancel: 'Cancel',
        confirm: 'Determine',
        superiorAgentName: 'Name of superior agent',
        principalPhoneNumber: "Responsible person's mobile phone number",
        email: 'Mailbox',
        belongingPlace: 'Location:',
        headCompanyProfitRatio: 'Profit sharing ratio of the head office',
        bankName: 'Bank name',
        branchName: 'Bank branch',
        accountType: 'Account type',
        bankAccount: 'Bank account',
        accountName: 'Title of account',
        withdrawableAmount: 'Withdrawable amount',
        cooperationStatus: 'Collaboration status',
        updateTime: 'Update time',
        createTime: 'Creation time',
        pleaseEnterNumberWithTwoDecimalPlaces: 'Please enter a number with a maximum of two decimal places',
        modificationSuccess: 'Modified successfully',
        changeTip: 'Please enter an integer from 0 to 100',
        edit: 'Edit',
    },
    agentEdit: {
        download: 'Download the contract',
        saveTip: 'Edited successfully',
    },
    agentUserItem: {
        agentName: 'Agent Name',
        userName: 'user name',
        phoneNumber: 'Phone number:',
        email: 'mailbox',
        loginPassword: 'Login password',
        role: 'role',
        status: 'state',
        enable: 'Enable',
        disable: 'Disable',
        cancel: 'cancel',
        confirm: 'determine',
        pleaseEnteragentName: 'Please select an agent',
        pleaseEnterUserName: 'enter one user name',
        pleaseEnterPhoneNumber: 'Please enter your phone number',
        pleaseEnterEmail: 'Please enter your email address',
        pleaseEnterLoginPassword: 'Please enter a combination of at least 6 digits and letters',
        pleaseSelectRole: 'Please select a role',
        pleaseEnterStatus: 'Please enter the status',
        edit: 'edit',
        saveSuccess: 'Saved successfully',
        submitErrorTip: 'Please fill in the form completely',
    },
    agentUser: {
        belongingCooperator: 'Affiliated agent',
        loginAccount: 'Login Account',
        userName: 'User name',
        phoneNumber: 'Telephone number',
        role: 'Role',
        search: 'Search',
        reset: 'Reset',
        add: 'Add to',
        email: 'Mailbox',
        isCooperatorResponsiblePerson: 'Is the agent in charge',
        is: 'Whether',
        status: 'State',
        enableDisable: 'Enable Disable',
        operation: 'Operation',
        edit: 'Edit',
        resetPassword: 'Reset password',
        newPassword: 'New password',
        cancel: 'Cancel',
        confirm: 'Determine',
        addUser: 'New User',
        editUserInfo: 'Edit user information',
        resetSuccess: 'Reset successful',
        enable: 'Enable',
        disable: 'Disable',
        yes: 'Yes',
        no: 'No',
    },
    deviceAssetManagement: {
        device: 'Device SN',
        belongingSubject: 'Belonging subject',
        deviceType: 'Equipment type',
        businessStatus: 'Business status',
        belongingPersonnel: 'Asset ownership personnel',
        search: 'Search',
        reset: 'Reset',
        export: 'Export',
        batchOperation: 'Batch operation',
        cabinetChargingTreasure: 'Cabinet power bank',
        deviceStatus: 'Equipment status',
        locatedStore: 'Store location',
        storeBelonging: 'Store ownership subject',
        locatedCabinet: 'The cabinet where it is located',
        deviceInformation: 'Equipment information',
        view: 'See',
        circulationRecord: 'Circulation record',
        chargingTreasure: 'Power bank SN',
        positionNumber: 'Position number',
        electricity: 'Electricity level',
        chargingStatus: 'Charging status',
        faultStatus: 'Fault status',
        return: 'Return',
        refresh: 'Refresh',
        cabinet: 'Cabinet',
        networkStatus: 'Network status',
        lastOnlineTime: 'Last online time',
        inTreasureNumber: 'Number of Treasures in Warehouse',
        positionStatus: 'Position status',
        operation: 'Operation',
        popUp: 'Pop up',
        popUpAll: 'Pop up all',
        uploadFile: 'Upload files',
        clickUpload: 'Click to upload',
        downloadTemplate: 'Download Template',
        targetSubject: 'Target subject',
        operationPrompt: 'Operation tip: Only devices that have not been stocked are supported for storage, only inventory devices are supported for returning inventory, and devices that have been completed in transit to the store are supported for transfer.',
        cancel: 'Cancel',
        returnToWarehouse: 'Return to warehouse',
        deviceTransfer: 'Equipment transfer',
        batchWarehousing: 'Batch storage',
        operator: 'Operator',
        operationType: 'Operation type',
        operationTime: 'Operation time',
        status: 'State',
        popUpSuccess: 'Pop up successfully',
        batchErrorTip: 'Please upload SN and enter the target subject',
        batchReturnErrorTip: 'Please upload SN',
        warehouse: 'Warehouse',
        enRoute: 'On the way',
        enterShopFinish: 'Entering the store completed',
        lending: 'Renting in progress',
        userShop: 'Selling',
        userLoss: 'User loss',
        repairing: 'Under repair',
        loss: 'Equipment loss',
        scrap: 'Scrap',
        notExist: 'Non-existent',
        support: 'Bracket',
        unkown: 'Unknown',
        offline: 'Off-line',
        online: 'On-line',
        inCabin: 'In the warehouse',
        offCabin: 'Not in the warehouse',
        poiBelongEmp: 'Store belongs to BD',
        addrAble: 'Available space',
        addrDisable: 'Position closure',
        battery: 'Power bank',
        popOption: 'Bullet Treasure Options',
        popUnlock: 'Pop up rechargeable',
        popLock: 'Pop up non rechargeable',
        batchOperationSuccess: 'Operation successful',
        actionTitle: 'Operation tips',
        actionBtn: 'Determine',
    },
    permitIndex: {
        create: 'Establish',
        operation: 'Operation',
        edit: 'Edit',
        authority: 'Jurisdiction',
        addRoleInfo: 'Add role information',
        modifyRoleInfo: 'Modify role information',
        roleName: 'Role Name',
        roleIdentification: 'Role identification',
        roleDescription: 'Role description',
        cancel: 'Cancel',
        confirm: 'Determine',
        assignAuthority: 'Assign permissions',
        update: 'Update',
        serialNumber: 'Serial number',
        pleaseEnterRoleName: 'Please enter the role name',
        pleaseEnterRoleIdentification: 'Please enter the role identifier',
        pleaseFillInCompletely: 'Please fill in the form completely',
        saveSuccess: 'Saved successfully',
        updateSuccess: 'Update successful',
    },
    poiAccount: {
        merchantAccount: 'Merchant account',
        email: 'Mailbox',
        merchantName: 'Merchant Name',
        query: 'Query',
        savingsOrdinary: 'Savings are ordinary',
        operation: 'Operation',
        edit: 'Edit',
        resendPassword: 'Resend password',
        merchantInfoModification: 'Merchant information modification',
        account: 'Account number',
        bankName: 'Bank name',
        branchName: 'Bank branch',
        accountType: 'Account type',
        ordinary: 'Ordinary',
        savings: 'Savings',
        bankAccount: 'Bank account',
        accountName: 'Title of account',
        cancel: 'Cancel',
        confirm: 'Determine',
        cooperationStoreNumber: 'Number of cooperative stores',
        withdrawableAmount: 'Withdrawable amount',
        updateSuccess: 'Update successful',
        resendPasswordSuccess: 'Resend password successfully',
        pleaseEnterAccount: 'Please enter your account',
        pleaseEnterCorrectEmailFormat: 'Please enter the correct email account',
        pleaseEnterName: 'Please enter the merchant name',
        pleaseEnterBankName: 'Please enter the bank name',
        pleaseEnterBranchName: 'Please enter the branch name',
        pleaseEnterBankType: 'Please enter the account type',
        pleaseEnterBankAccount: 'Please enter the account name',
        pleaseEnterBankNumber: 'Please enter bank account number',
    },
    poiDetail: {
        basicInfo: 'Essential information',
        edit: 'Edit',
        cooperationInfo: 'Collaboration Information',
        remark: 'Remarks',
        inputTip: 'The input content should not exceed 50 characters',
        return: 'Return',
        saveChanges: 'Save changes',
        notYetOpen: 'Not yet open for business',
        suspendBusiness: 'Suspension of business',
        inBusiness: 'In operation',
        closed: 'Closed',
        unrecognizable: 'Unable to recognize',
        storeName: 'Store Name',
        businessStatus: 'Business status',
        personInChargePhone: 'Phone number of the person in charge',
        storeId: 'Store ID',
        businessHours: 'Business Hours',
        personInChargeEmail: 'Responsible person email',
        storeType: 'Store type',
        storeAddress: 'Store address',
        cooperationStatus: 'Collaboration status',
        merchantAccount: 'Merchant account',
        belongingAgent: 'Belonging to the agent',
        merchantEmail: 'Merchant email',
        belongingBd: 'Belonging to BD',
        prompt: 'Tips',
        saveTip: 'Is there information in the editing process? Are you sure you want to return it?',
        confirmReturn: 'Confirm return',
        saveSuccess: 'Saved successfully',
        required: 'Required',
        unvaild: 'Not in compliance with regulations',
        check: 'View details',
        license: 'Business license',
        contactName: 'Name of the person in charge',
        merchantName: 'Merchant Name',
    },
    poiList: {
        storeId: 'Store ID',
        belongingAgent: 'Belonging to the agent',
        query: 'Query',
        operation: 'Operation',
        basicInfo: 'Essential information',
        storeName: 'Store Name',
        storeType: 'Store type',
        businessStatus: 'Business status',
        businessHours: 'Business Hours',
        storeAddress: 'Store address',
        personInChargePhoneNumber: "Responsible person's mobile phone number",
        cooperationStatus: 'Collaboration status',
        belongingId: 'Belonging to BD',
        cabinetNumber: 'Number of cabinets',
        currentTreasureNumber: 'Current Treasure Count',
        updateTime: 'Update time',
        belongingBD: 'Belonging to BD',
        account: 'Merchant account',
        export: 'Export',
        exportSuccessTip: 'Export successful, please check your email',
        benefit: 'Profit sharing ratio',
        view: 'Check',
        contactName: 'Name of the person in charge',
        merchantName: 'Merchant Name',
        priceRule: 'Fee Rules',
        freeTime: 'Free duration: {num} minutes',
        pricePeriod: 'Billing cycle: {count} minutes',
        price: 'Price: {num} {unit}',
        dayCappedPrice: 'Tianfeng Peak Amount: {num} {unit}',
        cappedPrice: 'Deposit amount: {num} {unit}',
        poiBenefit: 'Store profit sharing ratio: {num}%',
    },
    poiType: {
        typeName: 'Type name',
        typeStatus: 'Type Status',
        disable: 'Disable',
        enable: 'Normal',
        query: 'Query',
        add: 'Add to',
        name: 'Name',
        remark: 'Remarks',
        status: 'State',
        disableEnable: 'Disable Enable',
        createTime: 'Creation time',
        operation: 'Operation',
        modify: 'Modify',
        storeTypeName: 'Store type name',
        cancel: 'Cancel',
        confirm: 'Determine',
        submitSuccess: 'Submitted successfully',
        addText: 'Newly added',
        editText: 'Edit',
        pleaseEnterTypeName: 'Please enter the store type name',
        pleaseEnterTypeStatus: 'Clear selection status',
    },
    benefitIndex: {
        paymentRefundDate: 'Payment refund date',
        selectDateRange: 'Select date range',
        orderNumber: 'Order Number',
        pleaseEnterOrderNumber: 'Please enter the order number',
        store: 'Store',
        search: 'Search',
        export: 'Export',
        billMonth: 'Billing month',
        billDate: 'Statement date',
        orderType: 'Order type',
        isCappedBilling: 'Is there a cap on billing',
        orderAmount: 'Order amount',
        storeName: 'Store Name',
        defaultAmount: 'Default amount',
        defaultTaxAmount: 'Default tax',
        rentTaxAmount: 'Rental tax',
        rentAmount: 'Rental amount',
        cooperationMode: 'Collaboration mode',
        merchantAccount: 'Merchant account',
        agent: 'Agent',
        agentName: 'Agent Name',
        profitSharingRatio: 'Profit sharing ratio',
        storeProfitSharingRatio: 'Store profit sharing ratio',
        headquartersProfitSharingRatio: 'Profit sharing ratio of the head office',
        defaultFeeProfitSharingAmount: 'Default fee MT profit sharing amount',
        defaultFeeHeadquartersProfitSharingAmount: 'Default fees: Total profit sharing amount of the head office',
        rentFeeProfitSharingAmount: 'Rental fee MT profit sharing amount',
        rentFeeHeadquartersProfitSharingAmount: 'Rental expenses: Profit sharing amount of the head office',
        rentFeeAgentParticipationSharingAmount: 'Rental fee agency participation sharing amount',
        rentFeeStoreProfitSharingAmount: 'Rental fees, store profit sharing amount',
        rentFeeAgentProfitSharingAmount: 'Rental fee agency profit sharing amount',
        headquartersProfitSharingAmount: 'Total profit sharing amount of the head office',
        headquartersAmount: 'Total profit sharing amount',
        cutDay: 'Payment/refund date',
    },
    cashoutList: {
        initiationDate: 'Date of initiation',
        selectDateRange: 'Select date range',
        merchantAccountAgent: 'Merchant Account Agent',
        pleaseEnterMerchantAccountAgent: 'Please enter merchant account ID/agent ID',
        payeeAccountName: 'Name of receiving account',
        pleaseEnterPayeeAccountName: 'Please enter the name of the receiving account',
        merchantAccountId: 'Merchant Account ID/Agent ID',
        merchantAccountName: 'Merchant account name/agent name',
        customerType: 'Customer type',
        merchant: 'Business',
        meituan: 'Meituan',
        agent: 'Agent',
        withdrawalInitiationTime: 'Withdrawal initiation time',
        merchantAccountNameAgentName: 'Merchant account name Agent name',
        taxAmount: 'Taxes',
        billAmount: 'Bill amount',
        withdrawalAmount: 'Withdrawal amount',
        bankName: 'Bank name',
        branchName: 'Branch Name',
        accountType: 'Account type',
        accountName: 'Bank account name',
        bankCardNumber: 'Bank card number',
        search: 'search',
        export: 'export',
        required: 'Required',
    },
    order: {
        orderList: {
            searchForm: {
                pleaseSelectPh: 'Please choose',
                pleaseInputPh: 'Please enter',
                orderNumber: 'Order Number',
                orderNumberPh: 'Please enter the order number',
                phoneNumber: 'Cell-phone number',
                orderStatus: 'State',
                depositType: 'Deposit type',
                userId: 'User ID',
                lendPoi: 'Renting POI',
                returnPoi: 'Return POI',
                batterySn: 'Bao SN',
                lendCabinSn: 'Renting cabinets',
                returnCabinSn: 'Return the cabinet',
                lendPoiName: 'Renting a store',
                returnPoiName: 'Return to store',
                createOrderTime: 'Order time',
                returnTime: 'Return time',
                search: 'Query',
                resetting: 'Reset',
                expand: 'Open',
                retract: 'Put it away',
                storeName: 'Store Name',
                merchantAccount: 'Merchant account',
                createTime: 'Creation time',
                updateTime: 'Update time',
                reset: 'Reset',
            },
            tableData: {
                orderId: 'Order Number',
                userId: 'User ID',
                createTime: 'Order time',
                returnTime: 'Return time',
                status: 'Order status',
                payMode: 'Deposit type',
                actualFee: 'Actual payment amount',
                lendPoiName: 'Rental land',
                returnPoiName: 'Return the land',
                hand: 'Operation',
                check: 'See',
            },
        },
        orderDetail: {
            artificialReturn: 'Artificial Treasure Return',
            lendTime: 'Pop up time',
            usageDuration: 'Duration of use',
            day: 'Day',
            hour: 'Hour',
            minute: 'Minute',
            second: 'Second',
            returnTime: 'Return time',
            chooseTime: 'Select time',
            returnCabin: 'Return the cabinet',
            returnCabinPd: 'Please enter the cabinet SN',
            returnPoiName: 'Return to store',
            remark: 'Remarks',
            actualAmountReceived: 'Actual received amount',
            return: 'Return',
            confirm: 'Confirm',
            cancel: 'Cancel',
            mustInput: 'Required',
            returnTimeRule: 'The return time must be less than the current time',
            artificialReturnConfirm: 'Manual return confirmation',
            artificialReturnConfirmDes: 'Are you sure to manually return the order',
            closeOrder: 'Customs clearance',
            closeOrderDesc: 'Close order',
            closeOrderConfirm: 'Are you sure to close the order?',
            stopCharge: 'Suspend billing',
            orderAmount: 'Order amount',
            suspendTime: 'Pause duration',
            stopTime24: '24 hours',
            stopChargeConfirm: 'Are you sure to suspend billing?',
            refund: 'Refund',
            originalFee: 'Original payment amount',
            canRefundFee: 'Refundable amount',
            refundFee: 'Refund amount',
            computedRefundFee: 'The refund amount must be greater than 0 yuan',
            refundConfirm: 'Refund Confirmation',
            refundConfirmMsg1: 'Confirm the refund of {0} for the order',
            refundConfirmMsg2: 'Order has been refunded {0}, would you like to proceed with the refund of {1} for the order',
            orderId: 'Order Number',
            userName: 'User name',
            mobile: 'Cell-phone number',
            createTime: 'Creation time',
            payTime: 'Payment date',
            payId: 'Payment Order Number',
            status: 'State',
            actualFee: 'Actual payment amount',
            lendInfo: 'Rental information',
            lendPoiName: 'Rental land',
            lendPoiId: 'Renting POI',
            lendCabinType: 'Renting Cabinet SN',
            orderLendTime: 'Command pop-up time',
            returnPoiId: 'Return POI',
            returnCabinType: 'Return cabinet SN',
            bizReturnTime: 'Business return time',
            lendBatteryType: 'Bao SN',
            returnType: 'Return method',
            returnTypeCase1: 'Normal',
            returnTypeCase2: 'Power failure',
            returnTypeCase3: 'Unreturned',
            returnTypeCase4: '00 Locking',
            returnTypeCase5: 'Lost after locking 00',
            returnTypeCase6: 'Power outage 00 lock',
            returnTypeCase7: 'Lost after 00 power outages and locking',
            returnTypeCase8: 'Return of foreign objects',
            manualReturnCase1: 'Artificial',
            orderReturnTime: 'Instruction return time',
            userPhonePower: 'Initial battery level of mobile phone',
            lendPower: 'Baodian Battery',
            chargeType: 'Billing method',
            chargeTypeCase1: 'Instructions',
            chargeTypeCase2: 'Artificial',
            chargeTypeCase3: 'Power failure',
            chargeTypeCase4: 'Consume power',
            chargeTypeCase5: 'Fault',
            chargeTypeCase6: 'Appeal',
            chargeTypeCase7: '00 lock return failed',
            chargeTypeCase8: 'Power outage 00, lock and return failure',
            userOptLog: 'User operation log',
            optType: 'Operation type',
            operateUserName: 'Operator',
            operateResult: 'results of enforcement',
            optTime: 'Operation time',
            manualLog: 'Manual operation log',
            operateContent: 'Operation content',
            optTypeCase1: 'Create a {0} payment order',
            orderFlow: 'Process Progress',
            orderFlowStep1: 'Create an order',
            orderFlowStep2: 'Pop up successfully',
            orderFlowStep3: 'Return successful',
            orderFlowStep4: 'Complete',
            leaseDuration: 'Rental duration',
            payInfo: 'Payment Information',
            orderType: 'Order type',
            depositFee: 'Deposit amount',
            depositRefundFee: 'System refund',
            originFee: 'Original order price',
            needPayFee: 'Payable amount',
            manualRefundFee: 'Manual refund',
            chargingPeriod: 'Billing duration',
            chargingRule: 'Billing Rules',
        },
        orderConstants: {
            MAX_LENGTH_VALIDATE: 'Enter up to 100 characters',
            DEPOSIT_TYPE_DESC: {
                DEPOSIT: 'Deposit',
                NO_REFUND: 'No deposit required',
            },
            ORDER_STATUS_QUERY_1: 'Pending authorization',
            ORDER_STATUS_QUERY_2: 'Pending payment',
            ORDER_STATUS_QUERY_3: 'Renting in progress',
            ORDER_STATUS_QUERY_4: 'Return successful',
            ORDER_STATUS_QUERY_5: 'Completed',
            ORDER_STATUS_QUERY_6: 'Manual refund',
            ORDER_STATUS_QUERY_7: 'Pop up in progress',
            ORDER_STATUS_QUERY_8: 'Closed',
            SOURCE_MAP: {
                Line: 'LINE Mini Program',
                App: 'App',
            },
            OPT_TYPE_MAP: {
                1000: 'Create a business order',
                1001: 'Delete order',
                1101: 'Create a payment order',
                1102: 'Initiate a non confidential deduction',
                1103: 'Refund of deposit system',
                1104: 'Secret deduction successful',
                1105: 'Proactive payment',
                1201: 'Pop up the power bank command',
                1202: 'Pop up the power bank',
                1203: 'Return the power bank',
                1204: 'Create an order appeal',
                1205: 'Order appeal successful',
                1206: 'Order appeal failed',
                1207: 'Refund after topping out',
                1208: 'Merchant applies for refund',
                1301: 'Manual return',
                1302: 'Manual refund',
                1303: 'Manual closing',
                1304: 'Suspend billing',
                1305: 'Extend the pause duration',
                1310: 'BD refund',
                1311: 'Price adjustment for unpaid orders',
                1312: 'Proactive service price adjustment',
                1313: 'Delete suspended billing',
                1401: 'Proactively buy treasure',
                1402: 'After sales coupon issuance',
                1403: 'After sales waste vouchers',
                2100: 'Intelligent customer service completes orders',
                2101: 'Salesperson review',
            },
            RESULT_MAP: {
                0: 'Success',
                1: 'Fail',
            },
            DATEPICKER_SHORTCUTS: {
                today: 'Today',
                yesterday: 'Yesterday',
                last7Days: 'Last 7 days',
                last14Days: 'Last 14 days',
                last30Days: 'Last 30 days',
                last90Days: 'Last 90 days',
            },
            SUSPEND_TIME: {
                '24Hours': '24 hours',
            },
        },
    },
    setting: {
        faq: {
            language: 'Language',
            title: 'Title',
            search: 'Search',
            reset: 'Reset',
            add: 'Add to',
            questionTitle: 'Frequently Asked Questions Title',
            questionContent: 'Common problem content',
            check: 'See',
            status: 'State',
            action: 'Operation',
            edit: 'Edit',
            delete: 'Delete',
            confirm: 'Confirm',
            cancel: 'Cancel',
            content: 'Content',
            placeholder: 'Please enter content',
            picture: 'Picture',
            addQuestion: 'Add common questions',
            editQuestion: 'Frequently Asked Questions for Editing',
            save: 'Saved successfully',
            warning: 'Tips',
            deleteContent: 'Are you sure to delete this question?',
            deleteTip: 'Delete successfully',
            enableTip: 'Confirm the effectiveness of this issue?',
            disableTip: 'Confirming the issue as invalid?',
            switchtip: 'Switching successful',
            required: 'Required',
        },
        price: {
            freeTime: 'Free available duration',
            minute: 'Minute',
            pricePeriod: 'Billing cycle',
            hour: 'Hour',
            halfHour: '0.5 hour',
            oneHour: '1 hour',
            unitPrice: 'Billing price',
            dayCappedPrice: 'Tianfeng Top Charge',
            cappedPrice: 'Deposit amount',
            saveConf: 'Synchronized charging template',
            freeTimeTip: 'Please enter an integer greater than 0',
            pricePeriodTip: 'Please select billing cycle',
            commonTip: 'Please enter a number greater than 0',
            tip: 'Tips',
            saveTip: 'Are you sure to synchronize the charging template?',
            saveSuccess: 'Saved successfully',
        },
        material: {
            brandName: 'Brand Name',
            logo: 'Logo image',
            logoTip: 'Image formats: png, jpg, gif, size ratio: 1:1, image size not exceeding 1M',
            baner: 'Scan the QR code landing page and rotate the images',
            bannerTip: 'Image format: png, jpg, width * height: 750px * 600px, image size not exceeding 5M',
            link: 'Customer service contact information',
            withdrawalThreshold: 'Merchant withdrawal amount threshold',
            save: 'Save configuration',
            brandNameTip: 'Please enter the brand name',
            withdrawalThresholdTip: 'Please enter the withdrawal amount threshold',
            linkTip: 'Please enter customer service contact information',
            logoRequireTip: 'Please upload a logo image',
            bannerRequireTip: 'Please upload the landing page carousel image',
            tip: 'Tips',
            saveTip: 'Are you sure to save the configuration? The new configuration will overwrite the old configuration',
            saveSuccess: 'Saved successfully',
        },
    },
    el: {
        locale: 'zh-CN',
        datepicker: {
            selectDate: 'Select date',
            selectTime: 'Select time',
            startTime: 'Start time',
            endTime: 'End time',
            now: 'This moment',
            ok: 'Determine',
            month: 'Month',
            month1: 'January',
            month2: 'February',
            month3: 'March',
            month4: 'April',
            month5: 'May',
            month6: 'June',
            month7: 'July',
            month8: 'August',
            month9: 'September',
            month10: 'October',
            month11: 'November',
            month12: 'December',
            year: 'Year',
            weeks: {
                sun: 'Day',
                mon: 'One',
                tue: 'Two',
                wed: 'Three',
                thu: 'Four',
                fri: 'Five',
                sat: 'Six',
            },
            months: {
                m1: 'January',
                m2: 'February',
                m3: 'March',
                m4: 'April',
                m5: 'May',
                m6: 'June',
                m7: 'July',
                m8: 'August',
                m9: 'September',
                m10: 'October',
                m11: 'November',
                m12: 'December',
            },
        },
        pagination: {
            goto: 'Go to',
            pagesize: 'Article/Page',
            total: 'Total of {total} items',
            pageClassifier: 'Page',
        },
    },
    editor: {
        editor: {
            more: 'More',
            justify: 'Alignment',
            indent: 'Indent',
            image: 'Picture',
            video: 'Video',
        },
        common: {
            ok: 'Determine',
            delete: 'Delete',
            enter: 'Enter',
        },
        blockQuote: {
            title: 'Quote',
        },
        codeBlock: {
            title: 'Code Block',
        },
        color: {
            color: 'Text color',
            bgColor: 'Background color',
            default: 'Default color',
            clear: 'Clear background color',
        },
        divider: {
            title: 'Dividing line',
        },
        emotion: {
            title: 'Expression',
        },
        fontSize: {
            title: 'Font size',
            default: 'Default font size',
        },
        fontFamily: {
            title: 'Typeface',
            default: 'Default font',
        },
        fullScreen: {
            title: 'Full screen',
        },
        header: {
            title: 'Title',
            text: 'Text',
        },
        image: {
            netImage: 'Network images',
            delete: 'Delete image',
            edit: 'Edit image',
            viewLink: 'View link',
            src: 'Image address',
            desc: 'Image description',
            link: 'Pictures linking',
        },
        indent: {
            decrease: 'Decrease indent',
            increase: 'Increase indentation',
        },
        justify: {
            left: 'Left aligned',
            right: 'Right aligned',
            center: 'Center Alignment',
            justify: 'Align both ends',
        },
        lineHeight: {
            title: 'Hang Gao',
            default: 'Default row height',
        },
        link: {
            insert: 'Insert Link',
            text: 'Link Text',
            url: 'Link address',
            unLink: 'Cancel Link',
            edit: 'Modify link',
            view: 'View link',
        },
        textStyle: {
            bold: 'Bold',
            clear: 'Clear Format',
            code: 'In line code',
            italic: 'Italic',
            sub: 'subscript',
            sup: 'Superscript',
            through: 'Delete Line',
            underline: 'Underline',
        },
        undo: {
            undo: 'Revoke',
            redo: 'Redo',
        },
        todo: {
            todo: 'Need to be dealt with',
        },
        listModule: {
            unOrderedList: 'Unordered List',
            orderedList: 'Ordered List',
        },
        tableModule: {
            deleteCol: 'Delete Column',
            deleteRow: 'Delete rows',
            deleteTable: 'Delete Table',
            widthAuto: 'Width adaptive',
            insertCol: 'Insert Column',
            insertRow: 'Insert line',
            insertTable: 'Insert Table',
            header: 'Header',
        },
        videoModule: {
            delete: 'Delete video',
            uploadVideo: 'Upload video',
            insertVideo: 'Insert video',
            videoSrc: 'Video address',
            videoSrcPlaceHolder: 'Video file URL or third-party<iframe>',
            videoPoster: 'Video Cover',
            videoPosterPlaceHolder: 'Cover image URL',
            ok: 'Confirm',
            editSize: 'Change size',
            width: 'Width',
            height: 'Height',
        },
        uploadImgModule: {
            uploadImage: 'Upload image',
            uploadError: '{{fileName}} upload error',
        },
        highLightModule: {
            selectLang: 'Choose language',
        },
    },
};
