export const ACTIVITY_TYPE = {
    xunbao: 1,
    huanfu: 2,
    yiyuanbao: 4,
    qishoubao: 5,
};

export const YIYUANBAO_ACTIVITY_STATUS = {
    NOSTART: 0, // 0或null
    JOINING: 1,
    SUCCESS: 2,
    FAIL: 3,
};
export const QISHOUBAO_ACTIVITY_STATUS = {
    NOSTART: 0, // 0或null
    JOINING: 1,
    SUCCESS: 2,
    FAIL: 3,
};
