<template>
    <div class="order-summary-comp">
        <div class="module left">
            <mtd-row :gutter="24">
                <mtd-col>
                    <span class="large-text">{{ $t('order.orderDetail.orderId') }}: {{ summaryData.orderId || '-' }}</span>
                    <mtd-tag
                        v-if="summaryData.payMode === DEPOSIT_TYPE.DEPOSIT"
                        theme="green"
                    >
                        {{ DEPOSIT_TYPE_DESC.DEPOSIT }}
                    </mtd-tag>
                    <mtd-tag
                        v-else-if="summaryData.payMode === DEPOSIT_TYPE.NO_REFUND"
                        theme="green"
                    >
                        {{ DEPOSIT_TYPE_DESC.NO_REFUND }}
                    </mtd-tag>
                    <mtd-tag
                        v-if="transferData.source"
                        theme="green"
                    >
                        {{ transferData.source }}
                    </mtd-tag>
                    <!-- <mtd-tag
                        v-if="summaryData.powerOffReturnFlag === 1"
                        theme="red"
                    >
                        断电
                    </mtd-tag>
                    <mtd-tag
                        v-if="summaryData.malfunction === 1"
                        theme="orange"
                    >
                        故障
                    </mtd-tag>
                    <mtd-tag
                        v-if="summaryData.deleted === true"
                        theme="red"
                    >
                        已删除
                    </mtd-tag> -->
                    <!-- <mtd-popover
                        v-if="summaryData.buyType === 1"
                        trigger="hover"
                        placement="right"
                    >
                        <mtd-tag
                            theme="orange"
                        >
                            主动买宝
                        </mtd-tag>
                        <div slot="content">
                            已租借时间：{{ buyTagPopInfo.alreadyLendTimeText }} <br />
                            预计可退款金额：{{ buyTagPopInfo.computedRefundFee }}元
                        </div>
                    </mtd-popover> -->
                    <!-- <mtd-tag
                        v-if="summaryData.buyBatteryActivities === 1"
                        theme="red"
                    >
                        喜茶活动买宝
                    </mtd-tag>
                    <mtd-tag
                        v-if="!!summaryData.appealTag"
                        theme="red"
                    >
                        申诉
                    </mtd-tag>
                    <mtd-tag
                        v-if="lendData.powerStatus === 2"
                        theme="red"
                    >
                        低电量宝
                    </mtd-tag> -->
                </mtd-col>
            </mtd-row>
            <mtd-row :gutter="24">
                <mtd-col :span="10">
                    <span> {{ $t('order.orderDetail.userName') }}： </span>
                    <span>{{ transferData.username }}</span>
                </mtd-col>
                <mtd-col :span="14">
                    <span> {{ $t('order.orderDetail.mobile') }}：</span>
                    <span>{{ phoneNum || transferData.mobile || '-' }}</span>
                    <!-- <mtd-button
                        v-if="!phoneNum"
                        type="text-primary"
                        size="small"
                        @click="showPhoneNum"
                    >
                        查看
                    </mtd-button> -->
                </mtd-col>
            </mtd-row>
            <mtd-row :gutter="24">
                <mtd-col :span="10">
                    <span>{{ $t('order.orderDetail.createTime') }}：</span>
                    <span>{{ transferData.createTime }}</span>
                </mtd-col>
                <!-- <mtd-col :span="14">
                    <span>租借产品：</span>
                    <span>{{ transferData.batteryType }}</span>
                </mtd-col> -->
            </mtd-row>
            <mtd-row :gutter="24">
                <mtd-col :span="10">
                    <span>{{ $t('order.orderDetail.payTime') }}：</span>
                    <span>{{ transferData.payTime }}</span>
                </mtd-col>
                <mtd-col :span="14">
                    <span>{{ $t('order.orderDetail.payId') }}：</span>
                    <span style="color: #1c6cdc">{{ transferData.payId || '-' }}</span>
                </mtd-col>
            </mtd-row>
        </div>
        <div class="module right">
            <slot></slot>
            <mtd-row class="content-show">
                <mtd-col
                    :span="12"
                    class="status"
                >
                    <div>
                        <span>{{ $t('order.orderDetail.status') }}</span>
                        <p class="large-text">
                            {{ transferData.status }}
                        </p>
                    </div>
                    <!-- <div>
                        <a
                            class="refresh-btn"
                            @click="syncOrder"
                        >
                            订单同步
                        </a>
                    </div> -->
                </mtd-col>
                <mtd-col :span="12">
                    <span>{{ $t('order.orderDetail.actualFee') }}</span>
                    <p class="large-text">
                        {{ PRICE_UNIT[summaryData.areaType] }} {{ transferData.actualFee || '-' }}
                    </p>
                </mtd-col>
            </mtd-row>
        </div>
    </div>
</template>

<script>
import STATUS from '@/constants/status';
import utils from '@/common/util';
import {
    ORDER_STATUS_QUERY, SOURCE_MAP, AREA_TYPE, PRICE_UNIT, DEPOSIT_TYPE,
    DEPOSIT_TYPE_DESC,
    DEPOSIT_TYPE_LIST,
} from '@/constants/orderConstants';
import OrderServiceApi from '@/api/order/orderService';
// import {
//     ACTIVITY_TYPE, YIYUANBAO_ACTIVITY_STATUS, QISHOUBAO_ACTIVITY_STATUS,
// } from '@/constants/adConstants';

export default {
    name: 'OrderSummary',
    props: {
        summaryData: {
            type: Object,
            default: () => ({}),
        },
        lendData: {
            type: Object,
            default: () => ({}),
        },
        flowData: {
            type: Object,
            default: () => ({}),
        },
        payInfo: {
            type: Object,
            default: () => ({}),
        },
        activityStatus: {
            type: Number,
            default: 0,
        },
        activityType: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            DEPOSIT_TYPE,
            DEPOSIT_TYPE_DESC,
            DEPOSIT_TYPE_LIST,
            AREA_TYPE,
            PRICE_UNIT,
            // ACTIVITY_TYPE,
            // YIYUANBAO_ACTIVITY_STATUS,
            // QISHOUBAO_ACTIVITY_STATUS,
            phoneNum: '',
            buyTagPopInfo: {
                alreadyLendTimeText: '',
                computedRefundFee: '',
            },
        };
    },
    computed: {
        transferData() {
            const {
                createTime,
                payTime,
                // batteryType,
                status,
                actualFee,
                source,
            } = this.summaryData;
            return {
                ...this.summaryData,
                createTime: createTime ? utils.parseTimeToDate(createTime, 'YYYY-MM-DD HH:mm:ss') : '-',
                payTime: payTime ? utils.parseTimeToDate(payTime, 'YYYY-MM-DD HH:mm:ss') : '-',
                // batteryType: BATTERY_TYPE_MAP[batteryType] || '-',
                status: this.parseStatusText(status),
                actualFee: utils.formatPrice(actualFee),
                source: SOURCE_MAP[source],
            };
        },
    },
    watch: {
        summaryData() {
            this.initBuyBatteryPop();
        },
    },
    methods: {
    // 初始化 主动买宝tag的pop弹窗信息
        initBuyBatteryPop() {
            if (this.summaryData.buyType !== 1) {
                return;
            }
            // serverCurrentTime 服务器当前时间
            const bizReturnTime = this.flowData.returnTime || this.lendData.serverCurrentTime || Date.now();
            // 1、已租借时间
            if (this.lendData.lendTime) {
                this.buyTagPopInfo.alreadyLendTimeText = utils.formatDuring(bizReturnTime - this.lendData.lendTime);
            }
            // 2、预计可退款金额
            const params = {
                userId: this.summaryData.userId,
                orderId: this.summaryData.orderId,
                bizReturnTime,
            };
            OrderServiceApi.getReturnFee(params).then((res) => {
                const { data, code } = res;
                if (code === STATUS.SUCCESS) {
                    this.buyTagPopInfo.computedRefundFee = utils.formatPrice(data.computedRefundFee);
                }
            });
        },
        // syncOrder() {
        //     this.$mtd.confirm({
        //         title: '确认信息',
        //         message: '确认将订单状态同步到美团订单中心?',
        //         width: '430px',
        //         showCancelButton: true,
        //         onOk: async () => {
        //             const params = {
        //                 orderId: this.summaryData.orderId,
        //                 userId: this.summaryData.userId,
        //             };
        //             const { status, message } = await OrderServiceApi.syncOrder(params);
        //             if (status === STATUS.SUCCESS) {
        //                 this.$mtd.message({
        //                     message: '订单同步成功',
        //                     type: 'success',
        //                 });
        //             } else {
        //                 this.$mtd.message({
        //                     message,
        //                     type: 'warning',
        //                 });
        //             }
        //         },
        //     }).catch((e) => {
        //         console.log(e);
        //     });
        // },
        parseStatusText(cellValue) {
            if (!cellValue) return '-';
            const { length } = ORDER_STATUS_QUERY;
            for (let i = 0; i < length; i++) {
                const valueArr = ORDER_STATUS_QUERY[i].value.split(',').map((p) => +p);
                if (valueArr.includes(cellValue)) {
                    return ORDER_STATUS_QUERY[i].label;
                }
            }
            return '-';
        },
        // async showPhoneNum() {
        //     try {
        //         const queryParams = {
        //             encryptMobile: this.summaryData.encryptMobile,
        //             userId: this.summaryData.userId,
        //         };
        //         const res = await OrderServiceApi.getPhoneNum(queryParams);
        //         const { status, data, message } = res;
        //         if (status === STATUS.SUCCESS && data) {
        //             this.phoneNum = data.mobileNumber;
        //         } else {
        //             this.$mtd.message({
        //                 message,
        //                 type: 'warning',
        //             });
        //         }
        //     } catch (e) {
        //         //
        //     }
        // },
    },
};
</script>

<style lang="less" scoped>
  .order-summary-comp {
    .mtd-col {
      margin: 5px 0;
    }
    .large-text {
      font-size: 24px;
      font-weight: bold;
    }
    display: flex;
    .right {
      min-width: 500px;
      text-align: right;
      .mtd-btn {
        margin-left: 10px;
      }
      .content-show {
        margin-top: 20px;
        .status {
          display: flex;
          justify-content: flex-end;
          a {
            cursor: pointer;
            text-decoration: underline;
          }
        }

      }
    }
    .left {
      flex: 1;
      .mtd-tag {
        vertical-align: text-bottom;
        margin-left: 10px;
      }
    }
  }
</style>
