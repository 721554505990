import dayjs from '@/common/dayjs';

const scrollTop = () => {
    document.getElementsByTagName('html')[0].scrollTop = 0;
};

function formatPrice(cent) {
    if (!cent || cent === 0) {
        return '0.00';
    }
    return (cent / 100).toFixed(2);
}

function parseUnixtimeToDate(data) {
    const unixTime = parseInt(data.createTime, 10);
    return dayjs(unixTime).format('YYYY-MM-DD HH:mm:ss');
}

function parseTimeToDate(time, format) {
    const unixTime = parseInt(time, 10);
    return dayjs(unixTime).format(format || 'YYYY-MM-DD');
}

/* 毫秒转换为 xx天xx小时xx分钟xx秒
* 秒位一定展示、从天开始如果首个单位为0则不展示此单位
* 比如 0天20小时0分0秒 则为 20小时0分0秒。
*/
function formatDuring(ms) {
    const secs = Math.round(ms / 1000);
    // 秒取余
    const seconds = secs % 60;
    // 秒转成分钟 再去分钟的余
    const minutes = parseInt(secs / 60, 10) % 60;
    // 秒转成小时 再取小时的余
    const hours = parseInt(secs / (60 * 60), 10) % 24;
    // 秒转成天
    const days = parseInt(secs / (60 * 60 * 24), 10);
    if (days === 0 && hours === 0 && minutes === 0) {
        return `${seconds}秒`;
    } else if (days === 0 && hours === 0) {
        return `${minutes}分钟${seconds}秒`;
    } else if (days === 0) {
        return `${hours}小时${minutes}分钟${seconds}秒`;
    } else {
        return `${days}天${hours}小时${minutes}分钟${seconds}秒`;
    }
}

function getByteLength(str) {
    return str.length;
}

function findRuleById(id, bindCities) {
    const { length } = bindCities;
    for (let i = 0; i < length; i++) {
        if (bindCities[i].cityId === id) {
            return {
                idx: i,
                rule: bindCities[i].rule,
            };
        }
    }
    return {};
}

/* tree全量数据转换成叶子节点的list结构 */
/**
 * @param treeData
 * @return {arrayList}
*/
function flatTree2Array(originTreeData) {
    // 将children的树形结构数据，逆转压平成为叶子节点包含parent的list结构
    const flatTree2ArrayResult = [];
    const obj = {}; // 自然复用
    function deepSetParent(node, $parent) {
        node.forEach((item) => {
            // 有孩子节点数据
            if (item.children && item.children.length) {
                obj.id = item.id;
                obj.name = item.name;
                obj.parent = { ...$parent };
                deepSetParent(item.children, { id: item.id, name: item.name });
            } else {
                flatTree2ArrayResult.push({ id: item.id, name: item.name, parent: { ...obj } });
            }
        });
    }
    deepSetParent(originTreeData, { parent: {} });
    return flatTree2ArrayResult;
}

function mergeDataAsIndex(originArr, obj, id, name) {
    let inx = -1;
    originArr.forEach((val, index) => {
        if (val.id === id) inx = index;
    });
    // 当前是否存在id相同元素节点,
    if (inx > -1) { // 直接设置 children
        let chilIndex = -1;
        originArr[inx].children.forEach((val, index) => {
            if (val.id === obj.id) chilIndex = index;
        });
        if (chilIndex > -1) {
            originArr[inx].children.splice(chilIndex, 1, obj);
        } else {
            originArr[inx].children.push(obj);
        }
    } else { // 不存在直接push
        originArr.push({ id, name, children: [obj] });
    }
}

/**
 * 叶子节点展开的list转换成反转为自上而下的树结构
*/
/* 根据节点id获取当前节点的数据 */
function getTreeNodeById(treeData, nodeId) {
    let findNode = {};
    function deepFind(node = []) {
        const { length: lg } = node;
        for (let i = 0; i < lg; i++) {
            if (node[i].id === nodeId) {
                findNode = node[i];
                return;
            } else {
                deepFind(node[i].children || []);
            }
        }
    }
    deepFind(treeData);
    return findNode;
}

function transArray2Tree(arrayList) {
    // 1.遍历, 将当前节点信息放入 parent对象里 children:[{}]。
    // 2.递归执行第一步获取到的parent数据。
    // 3.无parent属性代表是顶层，放入顶层list里 [{}]。
    const treeData = [];
    function setChildren(item = {}, obj) {
        const { id, name, parent } = item;
        // 存在parent
        if (parent) {
            obj.id = id;
            obj.name = name;
            const nextParent = { id: parent.id, name: parent.name, children: [obj] };
            setChildren(parent.parent, nextParent);
        } else {
            // 需要设置倒数第二层的节点的children信息
            const treeNode = getTreeNodeById(treeData, obj.id);
            const { children: findChilred = [] } = treeNode;
            // 当前查找到的children节点和obj.children merge合并形成新的obj.children;
            obj.children = obj.children.concat(findChilred);
            mergeDataAsIndex(treeData, obj, id, name);
        }
    }
    arrayList.forEach((item) => {
        const nextParent = { id: '', name: '', children: [] };
        setChildren(item, nextParent);
    });
    return treeData;
}

/* 树形结构转换成 */
/** 后端返回的城市结构信息转换成tree组件格式
* @param treeData: [{ id: '1', label: '省级', children: [{id: '1-1', name: '市级', children: [{{ id: '1-1-1', name: '区级'}}]}]}]
* @return 叶子节点的id treeList: [{ id: '1-1-1', name: 'xx-xx-xx'}]
*/
function transTree(originList = [], bindCities = [], isSetName) {
    const listByleafId = [];
    function deep(node = [], _nameStr = '') {
        node.forEach((item = {}) => {
            const { children = [], id, name } = item;
            const nameStr = _nameStr ? `${_nameStr}-${name}` : name;
            if (children.length) {
                deep(children, nameStr);
            } else {
                const { rule } = findRuleById(id, bindCities);
                if (isSetName) {
                    item.name = rule ? `${name}${rule}` : name;
                } else {
                    item.name = name;
                }
                listByleafId.push({
                    id,
                    name: nameStr,
                });
            }
        });
    }
    deep(originList);
    return {
        listByleafId,
        treeData: originList,
    };
}

/* tree全量数据转换成叶子节点关联的map结构 */
/**
 * @param treeData
 * @return {arrayList}
*/
function transTreeToLeafRelation(originList) {
    const relateMap = {};
    let listTemp = [];
    function deep(arr, parentList) {
    // 遍历
        arr.forEach((item) => {
            listTemp = [...parentList, item.id];
            if (item.children && item.children.length) {
                deep(item.children, listTemp);
            } else {
                relateMap[item.id] = listTemp;
            }
        });
    }
    deep(originList, []);
    return relateMap;
}

/**
 * @param treeData
 * @return {arrayList}
 */
// 遍历城市节点，设置节点不可选中
function setCityTreeNodeDisabled(originTree = [], targetList = []) {
    const newTreeData = JSON.parse(JSON.stringify(originTree));
    function deep(arr) {
        arr.forEach((item) => {
            if (item.children && item.children.length) {
                deep(item.children);
            } else {
                if (targetList.includes(item.id)) {
                    item.disabled = true;
                }
            }
        });
    }
    deep(newTreeData);
    return newTreeData;
}

function debounce(fn, delays) {
    let timer = null;
    const func = () => {
        if (timer) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                fn();
                timer = null;
            }, delays);
            return;
        }
        clearTimeout(timer);
        timer = setTimeout(() => {
            fn();
        }, delays);
    };
    return func;
}

function downloadByUrl(url, misid) {
    try {
        if (window.secDBDownload) {
            window.secDBDownload.downloadFile(url, misid);
        } else {
            window.open(url, '_blank');
        }
    } catch (e) {
        window.open(url, '_blank');
    }
}

export default {
    scrollTop,
    formatPrice,
    parseUnixtimeToDate,
    parseTimeToDate,
    getByteLength,
    transTree,
    transArray2Tree,
    flatTree2Array,
    formatDuring,
    transTreeToLeafRelation,
    setCityTreeNodeDisabled,
    debounce,
    downloadByUrl,
};
