















































































































import { Component, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';
import agentSelect from '../common/agentSelect.vue';

@Component({
    components: {
        agentSelect,
    },
})
export default class agentList extends Vue {
    @State((state) => state.common.uacMenusMap) uacMenusMap: any;

    @State((state) => state.common.agentConfig) agentConfig: any;

    form = {
        agentId: '',
        poiId: '',
        empId: '',
        accountId: '',
    }

    columns = [
        { title: this.$t('poiList.storeId'), dataIndex: 'id' },
        { title: this.$t('poiList.storeName'), dataIndex: 'poiName' },
        { title: this.$t('poiList.storeType'), dataIndex: 'poiType' },
        { title: this.$t('poiList.benefit'), dataIndex: 'benefit' },
        { title: this.$t('poiList.businessStatus'), dataIndex: 'openStatus' },
        { title: this.$t('poiList.businessHours'), dataIndex: 'openTime' },
        { title: this.$t('poiList.storeAddress'), dataIndex: 'address' },
        { title: this.$t('poiList.contactName'), dataIndex: 'contactName' },
        { title: this.$t('poiList.personInChargePhoneNumber'), dataIndex: 'contactMobile' },
        { title: this.$t('poiList.priceRule'), dataIndex: 'priceRule' },
        { title: this.$t('poiList.cooperationStatus'), dataIndex: 'poiStatus' },
        { title: this.$t('poiList.belongingAgent'), dataIndex: 'bindAgentName' },
        { title: this.$t('poiList.belongingId'), dataIndex: 'bindBdName' },
        { title: this.$t('poiList.account'), dataIndex: 'account' },
        { title: this.$t('poiList.merchantName'), dataIndex: 'accountName' },
        { title: this.$t('poiList.cabinetNumber'), dataIndex: 'cabinNum' },
        { title: this.$t('poiList.currentTreasureNumber'), dataIndex: 'cabinBatteryNum' },
        { title: this.$t('poiList.updateTime'), dataIndex: 'updateTime' },
    ]

    tableData = [];

    total: number = 0;

    pagination = {
        pageNum: 1,
        pageSize: 10,
    }

    defaultAccount: any = [];

    async created() {
        const { accountId, accountName } = this.$route.query;
        if (accountId && accountName) {
            this.form.accountId = accountId as string;
            this.defaultAccount = [{
                id: accountId,
                name: accountName,
            }];
        }
        this.getTableData();
    }

    async getTableData() {
        Object.keys(this.form).forEach((key) => {
            if (this.form[key] === '') {
                this.form[key] = null;
            }
        });
        const { data } = await this.$request.get('/mapi/manager/poi/list-poi', {
            params: {
                ...this.form,
                ...this.pagination,
            },
        });
        if (data && data.data) {
            this.tableData = data.data;
            this.total = data.total;
        } else {
            this.tableData = [];
            this.total = 0;
        }
    }

    edit(row: any) {
        this.$router.push(`/poi/detail?poiId=${row.id}`);
    }

    search() {
        this.pagination = {
            pageNum: 1,
            pageSize: 10,
        };
        this.getTableData();
    }

    change() {
        this.getTableData();
    }

    async showPriceRule(row: any) {
        const { success, data } = await this.$request.get('/mapi/manager/poi/query-poi-price-rule', {
            params: {
                poiId: row.id,
            },
        });
        if (success && data) {
            const priceUnit = `${this.agentConfig.currencyCode}${this.agentConfig.currencySymbol}`;
            this.$mtd.confirm({
                title: this.$t('poiList.priceRule') as string,
                message: [
                    `${this.$t('poiList.freeTime', { num: data.freeTime })}`,
                    `${this.$t('poiList.pricePeriod', { count: data.pricePeriod === 1 ? '30' : '60' })}`,
                    `${this.$t('poiList.price', { num: data.unitPrice, unit: priceUnit })}`,
                    `${this.$t('poiList.dayCappedPrice', { num: data.dayCappedPrice, unit: priceUnit })}`,
                    `${this.$t('poiList.cappedPrice', { num: data.cappedPrice, unit: priceUnit })}`,
                    `${this.$t('poiList.poiBenefit', { num: row.benefit === null ? '-' : row.benefit })}`,
                ].join('<br />'),
                width: '530px',
                type: 'info',
                dangerouslyUseHTMLString: true,
                okButtonText: this.$t('common.confirm') as string,
            }).catch(() => {});
        }
    }

    async exportFile() {
        Object.keys(this.form).forEach((key) => {
            if (this.form[key] === '') {
                this.form[key] = null;
            }
        });
        const { success, data } = await this.$request.get('/mapi/manager/poi/list-poi-export', {
            params: {
                ...this.form,
            },
        });
        if (success && data) {
            window.open(data);
            // this.$mtd.message.success(this.$t('poiList.exportSuccessTip') as any);
        }
    }
}
