
















































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { State } from 'vuex-class';
// eslint-disable-next-line import/extensions
import AppContainer from './components/AppContainer.vue';
import { langList } from './common/i18n';
import stateStore from './store';
import { indexTypes as types } from './store/action-types';

@Component({
    components: {
        AppContainer,
    },
})
export default class App extends Vue {
    @State((state) => state.common.user) user: any;

    @State((state) => state.common.agentConfig) agentConfig: any;

    showTipForChrome: boolean = window.navigator.userAgent.indexOf('Chrome') === -1;

    menuInfo: any = {
        title: 'MT CHARGE',
        layout: 'left',
    };

    get doNotNeedLogin() {
        const { meta = {} } = this.$route;
        return meta.needLogin === false;
    }

    get lang() {
        if (this.agentConfig.mlanguageList && this.agentConfig.mlanguageList.length) {
            // 取交集
            let defaultLang = this.$i18n.locale;
            const list = this.agentConfig.mlanguageList.filter((item: any) => {
                if (langList.includes(item.code)) {
                    if (item.official) {
                        defaultLang = item.code;
                    }
                    return true;
                }
                return false;
            });
            if (defaultLang !== this.$i18n.locale && !window.localStorage.getItem('locale')) {
                // 需要切换语言
                this.$i18n.locale = defaultLang;
                window.localStorage.setItem('locale', defaultLang);
            }
            return list;
        }
        return langList;
    }

    get defaultLangLabel() {
        return this.lang.find((item: any) => item.code === this.$i18n.locale)?.name;
    }

    @Watch('doNotNeedLogin', { immediate: true })
    onPageChange() {
        stateStore.commit(types.SET_SIDE_VISIBLE, !this.doNotNeedLogin);
    }

    changeLang(key: string) {
        this.$i18n.locale = key;
        window.localStorage.setItem('locale', key);
        window.location.reload();
    }

    async logout() {
        const { data } = await this.$request.post('/mapi/manager/logout/manager-logout');
        if (data) {
            window.localStorage.removeItem('manager-token');
            window.location.href = '/login';
        }
    }
}
