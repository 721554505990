import { request } from '../../common/request';
import config from '../../common/config';

// eslint-disable-next-line no-nested-ternary
const orderApiHead = config.isProd ? 'https://enter-api.mtcharge.jp' : (config.isStaging ? 'https://st-enter-api.mtcharge.jp' : '');
// const orderApiHead = 'https://st-enter-api.mtcharge.jp';

/**
 * 模糊查询门店列表
 * @param poiName 规则名称
 * @returns {*}
 */
const getPoiListByKeyWord = (poiName: string) => request.get('/api/v2/merchant/getPoiInfoByPoiName', {
    params: {
        poiName: poiName.trim(),
        pageNum: 1,
        pageSize: 100,
    },
});

/**
 * 订单列表
 * @param params params
 * @returns {*}
 */
const getOrderListData = (params: any) => request.post(`${orderApiHead}/api-c/manager/v1/orders/list`, params);

/**
 * 获取超期订单列表接口
 * @param params params
 * @returns {*}
 */
const getyCellarOrderList = (params: any) => request.post('/shepherd/api/v2/order-cellar/list', params);

/**
 * 订单详情
 * @param params params
 * @returns {*}
 */
const fetchOrderDetail = (params: any) => request.post(`${orderApiHead}/api-c/manager/v1/orders/detail`, params);

/**
 * 用户近半年下单记录
 * @param params params
 * @returns {*}
 */
// const fetchOrderRecord = (params: any) => request.post('/shepherd/api/v2/order-es/record', params);

/**
 * 查询手机号、复用老接口https://km.sankuai.com/page/390303865#id-5.9%E6%9F%A5%E8%AF%A2%E6%89%8B%E6%9C%BA%E5%8F%B7
 * @param params params
 * @returns {*}
 */
const getPhoneNum = (params: any) => request.post(
    '/shepherd/api/v2/orderManager/battery/queryPhoneDetailInfo',
    params,
);

/**
 * 人工归还
 * @param params params
 * @returns {*}
 */
const manualReturn = (params: any) => request.post(`${orderApiHead}/api-c/manager/v1/order/manualReturn`, params);

/**
 * 人工退款
 * @param params params
 * @returns {*}
 */
const refundFee = (params: any) => request.post(`${orderApiHead}/api-c/manager/v1/order/refund`, params);

/**
 * 删除暂停计费
 * @param params params
 * @returns {*}
 */
const delSuspend = (params: any) => request.post('/shepherd/api/v2/order/suspend/delete', params);

/**
 * 根据时间计算应付金额、已退金额、退款次数等信息
 * @param params params
 * @returns {*}
 */
const getReturnFee = (params: any) => request.post(`${orderApiHead}/api-c/manager/v1/order/return-fee`, params);

/**
 * 暂停计费
 * @param params params
 * @returns {*}
 */
const stopCharge = (params: any) => request.post(`${orderApiHead}/api-c/manager/v1/order/suspend`, params);

/**
 * 关闭订单
 * @param params params
 * @returns {*}
 */
const closeOrder = (params: any) => request.post(`${orderApiHead}/api-c/manager/v1/order/close`, params);

/**
 * 查询用户当前资产(券信息)
 * @param params params
 * @returns {*}
 */
const queryCouponInfo = (params: any) => request.post('/shepherd/api/v2/userCoupon/queryCouponInfoByUserId', params);

/**
 * 获取所有人工还宝的操作原因
 * @param params params
 * @returns {*}
 */
const getArtificialReturnReasons = (params: any) => request.get('/shepherd/api/v2/order/getArtificialReturnChargePalReasonAll', {
    params,
});

/**
 * 获取所有退款的操作原因
 * @param params params
 * @returns {*}
 */
const getRefundReasons = (params: any) => request.get('/shepherd/api/v2/order/getRefundReasonAll', { params });

/**
 * 获取关联工单
 * @param params params
 * @returns {*}
 */
// const getRelatedCustomerServiceOrders = (params: any) => request.post('/shepherd/api/v2/workOrder/queryOrderListByWorkOrderId', params);

/**
 * 订单同步
 * @param {*} params
 */
const syncOrder = (params: any) => request.post('/shepherd/api/v2/order/push-order', params);

/**
 * 获取关联申诉单
 * http://shepherd.mws-test.sankuai.com/api-detail?api_group_name=powerbank_manager_c&api_group_id=11779&group_tab=api-manage&api_name=query_user_appeal&api_id=58846&tab=api-doc&comp=auth
 * @param params params
 * @returns {*}
 */
// const getRelatedCustomerFeedbackOrders = (params: any) => request.post('/shepherd/api/v1/userAppeal/list', params);

/**
 * 根据机柜sn查询门店名称
 * https://km.sankuai.com/page/1281443985
 * @params cabinSn: String;
 * @returns {
 *  poiId: number,
 *  poiName: string,
 * }
 */
const getPoiInfoByCabinSn = (cabinSn: string) => request.post('/shepherd/api/v2/order-es/get-poi-name', { cabinSn });

// 押金单 列表
const getDepositOrderList = (params: any, config: any) => request.post('/shepherd/api/v2/recharge/list', params, config);
// 押金单 详情
const getDepositOrderDetail = (params: any, config: any) => request.post('/shepherd/api/v2/recharge/detail', params, config);
// 退还用户押金
const sendDepositWithdraw = (params: any) => request.post('/shepherd/api/v2/recharge/manualWithdraw', params);

// 查询购卡订单列表
const getCardPurchaseList = (params: any) => request.post('/shepherd/api/v2/order-cardPurchase/list', {
    ...params,
    pageNum: params?.pageNum || 1,
    pageSize: params?.pageSize || 20,
});
// 查询次卡详情
const getCardPurchaseDetail = (params: any) => request.post('/shepherd/api/v2/order-cardPurchase/detail', params);
//次卡退款
const cardPurchaseRefund = (params: any) => request.post('/shepherd/api/v2/order-cardPurchase/refund', params);

// 获取改价金额
const getChangePriceInfo = (params: any) => request.get('/shepherd/api/v2/order/getChangePriceInfo', { params });
// 改价
const changePrice = (params: any) => request.post('/shepherd/api/v2/order/changePrice', { ...params, changeSource: 1 });

// 发券
const sendOrderCoupon = (params: any) => request.post('/shepherd/api/v2/order-coupon/send', params);

// 废券
const withdrawOrderCoupon = (params: any) => request.post('/shepherd/api/v2/order-coupon/discard', params);

export default {
    getCardPurchaseList,
    getCardPurchaseDetail,
    cardPurchaseRefund,
    getPoiListByKeyWord,
    getOrderListData,
    getyCellarOrderList,
    fetchOrderDetail,
    // fetchOrderRecord,
    getPhoneNum,
    manualReturn,
    refundFee,
    delSuspend,
    getReturnFee,
    stopCharge,
    // stopChargeV2,
    closeOrder,
    queryCouponInfo,
    getArtificialReturnReasons,
    getRefundReasons,
    // getRelatedCustomerServiceOrders,
    syncOrder,
    // getRelatedCustomerFeedbackOrders,
    getPoiInfoByCabinSn,
    getDepositOrderList,
    getDepositOrderDetail,
    sendDepositWithdraw,
    getChangePriceInfo,
    changePrice,
    sendOrderCoupon,
    withdrawOrderCoupon,
};
