<template>
    <!-- 关单 弹窗 -->
    <div class="drawer-action">
        <h3 class="title text-left">
            {{ $t('order.orderDetail.closeOrderDesc') }}
        </h3>
        <mtd-form
            ref="closeOrderForm"
            :rules="rules"
            :model="closeOrderForm"
        >
            <!-- <mtd-row>
                <mtd-col :span="12">
                    <mtd-form-item
                        label="关单原因:"
                        prop="reason"
                    >
                        <mtd-select v-model="closeOrderForm.reason">
                            <mtd-option
                                v-for="(item, index) in closeReasonOptions"
                                :key="index"
                                :label="item.name"
                                :value="item.value"
                            />
                        </mtd-select>
                    </mtd-form-item>
                </mtd-col>
            </mtd-row> -->
            <mtd-form-item :label="$t('order.orderDetail.remark') ">
                <mtd-textarea
                    v-model="closeOrderForm.remark"
                    rows="4"
                    maxlength="100"
                />
            </mtd-form-item>
        </mtd-form>
        <mtd-button
            type="primary"
            @click="closeOrder"
        >
            {{ $t('order.orderDetail.closeOrder') }}
        </mtd-button>
        <mtd-button @click="closeModel">
            {{ $t('order.orderDetail.cancel') }}
        </mtd-button>
    </div>
</template>

<script>
import STATUS from '@/constants/status';
import OrderServiceApi from '@/api/order/orderService';

export default {
    name: 'CloseOrder',
    props: {
        userInfo: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            closeOrderForm: {
                // reason: '',
                remark: '',
            },
            // closeReasonOptions: [
            //     {
            //         name: '机柜离线时归还',
            //         value: 'CO001',
            //     },
            //     {
            //         name: '未弹出充电宝',
            //         value: 'CO002',
            //     },
            //     {
            //         name: '机柜故障无法使用',
            //         value: 'CO003',
            //     },
            //     {
            //         name: '系统服务故障',
            //         value: 'CO004',
            //     },
            //     {
            //         name: '其他',
            //         value: 'CO005',
            //     },
            // ],
            rules: {
                // reason: { required: true, message: '必填' },
            },
        };
    },
    methods: {
        closeOrder() {
            this.$refs.closeOrderForm.validate((valid) => {
                if (!valid) return;
                this.$mtd.confirm({
                    title: this.$t('order.orderDetail.closeOrderDesc'),
                    message: this.$t('order.orderDetail.closeOrderConfirm'),
                    width: '430px',
                    type: 'warning',
                    showCancelButton: true,
                    onOk: async () => {
                        const param = {
                            ...this.closeOrderForm,
                            ...this.userInfo,
                            orderId: this.$route.params.orderId,
                            userId: this.$route.params.userId,
                        };
                        try {
                            const { code } = await OrderServiceApi.closeOrder(param);
                            if (code === STATUS.SUCCESS) {
                                this.$mtd.message({
                                    message: 'success',
                                    type: 'success',
                                });
                                this.$emit('closeModel', {
                                    isShowModel: false,
                                    detailLoading: true,
                                });
                                setTimeout(() => {
                                    window.location.reload();// reload
                                }, 5000);
                            }
                        } catch (e) {
                            // this.$mtd.message({
                            //     message: 'error',
                            //     type: 'error',
                            // });
                        }
                    },
                });
            });
        },
        closeModel() {
            this.$emit('closeModel', {
                isShowModel: false,
                detailLoading: false,
            });
        },
    },
};
</script>

<style lang="less" scoped>
.mtd-drawer-close {
  left: 16px !important;
  right: auto;
}
.text-left {
  text-align: left;
}
.drawer-action {
  text-align: right;
  &>button {
    min-width: 80px;
  }
  button+button {
    margin-left: 12px;
  }
}
.mtd-form {
  margin-top: 20px;
}
</style>
