<template>
    <div class="operation-log-comp">
        <mtd-tabs v-model="activeName">
            <mtd-tab-pane
                :label="$t('order.orderDetail.userOptLog')"
                value="user"
            >
                <mtd-table
                    :data="userLog"
                    :tooltip-props="{ size: 'small' }"
                >
                    <mtd-table-column
                        :label="$t('order.orderDetail.optType')"
                        width="200"
                        prop="optType"
                        fixed
                    />
                    <mtd-table-column
                        :label="$t('order.orderDetail.operateUserName')"
                        prop="username"
                        width="150"
                        fixed
                    />
                    <mtd-table-column
                        :label="$t('order.orderDetail.operateResult')"
                        width="100"
                        prop="result"
                    />
                    <mtd-table-column
                        :label="$t('order.orderDetail.optTime')"
                        prop="optTime"
                    />
                    <mtd-table-column
                        :label="$t('order.orderDetail.remark')"
                        prop="remark"
                    />
                </mtd-table>
            </mtd-tab-pane>
            <mtd-tab-pane
                :label="$t('order.orderDetail.manualLog')"
                value="manualLog"
            >
                <mtd-table
                    :data="manualLog"
                    :tooltip-props="{ size: 'small' }"
                >
                    <mtd-table-column
                        :label="$t('order.orderDetail.optType')"
                        width="200"
                        prop="optType"
                        fixed
                    />
                    <mtd-table-column
                        :label="$t('order.orderDetail.operateUserName')"
                        width="150"
                        prop="operator"
                        fixed
                    />
                    <mtd-table-column
                        :label="$t('order.orderDetail.operateResult')"
                        width="100"
                        prop="result"
                    />
                    <mtd-table-column
                        :label="$t('order.orderDetail.optTime')"
                        width="200"
                        prop="optTime"
                    />
                    <mtd-table-column
                        :label="$t('order.orderDetail.operateContent')"
                        prop="optContext"
                    />
                    <mtd-table-column
                        :label="$t('order.orderDetail.remark')"
                        prop="remark"
                    />
                </mtd-table>
            </mtd-tab-pane>
        </mtd-tabs>
    </div>
</template>

<script>
import utils from '@/common/util';
import { OPT_TYPE_MAP, RESULT_MAP, OPT_TYPE_CREATE_PAY_ORDER } from '@/constants/orderConstants';

export default {
    name: 'OperationLog',
    props: {
        userLogList: {
            type: Array,
            default: () => [],
        },
        manualLogList: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            activeName: 'user',
        };
    },
    computed: {
        userLog() {
            return this.userLogList.map((item) => {
                const {
                    optType,
                    busiType,
                    optTime,
                    result,
                } = item;
                return {
                    ...item,
                    result: RESULT_MAP[result] || '-',
                    optType: optType === OPT_TYPE_CREATE_PAY_ORDER ? this.$t('order.orderDetail.optTypeCase1', [busiType]) : OPT_TYPE_MAP[optType] || '-',
                    optTime: optTime ? utils.parseTimeToDate(optTime, 'YYYY-MM-DD HH:mm:ss') : '-',
                };
            });
        },
        manualLog() {
            return this.manualLogList.map((item) => {
                const {
                    optType,
                    optTime,
                    result,
                } = item;
                return {
                    ...item,
                    result: RESULT_MAP[result] || '-',
                    optType: OPT_TYPE_MAP[optType] || '-',
                    optTime: optTime ? utils.parseTimeToDate(optTime, 'YYYY-MM-DD HH:mm:ss') : '-',
                };
            });
        },
    },
};

</script>
