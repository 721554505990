export default {
    common: {
        menu: {
            agent: 'Agentur',
            agentList: 'Agenturverwaltung',
            EmployeeManagement: 'Mitarbeiterverwaltung',
            store: 'Filiale',
            storeList: 'Filialliste',
            storeType: 'Filialtyp',
            collapseText: 'Einklappen',
            storeAccount: 'Händlerkonto',
            permit: 'Berechtigungen',
            permitList: 'Berechtigungsliste',
            device: 'Gerät',
            asset: 'Geräteliste',
            bill: 'Abrechnung',
            benefit: 'Bestellprovision',
            cashout: 'Auszahlungsaufzeichnungen',
            orderManage: 'Bestellverwaltung',
            orderList: 'Bestellliste',
            setting: 'Allgemeine Konfiguration',
            faq: 'Häufig gestellte Fragen',
            material: 'Anzeigematerial',
            price: 'Preismodell',
        },
        login: {
            name: 'Ladeschrank-Verwaltungssystem',
            title: 'Anmeldung',
            placehloder: {
                username: 'Bitte geben Sie Konto/Telefonnummer ein',
                password: 'Bitte Passwort eingeben',
            },
            errorTip: 'Bitte geben Sie Ihr Konto/Ihre Telefonnummer und Ihr Passwort ein',
            logout: 'Abmelden',
        },
        selectPlaceholder: 'Bitte auswählen',
        emptyText: 'Keine Daten verfügbar',
        pleaseFillInCompletely: 'Bitte vollständig ausfüllen',
        confirm: 'Bestätigen',
    },
    agentCreate: {
        agentBasicInfo: 'Grundlegende Informationen der Agentur',
        agentName: 'Name der Agentur',
        principalName: 'Name des Verantwortlichen',
        phoneNumber: 'Telefonnummer',
        email: 'E-Mail',
        detailedAddress: 'Detaillierte Adresse',
        agentInfo: 'Agenturinformationen',
        contractFile: 'Vertragsdokumente',
        clickUpload: 'Zum Hochladen klicken',
        chargeInfo: 'Gebühreninformationen',
        headCompanyProfit: 'Gewinnbeteiligung der Zentrale',
        bankAccountInfo: 'Bankkontoinformationen',
        bankName: 'Bankname',
        branchName: 'Name der Zweigstelle',
        bankType: 'Banktyp',
        ordinary: 'Normal',
        savings: 'Sparen',
        bankAccount: 'Bankkonto',
        accountName: 'Kontoname',
        cancel: 'Abbrechen',
        submit: 'Einreichen',
        pleaseEnterAgentName: 'Bitte geben Sie den Namen der Agentur ein',
        pleaseEnterPrincipalName: 'Bitte geben Sie den Namen des Verantwortlichen ein',
        pleaseEnterCorrectPhoneNumber: 'Bitte geben Sie eine gültige Telefonnummer ein',
        pleaseEnterCorrectEmailFormat: 'Bitte geben Sie eine gültige E-Mail-Adresse ein',
        pleaseEnterDetailedAddress: 'Bitte geben Sie die detaillierte Adresse ein',
        pleaseEnterContractFile: 'Bitte geben Sie das Vertragsdokument ein',
        pleaseEnterBankName: 'Bitte geben Sie den Banknamen ein',
        pleaseEnterBranchName: 'Bitte geben Sie den Namen der Zweigstelle ein',
        pleaseEnterBankAccount: 'Bitte geben Sie die Bankkontonummer ein',
        pleaseEnterAccountName: 'Bitte geben Sie den Kontonamen ein',
        pleaseFillInCompletely: 'Bitte vollständig ausfüllen',
        pleaseSelectBankType: 'Bitte wählen Sie den Banktyp',
        addSuccess: 'Erfolgreich hinzugefügt',
        parentName: 'Übergeordneter Agent',
    },
    agentList: {
        agentId: 'Agentur-ID',
        agentName: 'Name der Agentur',
        agentLevel: 'Agenturstufe',
        headCompany: 'Hauptsitz',
        agent: 'Agentur',
        agentAccount: 'Agenturkonto',
        principalName: 'Name des Verantwortlichen',
        type: 'Agenturtyp',
        query: 'Suchen',
        create: 'Name des Verantwortlichen',
        operation: 'Agenturtyp',
        modifyProfitInfo: 'Suchen',
        headCompanyProfit: 'Erstellen',
        cancel: 'Aktion',
        confirm: 'Gewinnbeteiligungsinformationen ändern',
        superiorAgentName: 'Gewinnbeteiligung der Zentrale',
        principalPhoneNumber: 'Abbrechen',
        email: 'Bestätigen',
        belongingPlace: 'Zugehörigkeit',
        headCompanyProfitRatio: 'Gewinnbeteiligungsanteil der Zentrale',
        bankName: 'Bankname',
        branchName: 'Name der Zweigstelle',
        accountType: 'Kontotyp',
        bankAccount: 'Bankkontonummer',
        accountName: 'Kontoname',
        withdrawableAmount: 'Verfügbarer Auszahlungsbetrag',
        cooperationStatus: 'Kooperationsstatus',
        updateTime: 'Aktualisierungszeit',
        createTime: 'Erstellungszeit',
        pleaseEnterNumberWithTwoDecimalPlaces: 'Bitte geben Sie eine Zahl mit maximal zwei Dezimalstellen ein',
        modificationSuccess: 'Erfolgreich geändert',
        changeTip: 'Bitte geben Sie eine ganze Zahl zwischen 0 und 100 ein',
        edit: 'Bearbeiten',
    },
    agentEdit: {
        download: 'Vertrag herunterladen',
        saveTip: 'Erfolgreich bearbeitet',
    },
    agentUserItem: {
        agentName: 'Agenturname',
        userName: 'Benutzername',
        phoneNumber: 'Telefonnummer',
        email: 'E-Mail',
        loginPassword: 'Anmeldepasswort',
        role: 'Rolle',
        status: 'Status',
        enable: 'Aktivieren',
        disable: 'Deaktivieren',
        cancel: 'Abbrechen',
        confirm: 'Bestätigen',
        pleaseEnteragentName: 'Bitte wählen Sie einen Agenten',
        pleaseEnterUserName: 'Bitte geben Sie den Benutzernamen ein',
        pleaseEnterPhoneNumber: 'Bitte geben Sie die Telefonnummer ein',
        pleaseEnterEmail: 'Bitte geben Sie die E-Mail-Adresse ein',
        pleaseEnterLoginPassword: 'Bitte geben Sie mindestens eine 6-stellige Kombination aus Zahlen und Buchstaben ein',
        pleaseSelectRole: 'Bitte wählen Sie eine Rolle',
        pleaseEnterStatus: 'Bitte geben Sie den Status ein',
        edit: 'Bearbeiten',
        saveSuccess: 'Erfolgreich gespeichert',
        submitErrorTip: 'Bitte vollständig ausfüllen',
    },
    agentUser: {
        belongingCooperator: 'Zugehöriger Agent',
        loginAccount: 'Anmeldekonto',
        userName: 'Benutzername',
        phoneNumber: 'Telefonnummer',
        role: 'Rolle',
        search: 'Suchen',
        reset: 'Zurücksetzen',
        add: 'Hinzufügen',
        email: 'E-Mail',
        isCooperatorResponsiblePerson: 'Ist der Agenturleiter',
        is: 'Ja/Nein',
        status: 'Status',
        enableDisable: 'Aktivieren/Deaktivieren',
        operation: 'Aktion',
        edit: 'Bearbeiten',
        resetPassword: 'Passwort zurücksetzen',
        newPassword: 'Neues Passwort',
        cancel: 'Abbrechen',
        confirm: 'Bestätigen',
        addUser: 'Neuen Benutzer hinzufügen',
        editUserInfo: 'Benutzerinformationen bearbeiten',
        resetSuccess: 'Erfolgreich zurückgesetzt',
        enable: 'Aktivieren',
        disable: 'Deaktivieren',
        yes: 'Ja',
        no: 'Nein',
    },
    deviceAssetManagement: {
        device: 'Geräte-SN',
        belongingSubject: 'Zugehörige Entität',
        deviceType: 'Gerätetyp',
        businessStatus: 'Geschäftsstatus',
        belongingPersonnel: 'Asset-Eigentümer',
        search: 'Suchen',
        reset: 'Zurücksetzen',
        export: 'Exportieren',
        batchOperation: 'Massenoperation',
        cabinetChargingTreasure: 'Schließfach-Powerbank',
        deviceStatus: 'Gerätestatus',
        locatedStore: 'Standort des Geschäfts',
        storeBelonging: 'Geschäftszugehörigkeit',
        locatedCabinet: 'Standort des Schließfachs',
        deviceInformation: 'Geräteinformationen',
        view: 'Ansehen',
        circulationRecord: 'Umlaufprotokoll',
        chargingTreasure: 'Powerbank-SN',
        positionNumber: 'Lagerplatznummer',
        electricity: 'Batteriestand',
        chargingStatus: 'Ladestatus',
        faultStatus: 'Fehlerstatus',
        return: 'Zurück',
        refresh: 'Aktualisieren',
        cabinet: 'Schließfach',
        networkStatus: 'Netzwerkstatus',
        lastOnlineTime: 'Letzte Online-Zeit',
        inTreasureNumber: 'Anzahl der Powerbanks im Lager',
        positionStatus: 'Lagerplatzstatus',
        operation: 'Aktion',
        popUp: 'Auswerfen',
        popUpAll: 'Alle auswerfen',
        uploadFile: 'Datei hochladen',
        clickUpload: 'Klicken zum Hochladen',
        downloadTemplate: 'Vorlage herunterladen',
        targetSubject: 'Zielentität',
        operationPrompt: 'Operationstipps: Nur Geräte, die sich nicht im Lager befinden, können eingelagert werden. Nur Lagergeräte können ins Lager zurückgeführt werden. Geräte, die sich auf dem Weg zum Geschäft befinden, können übertragen werden.',
        cancel: 'Abbrechen',
        returnToWarehouse: 'Rückführung ins Lager',
        deviceTransfer: 'Geräteübertragung',
        batchWarehousing: 'Massenhafte Einlagerung',
        operator: 'Operator',
        operationType: 'Operationstyp',
        operationTime: 'Operationszeit',
        status: 'Status',
        popUpSuccess: 'Erfolgreich ausgeworfen',
        batchErrorTip: 'Bitte laden Sie die SN hoch und geben Sie die Zielentität ein',
        batchReturnErrorTip: 'Bitte laden Sie die SN hoch',
        warehouse: 'Lager',
        enRoute: 'Unterwegs',
        enterShopFinish: 'Im Geschäft abgeschlossen',
        lending: 'Vermietet',
        userShop: 'Verkauft',
        userLoss: 'Benutzer verloren',
        repairing: 'In Reparatur',
        loss: 'Gerät verloren',
        scrap: 'Ausgemustert',
        notExist: 'Existiert nicht',
        support: 'Halterung',
        unkown: 'Unbekannt',
        offline: 'Offline',
        online: 'Online',
        inCabin: 'Im Lager',
        offCabin: 'Nicht im Lager',
        poiBelongEmp: 'Geschäftszuständiger BD',
        addrAble: 'Lagerplatz verfügbar',
        addrDisable: 'Lagerplatz gesperrt',
        battery: 'Powerbank',
        popOption: 'Auswurfauswahl',
        popUnlock: 'Auswerfen, wenn aufladbar',
        popLock: 'Auswerfen, wenn nicht aufladbar',
        batchOperationSuccess: 'Operation erfolgreich',
        actionTitle: 'Operationstipps',
        actionBtn: 'Bestätigen',
    },
    permitIndex: {
        create: 'Erstellen',
        operation: 'Aktion',
        edit: 'Bearbeiten',
        authority: 'Berechtigungen',
        addRoleInfo: 'Neue Rolleninformationen hinzufügen',
        modifyRoleInfo: 'Rolleninformationen ändern',
        roleName: 'Rollenname',
        roleIdentification: 'Rollenkennzeichnung',
        roleDescription: 'Rollenbeschreibung',
        cancel: 'Abbrechen',
        confirm: 'Bestätigen',
        assignAuthority: 'Berechtigungen zuweisen',
        update: 'Aktualisieren',
        serialNumber: 'Seriennummer',
        pleaseEnterRoleName: 'Bitte Rollenname eingeben',
        pleaseEnterRoleIdentification: 'Bitte Rollenkennzeichnung eingeben',
        pleaseFillInCompletely: 'Bitte vollständig ausfüllen',
        saveSuccess: 'Erfolgreich gespeichert',
        updateSuccess: 'Erfolgreich aktualisiert',
    },
    poiAccount: {
        merchantAccount: 'Händlerkonto',
        email: 'E-Mail',
        merchantName: 'Händlername',
        query: 'Suchen',
        savingsOrdinary: 'Normales Sparen',
        operation: 'Aktion',
        edit: 'Bearbeiten',
        resendPassword: 'Passwort erneut senden',
        merchantInfoModification: 'Händlerinformationen ändern',
        account: 'Konto',
        bankName: 'Bankname',
        branchName: 'Zweigstellennamen',
        accountType: 'Kontotyp',
        ordinary: 'Normal',
        savings: 'Sparen',
        bankAccount: 'Bankkonto',
        accountName: 'Kontoname',
        cancel: 'Abbrechen',
        confirm: 'Bestätigen',
        cooperationStoreNumber: 'Anzahl der Partnergeschäfte',
        withdrawableAmount: 'Verfügbarer Auszahlungsbetrag',
        updateSuccess: 'Erfolgreich aktualisiert',
        resendPasswordSuccess: 'Passwort erfolgreich erneut gesendet',
        pleaseEnterAccount: 'Bitte Konto eingeben',
        pleaseEnterCorrectEmailFormat: 'Bitte geben Sie eine gültige E-Mail-Adresse ein',
        pleaseEnterName: 'Bitte Händlernamen eingeben',
        pleaseEnterBankName: 'Bitte Bankname eingeben',
        pleaseEnterBranchName: 'Bitte Zweigstellennamen eingeben',
        pleaseEnterBankType: 'Bitte Kontotyp eingeben',
        pleaseEnterBankAccount: 'Bitte Kontoname eingeben',
        pleaseEnterBankNumber: 'Bitte Bankkonto eingeben',
    },
    poiDetail: {
        basicInfo: 'Grundinformationen',
        edit: 'Bearbeiten',
        cooperationInfo: 'Partnerinformationen',
        remark: 'Bemerkungen',
        inputTip: 'Eingabe darf 50 Zeichen nicht überschreiten',
        return: 'Zurück',
        saveChanges: 'Änderungen speichern',
        notYetOpen: 'Noch nicht geöffnet',
        suspendBusiness: 'Vorübergehend geschlossen',
        inBusiness: 'Geöffnet',
        closed: 'Geschlossen',
        unrecognizable: 'Nicht erkennbar',
        storeName: 'Geschäftsname',
        businessStatus: 'Betriebsstatus',
        personInChargePhone: 'Telefonnummer des Verantwortlichen',
        storeId: 'Geschäfts-ID',
        businessHours: 'Öffnungszeiten',
        personInChargeEmail: 'E-Mail des Verantwortlichen',
        storeType: 'Geschäftstyp',
        storeAddress: 'Geschäftsadresse',
        cooperationStatus: 'Kooperationsstatus',
        merchantAccount: 'Händlerkonto',
        belongingAgent: 'Zugehöriger Agent',
        merchantEmail: 'Händler-E-Mail',
        belongingBd: 'Zugehöriger BD',
        prompt: 'Hinweis',
        saveTip: 'Es gibt bearbeitete Informationen. Möchten Sie wirklich zurückkehren?',
        confirmReturn: 'Bestätigen und zurückkehren',
        saveSuccess: 'Erfolgreich gespeichert',
        required: 'Pflichtfeld',
        unvaild: 'Nicht konform',
        check: 'Details anzeigen',
        license: 'Gewerbeschein',
        contactName: 'Name des Verantwortlichen',
        merchantName: 'Händlername',
    },
    poiList: {
        storeId: 'Geschäfts-ID',
        belongingAgent: 'Zugehöriger Agent',
        query: 'Suchen',
        operation: 'Aktion',
        basicInfo: 'Grundinformationen',
        storeName: 'Geschäftsname',
        storeType: 'Geschäftstyp',
        businessStatus: 'Betriebsstatus',
        businessHours: 'Öffnungszeiten',
        storeAddress: 'Geschäftsadresse',
        personInChargePhoneNumber: 'Telefonnummer des Verantwortlichen',
        cooperationStatus: 'Kooperationsstatus',
        belongingId: 'Zugehöriger BD',
        cabinetNumber: 'Anzahl der Schränke',
        currentTreasureNumber: 'Aktuelle Anzahl der Powerbanks',
        updateTime: 'Aktualisierungszeit',
        belongingBD: 'Zugehöriger BD',
        account: 'Händlerkonto',
        export: 'Exportieren',
        exportSuccessTip: 'Export erfolgreich, bitte überprüfen Sie Ihre E-Mail',
        benefit: 'Gewinnverteilungsquote',
        view: 'Ansehen',
        contactName: 'Name des Verantwortlichen',
        merchantName: 'Händlername',
        priceRule: 'Gebührenregelung',
        freeTime: 'Kostenlose Dauer: {num} Minuten',
        pricePeriod: 'Abrechnungszyklus: {count} Minuten',
        price: 'Preis: {num} {unit}',
        dayCappedPrice: 'Tageshöchstbetrag: {num} {unit}',
        cappedPrice: 'Kautionsbetrag: {num} {unit}',
        poiBenefit: 'Gewinnverteilungsquote des Geschäfts: {num}%',
    },
    poiType: {
        typeName: 'Typenname',
        typeStatus: 'Typenstatus',
        disable: 'Deaktiviert',
        enable: 'Normal',
        query: 'Suchen',
        add: 'Hinzufügen',
        name: 'Name',
        remark: 'Bemerkungen',
        status: 'Status',
        disableEnable: 'Deaktivieren/Aktivieren',
        createTime: 'Erstellungszeit',
        operation: 'Aktion',
        modify: 'Ändern',
        storeTypeName: 'Geschäftstypname',
        cancel: 'Abbrechen',
        confirm: 'Bestätigen',
        submitSuccess: 'Erfolgreich eingereicht',
        addText: 'Neu hinzufügen',
        editText: 'Bearbeiten',
        pleaseEnterTypeName: 'Bitte geben Sie den Geschäftstypnamen ein',
        pleaseEnterTypeStatus: 'Bitte wählen Sie den Status',
    },
    benefitIndex: {
        paymentRefundDate: 'Zahlungs- und Rückerstattungsdatum',
        selectDateRange: 'Wählen Sie den Datumsbereich',
        orderNumber: 'Bestellnummer',
        pleaseEnterOrderNumber: 'Bitte geben Sie die Bestellnummer ein',
        store: 'Geschäft',
        search: 'Suchen',
        export: 'Exportieren',
        billMonth: 'Abrechnungsmonat',
        billDate: 'Abrechnungsdatum',
        orderType: 'Bestelltyp',
        isCappedBilling: 'Obere Abrechnungsgrenze',
        orderAmount: 'Bestellbetrag',
        storeName: 'Geschäftsname',
        defaultAmount: 'Vertragsstrafe',
        defaultTaxAmount: 'Vertragsstrafe Steuern',
        rentTaxAmount: 'Mietsteuern',
        rentAmount: 'Mietbetrag',
        cooperationMode: 'Kooperationsmodell',
        merchantAccount: 'Händlerkonto',
        agent: 'Agent',
        agentName: 'Agentenname',
        profitSharingRatio: 'Gewinnverteilungsquote',
        storeProfitSharingRatio: 'Gewinnverteilungsquote des Geschäfts',
        headquartersProfitSharingRatio: 'Gewinnverteilungsquote der Zentrale',
        defaultFeeProfitSharingAmount: 'Vertragsstrafe MT Gewinnbetrag',
        defaultFeeHeadquartersProfitSharingAmount: 'Vertragsstrafe Zentrale Gewinnbetrag',
        rentFeeProfitSharingAmount: 'Mietgebühren MT Gewinnbetrag',
        rentFeeHeadquartersProfitSharingAmount: 'Mietgebühren Zentrale Gewinnbetrag',
        rentFeeAgentParticipationSharingAmount: 'Mietgebühren Agent Beteiligungsbetrag',
        rentFeeStoreProfitSharingAmount: 'Mietgebühren Geschäfts Gewinnbetrag',
        rentFeeAgentProfitSharingAmount: 'Mietgebühren Agent Gewinnbetrag',
        headquartersProfitSharingAmount: 'Gesamtgewinnbetrag der Zentrale',
        headquartersAmount: 'Gesamtgewinnbetrag',
        cutDay: 'Zahlungs-/Rückerstattungsdatum',
    },
    cashoutList: {
        initiationDate: 'Initiierungsdatum',
        selectDateRange: 'Wählen Sie den Datumsbereich',
        merchantAccountAgent: 'Händlerkonto/Agent',
        pleaseEnterMerchantAccountAgent: 'Bitte geben Sie die Händlerkonto-ID/Agenten-ID ein',
        payeeAccountName: 'Empfängerkontoname',
        pleaseEnterPayeeAccountName: 'Bitte geben Sie den Empfängerkontonamen ein',
        merchantAccountId: 'Händlerkonto-ID/Agenten-ID',
        merchantAccountName: 'Händlerkonto-Name/Agenten-Name',
        customerType: 'Kundentyp',
        merchant: 'Händler',
        meituan: 'Meituan',
        agent: 'Agent',
        withdrawalInitiationTime: 'Auszahlungsinitiierungszeit',
        merchantAccountNameAgentName: 'Händlerkonto-Name/Agenten-Name',
        taxAmount: 'Steuern',
        billAmount: 'Rechnungsbetrag',
        withdrawalAmount: 'Auszahlungsbetrag',
        bankName: 'Bankname',
        branchName: 'Zweigstellenname',
        accountType: 'Kontotyp',
        accountName: 'Bankkontoname',
        bankCardNumber: 'Bankkartennummer',
        search: 'Suchen',
        export: 'Exportieren',
        required: 'Pflichtfeld',
    },
    order: {
        orderList: {
            searchForm: {
                pleaseSelectPh: 'Bitte auswählen',
                pleaseInputPh: 'Bitte eingeben',
                orderNumber: 'Bestellnummer',
                orderNumberPh: 'Bitte geben Sie die Bestellnummer ein',
                phoneNumber: 'Handynummer',
                orderStatus: 'Status',
                depositType: 'Kautionstyp',
                userId: 'Benutzer-ID',
                lendPoi: 'Miet-POI',
                returnPoi: 'Rückgabe-POI',
                batterySn: 'Bauteil-SN',
                lendCabinSn: 'Mietschrank',
                returnCabinSn: 'Rückgabeschrank',
                lendPoiName: 'Mietgeschäft',
                returnPoiName: 'Rückgabegeschäft',
                createOrderTime: 'Bestellzeit',
                returnTime: 'Rückgabezeit',
                search: 'Suchen',
                resetting: 'Zurücksetzen',
                expand: 'Erweitern',
                retract: 'Einklappen',
                storeName: 'Geschäftsname',
                merchantAccount: 'Händlerkonto',
                createTime: 'Erstellungszeit',
                updateTime: 'Aktualisierungszeit',
                reset: 'Zurücksetzen',
            },
            tableData: {
                orderId: 'Bestellnummer',
                userId: 'Benutzer-ID',
                createTime: 'Bestellzeit',
                returnTime: 'Rückgabezeit',
                status: 'Bestellstatus',
                payMode: 'Kautionstyp',
                actualFee: 'Tatsächlich gezahlter Betrag',
                lendPoiName: 'Mietort',
                returnPoiName: 'Rückgabeort',
                hand: 'Aktion',
                check: 'Ansehen',
            },
        },
        orderDetail: {
            artificialReturn: 'Manuelle Rückgabe',
            lendTime: 'Auswurfzeit',
            usageDuration: 'Nutzungsdauer',
            day: 'Tage',
            hour: 'Stunden',
            minute: 'Minuten',
            second: 'Sekunden',
            returnTime: 'Rückgabezeit',
            chooseTime: 'Zeit auswählen',
            returnCabin: 'Rückgabeschrank',
            returnCabinPd: 'Bitte geben Sie die Schrank-SN ein',
            returnPoiName: 'Rückgabegeschäft',
            remark: 'Bemerkung',
            actualAmountReceived: 'Tatsächlich erhaltene Betrag',
            return: 'Rückgabe',
            confirm: 'Bestätigen',
            cancel: 'Abbrechen',
            mustInput: 'Pflichtfeld',
            returnTimeRule: 'Die Rückgabezeit muss kleiner als die aktuelle Zeit sein',
            artificialReturnConfirm: 'Manuelle Rückgabebestätigung',
            artificialReturnConfirmDes: 'Möchten Sie die Bestellung manuell zurückgeben?',
            closeOrder: 'Bestellung schließen',
            closeOrderDesc: 'Bestellung schließen',
            closeOrderConfirm: 'Sind Sie sicher, dass Sie die Bestellung schließen möchten?',
            stopCharge: 'Berechnung pausieren',
            orderAmount: 'Bestellbetrag',
            suspendTime: 'Pausendauer',
            stopTime24: '24 Stunden',
            stopChargeConfirm: 'Sind Sie sicher, dass Sie die Berechnung pausieren möchten?',
            refund: 'Rückerstattung',
            originalFee: 'Ursprünglich gezahlter Betrag',
            canRefundFee: 'Erstattungsfähiger Betrag',
            refundFee: 'Rückerstattungsbetrag',
            computedRefundFee: 'Der Rückerstattungsbetrag muss größer als 0 Euro sein',
            refundConfirm: 'Rückerstattungsbestätigung',
            refundConfirmMsg1: 'Möchten Sie die Bestellung mit einer Rückerstattung von {0} bestätigen?',
            refundConfirmMsg2: 'Die Bestellung wurde bereits um {0} erstattet. Möchten Sie die Bestellung weiterhin um {1} erstatten?',
            orderId: 'Bestellnummer',
            userName: 'Benutzername',
            mobile: 'Telefonnummer',
            createTime: 'Erstellungszeit',
            payTime: 'Zahlungsdatum',
            payId: 'Zahlungsnummer',
            status: 'Status',
            actualFee: 'Tatsächlich gezahlter Betrag',
            lendInfo: 'Mietinformationen',
            lendPoiName: 'Mietort',
            lendPoiId: 'Miet-POI',
            lendCabinType: 'Mietschrank-SN',
            orderLendTime: 'Befehlsauswurfzeit',
            returnPoiId: 'Rückgabe-POI',
            returnCabinType: 'Rückgabeschrank-SN',
            bizReturnTime: 'Geschäftliche Rückgabezeit',
            lendBatteryType: 'Schatz-SN',
            returnType: 'Rückgabemethode',
            returnTypeCase1: 'Normal',
            returnTypeCase2: 'Stromausfall',
            returnTypeCase3: 'Nicht zurückgegeben',
            returnTypeCase4: '00 Verriegelung',
            returnTypeCase5: '00 Verriegelung nach Verlust',
            returnTypeCase6: 'Stromausfall 00 Verriegelung',
            returnTypeCase7: 'Stromausfall 00 Verriegelung nach Verlust',
            returnTypeCase8: 'Fremdkörperrückgabe',
            manualReturnCase1: 'Manuell',
            orderReturnTime: 'Befehlsrückgabezeit',
            userPhonePower: 'Anfänglicher Akkustand des Telefons',
            lendPower: 'Akkustand des Schatzes',
            chargeType: 'Abrechnungsmethode',
            chargeTypeCase1: 'Befehl',
            chargeTypeCase2: 'Manuell',
            chargeTypeCase3: 'Stromausfall',
            chargeTypeCase4: 'Stromverbrauch',
            chargeTypeCase5: 'Fehler',
            chargeTypeCase6: 'Beschwerde',
            chargeTypeCase7: '00 Verriegelungsrückgabe fehlgeschlagen',
            chargeTypeCase8: 'Stromausfall 00 Verriegelungsrückgabe fehlgeschlagen',
            userOptLog: 'Benutzeraktionsprotokoll',
            optType: 'Aktionstyp',
            operateUserName: 'Aktion durchgeführt von',
            operateResult: 'Ergebnis der Aktion',
            optTime: 'Aktionszeit',
            manualLog: 'Manuelles Aktionsprotokoll',
            operateContent: 'Aktionsinhalt',
            optTypeCase1: 'Erstellen {0} Zahlungsauftrag',
            orderFlow: 'Prozessfortschritt',
            orderFlowStep1: 'Bestellung erstellen',
            orderFlowStep2: 'Erfolgreich ausgeworfen',
            orderFlowStep3: 'Erfolgreiche Rückgabe',
            orderFlowStep4: 'Abgeschlossen',
            leaseDuration: 'Mietdauer',
            payInfo: 'Zahlungsinformationen',
            orderType: 'Bestelltyp',
            depositFee: 'Kautionsbetrag',
            depositRefundFee: 'Systemrückerstattung',
            originFee: 'Ursprünglicher Bestellpreis',
            needPayFee: 'Zu zahlender Betrag',
            manualRefundFee: 'Manuelle Rückerstattung',
            chargingPeriod: 'Abrechnungsdauer',
            chargingRule: 'Abrechnungsregeln',
        },
        orderConstants: {
            MAX_LENGTH_VALIDATE: 'Maximal 100 Zeichen eingeben',
            DEPOSIT_TYPE_DESC: {
                DEPOSIT: 'Kaution',
                NO_REFUND: 'Kautionsfrei',
            },
            ORDER_STATUS_QUERY_1: 'Warten auf Autorisierung',
            ORDER_STATUS_QUERY_2: 'Warten auf Zahlung',
            ORDER_STATUS_QUERY_3: 'In Miete',
            ORDER_STATUS_QUERY_4: 'Erfolgreiche Rückgabe',
            ORDER_STATUS_QUERY_5: 'Abgeschlossen',
            ORDER_STATUS_QUERY_6: 'Manuelle Rückerstattung',
            ORDER_STATUS_QUERY_7: 'Wird ausgeworfen',
            ORDER_STATUS_QUERY_8: 'Geschlossen',
            SOURCE_MAP: {
                Line: 'LINE Mini-Programm',
                App: 'App',
            },
            OPT_TYPE_MAP: {
                1000: 'Geschäftsauftrag erstellen',
                1001: 'Bestellung löschen',
                1101: 'Zahlungsauftrag erstellen',
                1102: 'Initiieren einer kennwortlosen Abbuchung',
                1103: 'Systemrückerstattung der Kaution',
                1104: 'Kennwortlose Abbuchung erfolgreich',
                1105: 'Aktive Zahlung',
                1201: 'Befehl zum Auswerfen der Powerbank',
                1202: 'Powerbank auswerfen',
                1203: 'Powerbank zurückgeben',
                1204: 'Bestellbeschwerde erstellen',
                1205: 'Bestellbeschwerde erfolgreich',
                1206: 'Bestellbeschwerde fehlgeschlagen',
                1207: 'Rückerstattung nach Erreichen des Limits',
                1208: 'Händler beantragt Rückerstattung',
                1301: 'Manuelle Rückgabe',
                1302: 'Manuelle Rückerstattung',
                1303: 'Manuelles Schließen der Bestellung',
                1304: 'Abrechnung pausieren',
                1305: 'Pausenzeit verlängern',
                1310: 'BD-Rückerstattung',
                1311: 'Unbezahlte Bestellung ändern',
                1312: 'Aktiver Servicepreis ändern',
                1313: 'Pausierte Abrechnung löschen',
                1401: 'Aktiver Kauf der Powerbank',
                1402: 'Gutschein nach dem Verkauf ausstellen',
                1403: 'Gutschein nach dem Verkauf stornieren',
                2100: 'Intelligenter Kundenservice schließt Bestellung ab',
                2101: 'Mitarbeiterüberprüfung',
            },
            RESULT_MAP: {
                0: 'Erfolgreich',
                1: 'Fehlgeschlagen',
            },
            DATEPICKER_SHORTCUTS: {
                today: 'Heute',
                yesterday: 'Gestern',
                last7Days: 'Letzte 7 Tage',
                last14Days: 'Letzte 14 Tage',
                last30Days: 'Letzte 30 Tage',
                last90Days: 'Letzte 90 Tage',
            },
            SUSPEND_TIME: {
                '24Hours': '24 Stunden',
            },
        },
    },
    setting: {
        faq: {
            language: 'Sprache',
            title: 'Titel',
            search: 'Suche',
            reset: 'Zurücksetzen',
            add: 'Hinzufügen',
            questionTitle: 'Häufig gestellte Fragen Titel',
            questionContent: 'Häufig gestellte Fragen Inhalt',
            check: 'Ansehen',
            status: 'Status',
            action: 'Aktion',
            edit: 'Bearbeiten',
            delete: 'Löschen',
            confirm: 'Bestätigen',
            cancel: 'Abbrechen',
            content: 'Inhalt',
            placeholder: 'Bitte Inhalt eingeben...',
            picture: 'Bild',
            addQuestion: 'Häufig gestellte Frage hinzufügen',
            editQuestion: 'Häufig gestellte Frage bearbeiten',
            save: 'Erfolgreich gespeichert',
            warning: 'Hinweis',
            deleteContent: 'Möchten Sie diese Frage wirklich löschen?',
            deleteTip: 'Erfolgreich gelöscht',
            enableTip: 'Möchten Sie diese Frage wirklich aktivieren?',
            disableTip: 'Möchten Sie diese Frage wirklich deaktivieren?',
            switchtip: 'Erfolgreich umgeschaltet',
            required: 'Pflichtfeld',
        },
        price: {
            freeTime: 'Kostenlose Nutzungsdauer',
            minute: 'Minuten',
            pricePeriod: 'Abrechnungszyklus',
            hour: 'Stunden',
            halfHour: '0,5 Stunden',
            oneHour: '1 Stunde',
            unitPrice: 'Abrechnungspreis',
            dayCappedPrice: 'Tageshöchstgebühr',
            cappedPrice: 'Kautionsbetrag',
            saveConf: 'Gebührenvorlage synchronisieren',
            freeTimeTip: 'Bitte geben Sie eine ganze Zahl größer als 0 ein',
            pricePeriodTip: 'Bitte wählen Sie einen Abrechnungszyklus',
            commonTip: 'Bitte geben Sie eine Zahl größer als 0 ein',
            tip: 'Hinweis',
            saveTip: 'Möchten Sie die Gebührenvorlage wirklich synchronisieren?',
            saveSuccess: 'Erfolgreich gespeichert',
        },
        material: {
            brandName: 'Markenname',
            logo: 'Logo-Bild',
            logoTip: 'Bildformat: PNG, JPG, GIF, Seitenverhältnis: 1:1, Bildgröße darf 1 MB nicht überschreiten',
            baner: 'QR-Code Landing Page Karussell-Bild',
            bannerTip: 'Bildformat: PNG, JPG, Breite*Höhe: 750px * 600px, Bildgröße darf 5 MB nicht überschreiten',
            link: 'Kundendienstkontakt',
            withdrawalThreshold: 'Mindestbetrag für Händlerauszahlung',
            save: 'Konfiguration speichern',
            brandNameTip: 'Bitte geben Sie den Markennamen ein',
            withdrawalThresholdTip: 'Bitte geben Sie den Mindestbetrag für die Auszahlung ein',
            linkTip: 'Bitte geben Sie die Kundendienstkontaktinformationen ein',
            logoRequireTip: 'Bitte laden Sie das Logo-Bild hoch',
            bannerRequireTip: 'Bitte laden Sie das Karussell-Bild für die Landing Page hoch',
            tip: 'Hinweis',
            saveTip: 'Möchten Sie die Konfiguration wirklich speichern? Die neue Konfiguration wird die alte überschreiben',
            saveSuccess: 'Erfolgreich gespeichert',
        },
    },
    el: {
        locale: 'zh-CN',
        datepicker: {
            selectDate: 'Datum auswählen',
            selectTime: 'Zeit auswählen',
            startTime: 'Startzeit',
            endTime: 'Endzeit',
            now: 'Jetzt',
            ok: 'Bestätigen',
            month: 'Monat',
            month1: 'Januar',
            month2: 'Februar',
            month3: 'März',
            month4: 'April',
            month5: 'Mai',
            month6: 'Juni',
            month7: 'Juli',
            month8: 'August',
            month9: 'September',
            month10: 'Oktober',
            month11: 'November',
            month12: 'Dezember',
            year: 'Jahr',
            weeks: {
                sun: 'Tag',
                mon: 'Mo',
                tue: 'Di',
                wed: 'Mi',
                thu: 'Do',
                fri: 'Fr',
                sat: 'Sa',
            },
            months: {
                m1: 'Januar',
                m2: 'Februar',
                m3: 'März',
                m4: 'April',
                m5: 'Mai',
                m6: 'Juni',
                m7: 'Juli',
                m8: 'August',
                m9: 'September',
                m10: 'Oktober',
                m11: 'November',
                m12: 'Dezember',
            },
        },
        pagination: {
            goto: 'Gehe zu',
            pagesize: 'Einträge/Seite',
            total: 'Insgesamt {total} Einträge',
            pageClassifier: 'Seite',
        },
    },
    editor: {
        editor: {
            more: 'Mehr',
            justify: 'Ausrichten',
            indent: 'Einzug',
            image: 'Bild',
            video: 'Video',
        },
        common: {
            ok: 'Bestätigen',
            delete: 'Löschen',
            enter: 'Eingabetaste',
        },
        blockQuote: {
            title: 'Zitat',
        },
        codeBlock: {
            title: 'Codeblock',
        },
        color: {
            color: 'Schriftfarbe',
            bgColor: 'Hintergrundfarbe',
            default: 'Standardfarbe',
            clear: 'Hintergrundfarbe löschen',
        },
        divider: {
            title: 'Trennlinie',
        },
        emotion: {
            title: 'Emoticon',
        },
        fontSize: {
            title: 'Schriftgröße',
            default: 'Standard-Schriftgröße',
        },
        fontFamily: {
            title: 'Schriftart',
            default: 'Standard-Schriftart',
        },
        fullScreen: {
            title: 'Vollbild',
        },
        header: {
            title: 'Titel',
            text: 'Text',
        },
        image: {
            netImage: 'Online-Bild',
            delete: 'Bild löschen',
            edit: 'Bild bearbeiten',
            viewLink: 'Link anzeigen',
            src: 'Bildadresse',
            desc: 'Bildbeschreibung',
            link: 'Bildlink',
        },
        indent: {
            decrease: 'Einzug verkleinern',
            increase: 'Einzug vergrößern',
        },
        justify: {
            left: 'Linksbündig',
            right: 'Rechtsbündig',
            center: 'Zentriert',
            justify: 'Blocksatz',
        },
        lineHeight: {
            title: 'Zeilenhöhe',
            default: 'Standard-Zeilenhöhe',
        },
        link: {
            insert: 'Link einfügen',
            text: 'Linktext',
            url: 'Linkadresse',
            unLink: 'Link entfernen',
            edit: 'Link ändern',
            view: 'Link anzeigen',
        },
        textStyle: {
            bold: 'Fett',
            clear: 'Formatierung löschen',
            code: 'Inline-Code',
            italic: 'Kursiv',
            sub: 'Tiefgestellt',
            sup: 'Hochgestellt',
            through: 'Durchgestrichen',
            underline: 'Unterstrichen',
        },
        undo: {
            undo: 'Rückgängig',
            redo: 'Wiederholen',
        },
        todo: {
            todo: 'To-Do',
        },
        listModule: {
            unOrderedList: 'Ungeordnete Liste',
            orderedList: 'Geordnete Liste',
        },
        tableModule: {
            deleteCol: 'Spalte löschen',
            deleteRow: 'Zeile löschen',
            deleteTable: 'Tabelle löschen',
            widthAuto: 'Breite automatisch anpassen',
            insertCol: 'Spalte einfügen',
            insertRow: 'Zeile einfügen',
            insertTable: 'Tabelle einfügen',
            header: 'Tabellenkopf',
        },
        videoModule: {
            delete: 'Video löschen',
            uploadVideo: 'Video hochladen',
            insertVideo: 'Video einfügen',
            videoSrc: 'Video-Adresse',
            videoSrcPlaceHolder: 'Video-Datei-URL oder Drittanbieter-<iframe>',
            videoPoster: 'Video-Cover',
            videoPosterPlaceHolder: 'Cover-Bild-URL',
            ok: 'Bestätigen',
            editSize: 'Größe ändern',
            width: 'Breite',
            height: 'Höhe',
        },
        uploadImgModule: {
            uploadImage: 'Bild hochladen',
            uploadError: 'Fehler beim Hochladen von {{fileName}}',
        },
        highLightModule: {
            selectLang: 'Sprache auswählen',
        },
    },
};
