<template>
    <div class="lend-info-comp">
        <p class="comp-title">
            {{ $t('order.orderDetail.lendInfo') }}
        </p>
        <mtd-row>
            <mtd-col :span="6">
                <span>{{ $t('order.orderDetail.lendPoiName') }}：</span>
                <span
                    class="pointer"
                >
                    {{ transferData.lendPoiName }}
                </span>
            </mtd-col>
            <mtd-col :span="4">
                <span>{{ $t('order.orderDetail.lendPoiId') }}：</span>
                <span
                    class="pointer"
                >
                    {{ transferData.lendPoiId }}
                </span>
            </mtd-col>
            <mtd-col :span="8">
                <span>{{ $t('order.orderDetail.lendCabinType') }}：</span>
                <mtd-tag v-if="transferData.lendCabinType">
                    {{ transferData.lendCabinType }}
                </mtd-tag>
                <span
                    class="pointer"
                >
                    {{ transferData.lendCabinSn }}
                </span>
            </mtd-col>
            <mtd-col :span="6">
                <span>{{ $t('order.orderDetail.orderLendTime') }}：</span>
                <span>{{ transferData.lendTime }}</span>
            </mtd-col>
        </mtd-row>
        <mtd-row>
            <mtd-col :span="6">
                <span>{{ $t('order.orderDetail.returnPoiName') }}：</span>
                <span
                    class="pointer"
                >
                    {{ transferData.returnPoiName }}
                </span>
            </mtd-col>
            <mtd-col :span="4">
                <span>{{ $t('order.orderDetail.returnPoiId') }}：</span>
                <span>{{ transferData.returnPoiId }}</span>
            </mtd-col>
            <mtd-col :span="8">
                <span>{{ $t('order.orderDetail.returnCabinType') }}：</span>
                <mtd-tag v-if="transferData.returnCabinType">
                    {{ transferData.returnCabinType }}
                </mtd-tag>
                <span
                    class="pointer"
                >
                    {{ transferData.returnCabinSn }}
                </span>
            </mtd-col>
            <mtd-col :span="6">
                <span>{{ $t('order.orderDetail.bizReturnTime') }}：</span>
                <span>{{ transferData.bizReturnTime }}</span>
            </mtd-col>
        </mtd-row>
        <mtd-row>
            <mtd-col :span="6">
                <span>{{ $t('order.orderDetail.lendBatteryType') }}：</span>
                <mtd-tag v-if="transferData.lendBatteryType">
                    {{ transferData.lendBatteryType }}
                </mtd-tag>
                <span
                    class="pointer"
                >
                    {{ transferData.batterySn }}
                </span>
            </mtd-col>
            <mtd-col :span="4">
                <span>{{ $t('order.orderDetail.returnType') }}：</span>
                <mtd-tag
                    v-if="transferData.returnType === 1"
                    theme="green"
                >
                    {{ $t('order.orderDetail.returnTypeCase1') }}
                </mtd-tag>
                <mtd-tag
                    v-if="transferData.returnType === 2"
                    theme="orange"
                >
                    {{ $t('order.orderDetail.returnTypeCase2') }}
                </mtd-tag>
                <mtd-tag
                    v-if="transferData.returnType === 3"
                    theme="red"
                >
                    {{ $t('order.orderDetail.returnTypeCase3') }}
                </mtd-tag>
                <mtd-tag
                    v-if="transferData.returnType === 4"
                    theme="red"
                >
                    {{ $t('order.orderDetail.returnTypeCase4') }}
                </mtd-tag>
                <mtd-tag
                    v-if="transferData.returnType === 5"
                    theme="red"
                >
                    {{ $t('order.orderDetail.returnTypeCase5') }}
                </mtd-tag>
                <mtd-tag
                    v-if="transferData.returnType === 6"
                    theme="red"
                >
                    {{ $t('order.orderDetail.returnTypeCase6') }}
                </mtd-tag>
                <mtd-tag
                    v-if="transferData.returnType === 7"
                    theme="red"
                >
                    {{ $t('order.orderDetail.returnTypeCase7') }}
                </mtd-tag>
                <mtd-tag
                    v-if="transferData.returnType === 8"
                    theme="red"
                >
                    {{ $t('order.orderDetail.returnTypeCase8') }}
                </mtd-tag>
                <mtd-tag
                    v-if="transferData.manualReturn === 1"
                    theme="red"
                >
                    {{ $t('order.orderDetail.manualReturnCase1') }}
                </mtd-tag>
            </mtd-col>
            <!-- <mtd-col :span="8">
                <span>机柜断电时间：</span>
                <span> {{ transferData.cabinPowerOffTime }}</span>
            </mtd-col> -->
            <mtd-col :span="8">
                <span>{{ $t('order.orderDetail.orderReturnTime') }}：</span>
                <span>{{ transferData.returnTime }}</span>
            </mtd-col>
            <mtd-col :span="6">
                <span>{{ $t('order.orderDetail.userPhonePower') }}：</span>
                <span>{{ transferData.userPhonePower }}</span>
            </mtd-col>
        </mtd-row>
        <mtd-row>
            <mtd-col :span="6">
                <span>{{ $t('order.orderDetail.lendPower') }}：</span>
                <span> {{ transferData.lendPower }}</span>
                <span>% -> </span>
                <span>{{ transferData.returnPower }} %</span>
            </mtd-col>
            <mtd-col :span="4">
                <span>{{ $t('order.orderDetail.chargeType') }}：</span>
                <mtd-tag
                    v-if="getChargeType"
                    :theme="getTagColor"
                >
                    {{ getChargeType }}
                </mtd-tag>
                <span v-else>-</span>
            </mtd-col>
            <!-- <mtd-col :span="8">
                <span>理论耗电时间：</span>
                <span>{{ transferData.consumePowerTime }}</span>
            </mtd-col> -->
        </mtd-row>
        <mtd-row>
            <!-- <mtd-col :span="6">
                <span>理论耗电时长：</span>
                <span>{{ transferData.consumePowerDuration }}</span>
            </mtd-col> -->
            <!-- <mtd-col :span="4">
                <span>充电宝评分：</span>
                <span>{{ typeof transferData.batteryHealth === 'number' ? transferData.batteryHealth : '-' }}</span>
            </mtd-col> -->
            <!-- <mtd-col :span="8">
                <span>使用数据：</span>
                <span v-if="transferData.batteryLabel">

                    <mtd-tag
                        v-for="item in transferData.batteryLabel"
                        :key="item.label"
                        theme="red"
                    >
                        {{ item.label }}
                    </mtd-tag>
                </span>
                <span v-else>-</span>
            </mtd-col> -->
        </mtd-row>
    </div>
</template>

<script>
import utils from '@/common/util';
// import { merchantListUrl, cabinetListUrl, batteryListUrl } from '@/constants/targetUrlConstants';

export default {
    name: 'LendInfo',
    props: {
        lendData: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            TypeMap: {
                1: 'TZ1',
                2: 'TZ2',
                3: 'TZ3',
                4: 'TZ4',
                5: 'TZ5',
            },
            chargeTypeArrary: [
                this.$t('order.orderDetail.chargeTypeCase1'),
                this.$t('order.orderDetail.chargeTypeCase2'),
                this.$t('order.orderDetail.chargeTypeCase3'),
                this.$t('order.orderDetail.chargeTypeCase4'),
                this.$t('order.orderDetail.chargeTypeCase5'),
                this.$t('order.orderDetail.chargeTypeCase6'),
                this.$t('order.orderDetail.chargeTypeCase7'),
                this.$t('order.orderDetail.chargeTypeCase8'),
            ],
        };
    },
    computed: {
        getTagColor() {
            if (this.transferData.chargeType === 1) { // 指令
                return 'green';
            } else if (this.transferData.chargeType === 3 || this.transferData.chargeType === 4) { // 断电、耗电
                return 'red';
            } else {
                return 'orange';
            }
        },
        getChargeType() {
            return this.chargeTypeArrary[this.transferData.chargeType - 1];
        },
        transferData() {
            const {
                lendTime,
                bizReturnTime,
                cabinPowerOffTime,
                returnTime,
                lendCabinType,
                lendBatteryType,
                returnCabinType,
                lendPower,
                returnPower,
                returnCabinSn,
                returnPoiName,
                returnPoiId,
                lendPoiName,
                lendPoiId,
                lendCabinSn,
                consumePowerTime,
                userPhonePower,
            } = this.lendData;

            return {
                ...this.lendData,
                lendTime: lendTime ? utils.parseTimeToDate(lendTime, 'YYYY-MM-DD HH:mm:ss') : '-',
                bizReturnTime: bizReturnTime ? utils.parseTimeToDate(bizReturnTime, 'YYYY-MM-DD HH:mm:ss') : '-',
                cabinPowerOffTime: cabinPowerOffTime ? utils.parseTimeToDate(cabinPowerOffTime, 'YYYY-MM-DD HH:mm:ss') : '-',
                returnTime: returnTime ? utils.parseTimeToDate(returnTime, 'YYYY-MM-DD HH:mm:ss') : '-',
                lendCabinType: this.TypeMap[lendCabinType],
                lendBatteryType: this.TypeMap[lendBatteryType],
                returnCabinType: this.TypeMap[returnCabinType],
                lendPower: this.formatPowerOnShow(lendPower),
                returnPower: this.formatPowerOnShow(returnPower),
                returnCabinSn: returnCabinSn || '-',
                returnPoiName: returnPoiName || '-',
                returnPoiId: returnPoiId || '-',
                lendPoiName: lendPoiName || '-',
                lendPoiId: lendPoiId || '-',
                lendCabinSn: lendCabinSn || '-',
                consumePowerTime: consumePowerTime ? utils.parseTimeToDate(consumePowerTime, 'YYYY-MM-DD HH:mm:ss') : '-',
                userPhonePower: userPhonePower || '-',
            };
        },
    },
    methods: {
        formatPowerOnShow(power) {
            if (!power && power !== 0) {
                return '-';
            }

            return power;
        },

        // handleClickPoi(poiId) {
        //     const { location: { origin } = {} } = window;
        //     const targetUrl = `${origin}${merchantListUrl}?poiId=${poiId}`;
        //     window.open(targetUrl, '_blank');
        // },

        // handleClickPoiSn(poiId) {
        //     const { location: { origin } = {} } = window;
        //     const targetUrl = `${origin}${cabinetListUrl}?poi=${poiId}`;
        //     window.open(targetUrl, '_blank');
        // },

        // handleClickCabinSn(cabinSn) {
        //     const { location: { origin } = {} } = window;
        //     const targetUrl = `${origin}${cabinetListUrl}?sn=${cabinSn}`;
        //     window.open(targetUrl, '_blank');
        // },

        // handleClickBatterySn(batterySn) {
        //     const { location: { origin } = {} } = window;
        //     const targetUrl = `${origin}${batteryListUrl}?batterySn=${batterySn}`;
        //     window.open(targetUrl, '_blank');
        // },
    },
};
</script>

<style lang="less" scoped>
  .lend-info-comp {
    width: 100%;
    .comp-title {
      margin-bottom: 10px;
      font-size: 16px;
      color: #000;
    }
    .mtd-col {
      margin: 5px 0;
    }
    .mtd-tag {
      margin-right: 5px;
    }
  }

  .pointer {
    // cursor: pointer;
  }
</style>
