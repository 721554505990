
























































































































import { Component, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';

@Component({
    components: {
    },
})
export default class materialIndex extends Vue {
    @State((state) => state.common.agentConfig) agentConfig: any;

    form: any = {
        logoPicture: null,
        locationPictureList: new Array(3).fill({}),
    };

    ruleCustom: {[key: string]: {}} = {
        brandName: {
            required: true,
            message: this.$t('setting.material.brandNameTip'),
        },
        withdrawalThreshold: {
            required: true,
            message: this.$t('setting.material.withdrawalThresholdTip'),
        },
        serviceMobile: {
            required: true,
            message: this.$t('setting.material.linkTip'),
        },
        logoPicture: {
            required: true,
            message: this.$t('setting.material.logoRequireTip'),
        },
        locationPictureList: {
            required: true,
            message: this.$t('setting.material.bannerRequireTip'),
        },
    }

    created() {
        this.getData();
    }

    async getData() {
        const { error, data } = await this.$request.get('/mapi/manager/material/query-agent-material');
        if (!error && data) {
            this.form = {
                ...data,
                logoPicture: [{ url: data.logoPicture }],
                locationPictureList: new Array(3).fill({}).map((item, index) => ({ url: data.locationPictureList[index] ? data.locationPictureList[index] : '' })),
            };
        }
    }

    async upload(option: any) {
        const fileParam = new FormData();
        fileParam.append('file', option.file);
        const res = await this.$request.post('/mapi/manager/upload/upload-poi-pic', fileParam, {
            headers: {
                'Content-type': 'multipart/form-data',
            },
        });
        return res;
    }

    async logoRequest(option: any) {
        const { data, success } = await this.upload(option);
        if (success && data) {
            this.form.logoPicture = [{
                url: data.fileUrl,
            }];
        } else {
            this.form.logoPicture = [];
        }
    }

    async bannerRequest(option: any) {
        const index = option.data;
        const { data, success } = await this.upload(option);
        if (success && data) {
            this.form.locationPictureList.splice(index, 1, {
                url: data.fileUrl,
            });
        } else {
            this.form.locationPictureList.splice(index, 1, {});
        }
    }

    removeFile() {
        this.form.logoPicture = [];
    }

    removeBannerFile(index: number) {
        this.form.locationPictureList.splice(index, 1);
    }

    clearFiles() {
        (this.$refs.upload as any).clearFiles();
    }

    async add() {
        (this.$refs.formRef as any).validate(async (valid: boolean) => {
            if (!valid) {
                return;
            }
            this.$mtd.confirm({
                title: this.$t('setting.material.tip') as string,
                message: this.$t('setting.material.saveTip') as string,
                okButtonText: this.$t('common.confirm') as string,
                onOk: async () => {
                    let url = '/mapi/manager/material/create-agent-material';
                    if (this.form.id) {
                        // 编辑
                        url = '/mapi/manager/material/update-agent-material';
                    }
                    const { error, data } = await this.$request.post(url, {
                        ...this.form,
                        logoPicture: this.form.logoPicture[0].url,
                        locationPictureList: this.form.locationPictureList.map((item: any) => item.url),
                    });
                    if (!error && data) {
                        this.$mtd.message.success(this.$t('setting.material.saveSuccess') as string);
                        this.getData();
                    }
                },
            });
        });
    }
}
