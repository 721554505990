





































































































































































import { Component, Vue } from 'vue-property-decorator';

import { State } from 'vuex-class';

import config from '@/common/config';
import agentSelect from '../common/agentSelect.vue';

@Component({ components: { agentSelect } })
export default class agentEdit extends Vue {
    @State((state) => state.common.user) user: any;

    form = {
        name: '',
        managerName: '',
        managerMobile: '',
        email: '',
        address: '',
        contractFileId: '',
        benefit: '',
        bankName: '',
        branchName: '',
        bankType: 1,
        bankNumber: '',
        bankAccount: '',
        parentId: '',
        contractUrl: '',
    };

    rules = {
        name: [
            { required: true, message: this.$t('agentCreate.pleaseEnterAgentName'), trigger: 'change' },
        ],
        managerName: [
            { required: true, message: this.$t('agentCreate.pleaseEnterPrincipalName'), trigger: 'change' },
        ],
        managerMobile: [
            {
                pattern: /^[+0-9]{6,20}$/, message: this.$t('agentCreate.pleaseEnterCorrectPhoneNumber'), required: config.isJpVersion, trigger: 'change',
            },
        ],
        email: [
            {
                type: 'email', required: true, message: this.$t('agentCreate.pleaseEnterCorrectEmailFormat'), trigger: 'change',
            },
        ],
        address: [
            { required: config.isJpVersion, message: this.$t('agentCreate.pleaseEnterDetailedAddress'), trigger: 'change' },
        ],
        contractFileId: [
            { required: config.isJpVersion, message: this.$t('agentCreate.pleaseEnterContractFile'), trigger: 'change' },
        ],
        benefit: [
            {
                pattern: /^(100|[1-9]?\d)$/, required: true, message: this.$t('agentList.changeTip'), trigger: 'change',
            },
        ],
        bankName: [
            { required: config.isJpVersion, message: this.$t('agentCreate.pleaseEnterBankName'), trigger: 'change' },
        ],
        branchName: [
            { required: config.isJpVersion, message: this.$t('agentCreate.pleaseEnterBranchName'), trigger: 'change' },
        ],
        bankType: [
            {
                required: config.isJpVersion, type: 'number', message: this.$t('agentCreate.pleaseSelectBankType'), trigger: 'change',
            },
        ],
        bankNumber: [
            { required: config.isJpVersion, message: this.$t('agentCreate.pleaseEnterBankAccount'), trigger: 'change' },
        ],
        bankAccount: [
            { required: config.isJpVersion, message: this.$t('agentCreate.pleaseEnterAccountName'), trigger: 'change' },
        ],
    };

    fileList: any = [];

    removeFile() {
        this.form.contractFileId = '';
    }

    created() {
        const { id } = this.$route.query;
        if (id) {
            this.getAgentInfo(Number(id));
        }
    }

    async getAgentInfo(id: number) {
        const { data, success } = await this.$request.get(`/mapi/manager/agent/query-agent-detail?agentId=${id}`);
        if (success && data) {
            this.form = data;
        }
    }

    submitForm(formName: string) {
        if (!this.form.contractFileId && config.isJpVersion) {
            this.$mtd.message.error(this.$t('agentCreate.pleaseFillInCompletely') as any);
            return;
        }
        // eslint-disable-next-line consistent-return
        (this.$refs[formName] as any).validate(async (valid: boolean) => {
            if (valid) {
                this.submitData();
            } else {
                this.$mtd.message.error(this.$t('agentCreate.pleaseFillInCompletely') as any);
                return false;
            }
        });
    }

    resetForm(formName: string) {
        (this.$refs[formName] as any).resetFields();
        this.$router.push('/agent/list');
    }

    clearFiles() {
        (this.$refs.upload as any).clearFiles();
    }

    downloadContract() {
        const { contractUrl } = this.form;
        window.open(contractUrl);
    }

    async httpRequest(option: any) {
        const fileParam = new FormData();
        fileParam.append('file', option.file);
        const { data, success } = await this.$request.post('/mapi/manager/upload/upload-agent-pic', fileParam, {
            headers: {
                'Content-type': 'multipart/form-data',
            },
        });
        if (success && data) {
            this.form.contractFileId = data.fileId;
        } else {
            this.form.contractFileId = '';
            this.fileList = [];
        }
    }

    async submitData() {
        const { success } = await this.$request.post('/mapi/manager/agent/update-agent', {
            ...this.form,
            parentId: this.user.agentId,
        });
        if (success === true) {
            this.$mtd.message.success(this.$t('agentEdit.saveTip') as string);
            setTimeout(() => {
                this.$router.push('/agent/list');
            }, 1000);
        }
    }
}
