<template>
    <!-- 人工还宝 弹窗 -->
    <div class="drawer-action">
        <mtd-form
            ref="form"
            :model="backForm"
            :rules="rules"
        >
            <mtd-form-item
                :label="$t('order.orderDetail.lendTime')"
            >
                <span>{{ lendTime }}</span>
            </mtd-form-item>
            <mtd-form-item
                :label="$t('order.orderDetail.usageDuration')"
                prop="returnTime"
            >
                <div class="consumptionTime">
                    <mtd-inputNumber
                        v-model="backForm.consumptionDays"
                        :controls="false"
                        :min="0"
                        :formatter="(value) => Number(value)"
                        style="width: 80px; margin-right: 8px"
                        @change="setConsumptionTime"
                    />
                    <span> {{ $t('order.orderDetail.day') }}</span>
                    <mtd-inputNumber
                        v-model="backForm.consumptionHours"
                        :controls="false"
                        :max="24"
                        :min="0"
                        :formatter="(value) => Number(value)"
                        style="width: 80px; margin: 0 8px"
                        @change="setConsumptionTime"
                    />
                    <span> {{ $t('order.orderDetail.hour') }}</span>

                    <mtd-inputNumber
                        v-model="backForm.consumptionMinutes"
                        :controls="false"
                        :max="59"
                        :min="0"
                        :formatter="(value) => Number(value)"
                        style="width: 80px; margin: 0 8px"
                        @change="setConsumptionTime"
                    />
                    <span> {{ $t('order.orderDetail.minute') }}</span>
                    <mtd-inputNumber
                        v-model="backForm.consumptionSeconds"
                        :controls="false"
                        :max="59"
                        :min="0"
                        :formatter="(value) => Number(value)"
                        style="width: 80px; margin: 0 8px"
                        @change="setConsumptionTime"
                    />
                    <span> {{ $t('order.orderDetail.second') }}</span>
                </div>
            </mtd-form-item>
            <mtd-form-item
                :label="$t('order.orderDetail.returnTime')"
            >
                <mtd-date-picker
                    v-model="backForm.returnTimeOnShow"
                    type="datetime"
                    :placeholder="$t('order.orderDetail.chooseTime')"
                    format="yyyy-MM-dd HH:mm:ss"
                    :options="returnTimePickerOptions"
                    @change="onChangeReturnTimeByPicker"
                />
            </mtd-form-item>
            <mtd-form-item
                :label="$t('order.orderDetail.returnCabin')"
                prop="returnCabinSn"
                required
            >
                <mtd-input
                    v-model="backForm.returnCabinSn"
                    clearable
                    style="width: 200px;"
                    @blur="onReturnCabinSnBlur"
                />
            </mtd-form-item>
            <mtd-form-item
                :label="$t('order.orderDetail.returnPoiName')"
            >
                <span>{{ backForm.returnPoiName }}</span>
            </mtd-form-item>
            <!-- <mtd-form-item
                label="操作原因"
                prop="refundReason"
            >
                <mtd-select
                    v-model="backForm.refundReason"
                    filterable
                    clearable
                >
                    <mtd-option
                        v-for="refundReason in backForm.selectableRefundReasons"
                        :key="refundReason"
                        :label="refundReason"
                        :value="refundReason"
                    />
                </mtd-select>
            </mtd-form-item> -->
            <mtd-form-item :label="$t('order.orderDetail.remark')">
                <mtd-textarea
                    v-model="backForm.remark"
                    style="width: 260px;"
                    rows="3"
                    maxlength="50"
                />
            </mtd-form-item>
        </mtd-form>
        <p class="text-left">
            <span>{{ $t('order.orderDetail.actualAmountReceived') }}：</span>
            <span>{{ fee ? `${priceUnit} ${fee}` : '-' }}</span>
        </p>
        <mtd-button
            type="primary"
            @click="openReturnConfirm"
        >
            {{ $t('order.orderDetail.return') }}
        </mtd-button>
        <mtd-button @click="handleClickCancel">
            {{ $t('order.orderDetail.cancel') }}
        </mtd-button>
    </div>
</template>

<script>
import dayjs from '@/common/dayjs';
import OrderServiceApi from '@/api/order/orderService';
import utils from '@/common/util';
import STATUS from '@/constants/status';
import { PRICE_UNIT, AREA_TYPE } from '@/constants/orderConstants';

export default {
    props: {
        userId: {
            type: String,
            default: '',
        },

        lendTime: {
            type: String,
            default: '',
        },

        lendPoiId: {
            type: [String, Number],
            default: '',
        },

        lendPoiName: {
            type: String,
            default: '',
        },

        summaryData: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            backForm: {
                consumptionDays: 0,
                consumptionHours: 0,
                consumptionMinutes: 0,
                consumptionSeconds: 0,
                returnTime: '',
                returnTimeOnShow: '--',
                returnCabinSn: '',
                returnPoiId: '',
                returnPoiName: '',
                selectableRefundReasons: [],
                refundReason: '',
                remark: '',
            },
            rules: {
                refundReason: { required: true, message: this.$t('order.orderDetail.mustInput') },
                returnPoiId: { required: true, message: this.$t('order.orderDetail.mustInput') },
                returnCabinSn: { required: true, message: this.$t('order.orderDetail.mustInput') },
            },
            returnPoiList: [],
            fee: '',
            returnTimePickerOptions: {},
        };
    },
    computed: {
        priceUnit() {
            return PRICE_UNIT[this.summaryData.areaType || AREA_TYPE.Japan];
        },
    },
    watch: {
        'backForm.returnTime': function (val) {
            if (!val) return;
            this.getReturnFeeInfo();
        },
        lendTime: {
            immediate: true,
            handler(val) {
                this.returnTimePickerOptions = {
                    disabledDate(date) {
                        return date.getTime() < dayjs(val).valueOf();
                    },
                };
            },
        },
    },
    created() {
        this.initReturnInfoByLendInfo();
        // this.getSugPoiList(this.lendPoiName);
        // this.getSelectableRefundReasons();
    },
    methods: {
        onReturnCabinSnBlur() {
            // this.getPoiInfoByCabinSn(this.backForm.returnCabinSn);
            this.getReturnFeeInfo();
        },

        // async getPoiInfoByCabinSn(cabinSn) {
        //     const {
        //         code, message, poiId, poiName,
        //     } = await OrderServiceApi.getPoiInfoByCabinSn(cabinSn) || {};

        //     if (code !== STATUS.SUCCESS) {
        //         this.$mtd.message({
        //             message,
        //             type: 'error',
        //         });
        //         return;
        //     }

        //     this.backForm.returnPoiId = poiId;
        //     this.backForm.returnPoiName = poiName;
        // },

        onChangeReturnTimeByPicker(value) {
            this.backForm.consumptionDays = dayjs(value).diff(this.lendTime, 'day');

            let operatedLendTime = dayjs(this.lendTime).add(this.backForm.consumptionDays, 'day');
            this.backForm.consumptionHours = dayjs(value).diff(operatedLendTime, 'hour');

            operatedLendTime = dayjs(operatedLendTime).add(this.backForm.consumptionHours, 'hour');
            this.backForm.consumptionMinutes = dayjs(value).diff(operatedLendTime, 'minute');

            operatedLendTime = dayjs(operatedLendTime).add(this.backForm.consumptionMinutes, 'minute');
            this.backForm.consumptionSeconds = dayjs(value).diff(operatedLendTime, 'second');

            this.backForm.returnTime = dayjs(value).valueOf();
        },

        initReturnInfoByLendInfo() {
            this.backForm.returnPoiId = this.lendPoiId;
            this.backForm.returnPoiName = this.lendPoiName;
            this.backForm.returnTimeOnShow = this.lendTime;
            this.backForm.returnTime = dayjs(this.lendTime).valueOf();
        },

        async getReturnFeeInfo() { // 获取应退金额已退金额信息
            try {
                const params = {
                    userId: this.userId,
                    orderId: this.$route.params.orderId,
                    bizReturnTime: this.backForm.returnTime,
                    returnCabinSn: this.backForm.returnCabinSn,
                    optType: 0, // =====
                };
                const res = await OrderServiceApi.getReturnFee(params);
                const { data, code } = res;
                if (code === STATUS.SUCCESS && data) {
                    this.fee = utils.formatPrice(data.fee); // 人工还宝实收金额
                    this.refundTimes = data.refundTimes; // 退款次数
                    this.originComputedRefundFee = data.computedRefundFee;// 原始计算出来的退款金额，单位分
                    this.computedRefundFee = utils.formatPrice(data.computedRefundFee); // 计算出来的退款金额
                    this.canRefundFee = utils.formatPrice(data.canRefundFee); // 当前可退款金额支付-退款
                    this.refundFee = utils.formatPrice(data.refundFee); // 当前退款总金额
                }
            } catch (e) {
                // this.$mtd.message({
                //     message: 'error',
                //     type: 'warning',
                // });
            }
        },

        setConsumptionTime() {
            const {
                consumptionDays, consumptionHours, consumptionMinutes, consumptionSeconds,
            } = this.backForm || {};

            const returnTime = dayjs(this.lendTime)
                .add(consumptionDays * 24, 'hour')
                .add(consumptionHours, 'hour')
                .add(consumptionMinutes, 'minute')
                .add(consumptionSeconds, 'second');

            this.backForm.returnTime = returnTime.valueOf();
            this.backForm.returnTimeOnShow = returnTime.format('YYYY-MM-DD HH:mm:ss');
        },

        // async getSugPoiList(query) {
        //     try {
        //         const res = await OrderServiceApi.getPoiListByKeyWord(query);
        //         const { data } = res;
        //         if (data && data.data && data.data.length) {
        //             const poiList = data.data.map((item) => ({
        //                 ...item,
        //                 label: item.poiName,
        //                 value: item.poiId,
        //             }));
        //             this.returnPoiList = poiList;
        //         }
        //     } catch (e) {
        //         this.returnPoiList = [];
        //     }
        // },

        // 选中或清空门店名称时，自动选择或删除对应的POID
        selectBackFormPoiName(value) {
            if (!value) return;
            const res = (this.returnPoiList || []).filter((item) => (item.poiId === value));
            if (!res || !res.length) return;
            this.backForm.returnPoiId = res[0].poiId;
            this.backForm.returnPoiName = res[0].poiName;
        },

        // 人工归还确认提示
        openReturnConfirm() {
            this.$refs.form.validate((valid) => {
                if (!valid) return;

                if (this.backForm.returnTime > Date.now()) {
                    this.$mtd.message({
                        message: this.$t('order.orderDetail.returnTimeRule'),
                        type: 'warning',
                    });
                    return;
                }

                this.$mtd.confirm({
                    title: this.$t('order.orderDetail.artificialReturnConfirm'),
                    // message: `确认对订单进行人工归还么，扣费${this.priceUnit}${this.fee}`,
                    message: this.$t('order.orderDetail.artificialReturnConfirmDes'),
                    width: '430px',
                    type: 'warning',
                    showCancelButton: true,
                    onOk: () => {
                        this.$emit('confirm-artificial-return', this.backForm);
                    },
                });
            });
        },

        // async getSelectableRefundReasons() {
        //     try {
        //         const { data, status, message } = await OrderServiceApi.getArtificialReturnReasons();

        //         if (status !== STATUS.SUCCESS || !data) throw new Error(message);

        //         this.backForm.selectableRefundReasons = data;
        //     } catch (e) {
        //         this.$mtd.message({
        //             message: e.message || '出现异常',
        //             type: 'error',
        //         });
        //     }
        // },

        handleClickCancel() {
            this.$emit('on-click-canel');
        },
    },
};
</script>

<style lang="less" scoped>
@import '../detail.less';
</style>
