export interface ActionType {
    [key: string]: string
}

export function generatorAsyncTypes(name: string): [string, string, string] {
    return [`${name}`, `${name}_SUCCESS`, `${name}_FAILURE`];
}

export function createVuexTypes(obj: ActionType, namespace = '') {
    let result: ActionType = {};
    const prefix = namespace === '' ? '' : `${namespace}/`;

    Object.entries(obj).forEach(([k, v]) => {
    // eslint-disable-next-line no-prototype-builtins
        if (result.hasOwnProperty(k)) {
            throw new Error('存在已定义的 ActionType！');
        }
        if (v === 'async') {
            const [origin, success, failure] = generatorAsyncTypes(k);
            result = {
                ...result,
                [origin]: origin,
                [success]: success,
                [failure]: failure,
            };
        } else {
            result[k] = `${k}`;
        }
    });

    result = Object.keys(result).reduce(
        (p, k) => ({
            ...p,
            [k]: `${prefix}${result[k]}`,
        }),
        {},
    );
    return result;
}

// 将包含parentId的扁平数组，转化为包含children的深层数组
export function flat2deep(flatArray = [], parentId = 0): any {
    if (flatArray.length === 0) {
        return flatArray;
    }
    const swapArray = flatArray.filter((item: any) => item.parentId !== parentId);
    return flatArray
        .filter((item: any) => item.parentId === parentId)
        .map((item: any) => {
            const children = flat2deep(swapArray, item.id);
            if (children.length > 0) {
                return {
                    ...item,
                    children,
                };
            }
            return item;
        });
}
