<template>
    <div
        id="order-detail-module"
        class="order-detail-module"
    >
        <div>
            <mtd-loading :loading="detailLoading">
                <div class="order-summary">
                    <!-- 订单详情 头部 -->
                    <order-summary
                        :summary-data="orderDetaiObj.orderHeader"
                        :lend-data="orderDetaiObj.orderLendInfo"
                        :activity-type="orderDetaiObj.orderLendInfo.activityType"
                        :activity-status="orderDetaiObj.orderLendInfo.activityStatus"
                        :flow-data="orderDetaiObj.orderProgress"
                        :pay-info="orderDetaiObj.orderPayInfo"
                    >
                        <!-- <mtd-button
                            v-if="orderDetaiObj.orderHeader.malfunction"
                            @click="isShowFaultDetail = true;"
                        >
                            故障详情
                        </mtd-button> -->
                        <!-- <mtd-button
                            v-if="hasOperateRight"
                            :disabled="disabledChangePrice"
                            @click="changePriceVisible = true;"
                        >
                            改价
                        </mtd-button> -->
                        <!-- 关单 按钮 -->
                        <mtd-button
                            v-if="uacMenusMap.ORDER_CLOSE"
                            :disabled="disabledCloseOrder"
                            type="danger"
                            @click="isShowCloseOrderModel = true;"
                        >
                            {{ $t('order.orderDetail.closeOrder') }}
                        </mtd-button>
                        <!-- 暂停计费 按钮 -->
                        <mtd-button
                            v-if="uacMenusMap.ORDER_SUSPEND"
                            :disabled="disabledReturn || isLockingOrder"
                            @click="isShowStopCharge = true;"
                        >
                            {{ orderDetaiObj.orderHeader.suspendTitle || $t('order.orderDetail.stopCharge') }}
                        </mtd-button>
                        <!-- 发劵 -->
                        <!-- <mtd-button
                            v-if="hasOperateRight && couponStatus"
                            :disabled="disabledCoupon"
                            @click="couponClickHandler"
                        >
                            {{ couponStatus }}
                        </mtd-button> -->
                        <!-- 退款 按钮 -->
                        <mtd-button
                            v-if="uacMenusMap.ORDER_REFUND"
                            :disabled="disabledRefund"
                            @click="refundVisible = true"
                        >
                            {{ $t('order.orderDetail.refund') }}
                        </mtd-button>
                        <!-- 人工还宝 按钮 -->
                        <mtd-button
                            v-if="uacMenusMap.ORDER_MANRETURN"
                            :disabled="disabledReturn"
                            type="primary"
                            @click="backVisible = true;"
                        >
                            {{ $t('order.orderDetail.artificialReturn') }}
                        </mtd-button>
                        <!-- <div class="refund-tips">
                            {{ !orderDetaiObj.orderHeader.merchantOrderCanRefund ? '无退款权限' : '' }}
                        </div> -->
                    </order-summary>
                </div>
                <div
                    id="demo-base-anchor-containter"
                    class="order-sub-module"
                >
                    <!-- <div
                        id="base-anchor-detail"
                        class="user-feature"
                    >
                        <user-feature :feature-data="orderDetaiObj.userLabelDataDTOs" />
                    </div> -->
                    <div
                        id="base-anchor-detail"
                        class="order-flow"
                    >
                        <order-flow
                            :flow-data="orderDetaiObj.orderProgress"
                            :header-status="headerStatus"
                        />
                    </div>
                    <div class="lend-info">
                        <lend-info
                            :lend-data="orderDetaiObj.orderLendInfo"
                        />
                    </div>
                    <div
                        id="base-anchor-pay-order"
                        class="pay-info"
                    >
                        <pay-info
                            :pay-data="orderDetaiObj.orderPayInfo"
                            :summary-data="orderDetaiObj.orderHeader"
                            :activity-type="orderDetaiObj.orderLendInfo.activityType"
                            :activity-status="orderDetaiObj.orderLendInfo.activityStatus"
                        />
                    </div>
                    <!-- <div
                        id="base-anchor-customer-service-order"
                        class="customer-service-order"
                    >
                        <customer-service-order />
                    </div> -->
                    <!-- <div
                        id="base-anchor-customer-feedback-order"
                        class="customer-service-order"
                    >
                        <customer-feedback-order />
                    </div> -->
                    <!-- <div class="property-info">
                        <property />
                    </div> -->
                    <!-- <div
                        id="base-anchor-work-order"
                        class="order-record"
                    >
                        <order-record :user-id="orderDetaiObj.orderHeader.userId" />
                    </div> -->
                    <div
                        id="base-anchor-log"
                        class="operation-log"
                    >
                        <operation-log
                            :user-log-list="orderDetaiObj.orderUserOptLogList"
                            :manual-log-list="orderDetaiObj.orderODOptLogList"
                        />
                    </div>
                </div>
                <mtd-drawer
                    v-model="backVisible"
                    :get-popup-container="getContainer"
                    class="return-drawer-form"
                    destroy-on-close
                    width="700px"
                >
                    <!-- 人工还宝 弹窗 -->
                    <ArtificialReturnDrawer
                        ref="artificialReturnDrawer"
                        :summary-data="orderDetaiObj.orderHeader"
                        :user-id="orderDetaiObj.orderHeader.userId"
                        :lend-time="lendTime"
                        :lend-poi-id="lendPoiId"
                        :lend-poi-name="lendPoiName"
                        :return-sn="orderDetaiObj.orderLendInfo.returnCabinSn"
                        @confirm-artificial-return="confirmArtificialReturn"
                        @on-click-canel="backVisible = false"
                    />
                </mtd-drawer>
                <mtd-drawer
                    v-model="sendCouponVisible"
                    :get-popup-container="getContainer"
                    class="refund-drawer-form"
                    destroy-on-close
                    width="700px"
                >
                    <SendCouponDrawer
                        ref="sendCouponDrawer"
                        :user-id="orderDetaiObj.orderHeader.userId"
                        :lend-time="lendTime"
                        :pay-info="orderDetaiObj.orderPayInfo"
                        :summary-data="orderDetaiObj.orderHeader"
                        @on-click-canel="sendCouponVisible = false"
                    />
                </mtd-drawer>

                <mtd-drawer
                    v-model="withdrawCouponVisible"
                    :get-popup-container="getContainer"
                    class="refund-drawer-form"
                    destroy-on-close
                    width="700px"
                >
                    <WithdrawCouponDrawer
                        ref="withdrawCouponDrawer"
                        :user-id="orderDetaiObj.orderHeader.userId"
                        :coupon-info="orderDetaiObj.orderHeader.orderCouponInfo"
                        @on-click-canel="withdrawCouponVisible = false"
                    />
                </mtd-drawer>
                <mtd-drawer
                    v-model="refundVisible"
                    :get-popup-container="getContainer"
                    class="refund-drawer-form"
                    destroy-on-close
                    width="700px"
                >
                    <refundDrawer
                        ref="refundDrawer"
                        :user-id="orderDetaiObj.orderHeader.userId"
                        :lend-time="lendTime"
                        :pay-info="orderDetaiObj.orderPayInfo"
                        :summary-data="orderDetaiObj.orderHeader"
                        :order-lend-info="orderDetaiObj.orderLendInfo"
                        @confirm-del-suspend="confirmDelSuspend"
                        @confirm-rebund="confirmRebund"
                        @on-click-canel="refundVisible = false"
                    />
                </mtd-drawer>
                <!-- <mtd-drawer
                    v-model="changePriceVisible"
                    :get-popup-container="getContainer"
                    class="refund-drawer-form"
                    destroy-on-close
                    width="700px"
                >
                    <ChangePriceDrawer
                        ref="changePriceDrawer"
                        :user-id="orderDetaiObj.orderHeader.userId"
                        :lend-time="lendTime"
                        :pay-info="orderDetaiObj.orderPayInfo"
                        :lend-info="orderDetaiObj.orderLendInfo"
                        :summary-data="orderDetaiObj.orderHeader"
                        @confirm-del-suspend="confirmDelSuspend"
                        @on-click-canel="changePriceVisible = false"
                    />
                </mtd-drawer> -->
                <mtd-drawer
                    v-model="isShowStopCharge"
                    :get-popup-container="getContainer"
                    class="charge-drawer-form"
                    width="700px"
                    destroy-on-close
                >
                    <!-- 暂停计费 弹窗 -->
                    <stop-charge
                        :user-info="userInfo"
                        :lend-time="lendTime"
                        @closeModel="closeStopCharge"
                    />
                </mtd-drawer>
                <mtd-drawer
                    v-model="isShowCloseOrderModel"
                    :get-popup-container="getContainer"
                    class="closeorder-drawer-form"
                    width="500px"
                    destroy-on-close
                >
                    <!-- 关单 弹窗 -->
                    <close-order
                        :user-info="userInfo"
                        @closeModel="closeOrederModel"
                    />
                </mtd-drawer>
                <!-- <mtd-drawer
                    v-model="isShowFaultDetail"
                    :get-popup-container="getContainer"
                    class="fault-drawer-form"
                    width="500px"
                    destroy-on-close
                >
                  // 故障 弹窗
                    <fault-detail
                        :fault-data="orderDetaiObj.feedBackDetailDTO"
                    />
                </mtd-drawer> -->
            </mtd-loading>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import dayjs from '@/common/dayjs';
import STATUS from '@/constants/status';
import OrderServiceApi from '@/api/order/orderService';
import {
    DISABLED_REFUNDE_LIST,
    DISABLED_RETURN_LIST,
    DISABLED_CLOSE_ORDER_LIST,
    // DISABLED_CHANGE_PRICE,
    DISABLED_COUPON_LIST,
    COUPON_STATUS,
} from '@/constants/orderConstants';
import OrderSummary from './components/OrderSummary';
import OrderFlow from './components/OrderFlow';
import LendInfo from './components/LendInfo';
// import UserFeature from './components/UserFeature';
import PayInfo from './components/PayInfo';
import OperationLog from './components/OperationLog';
// import OrderRecord from './components/OrderRecord';
import StopCharge from './components/StopCharge';
// import FaultDetail from './components/FaultDetail';
import CloseOrder from './components/CloseOrder';
// import Property from './components/Property';
import RefundDrawer from './components/RefundDrawer';
import ArtificialReturnDrawer from './components/ArtificialReturnDrawer';
// import ChangePriceDrawer from './components/ChangePriceDrawer';
// import SendCouponDrawer from './components/SendCouponDrawer';
// import WithdrawCouponDrawer from './components/WithdrawCouponDrawer';

export default {
    components: {
        OrderSummary,
        OrderFlow,
        LendInfo,
        PayInfo,
        OperationLog,
        // OrderRecord,
        StopCharge,
        // FaultDetail,
        CloseOrder,
        // UserFeature,
        // Property,
        RefundDrawer,
        ArtificialReturnDrawer,
        // SendCouponDrawer,
        // WithdrawCouponDrawer,
    },
    data() {
        return {
            detailLoading: false,
            activeAnchor: '#base-anchor-detail',
            orderDetaiObj: {
                orderHeader: {},
                orderProgress: {},
                orderLendInfo: {},
                feedBackDetailDTO: {},
            },
            backVisible: false,
            refundVisible: false,
            lendTime: '',
            lendPoiId: '',
            lendPoiName: '',
            hasOperateRight: true,
            pickerOptions: {
                disabledDate(date) {
                    return date && date.getTime() > Date.now();
                },
            },
            isShowStopCharge: false,
            isShowCloseOrderModel: false,
            // isShowFaultDetail: false,
            // changePriceVisible: false,
            headerStatus: 0,
            sendCouponVisible: false,
            withdrawCouponVisible: false,
        };
    },
    computed: {
        ...mapState({
            userInfo: (state) => state.common.user,
            uacMenusMap: (state) => state.common.uacMenusMap,
        }),
        // disabledChangePrice() {
        //     const { status } = this.orderDetaiObj.orderHeader;
        //     return !DISABLED_CHANGE_PRICE.includes(status);
        // },
        // 是否可以 退款
        disabledRefund() {
            const { status, cantRefund } = this.orderDetaiObj.orderHeader;
            return !DISABLED_REFUNDE_LIST.includes(status) || cantRefund;
        },

        // 是否可以 人工还宝 暂停计费
        disabledReturn() {
            // return false;
            const { status } = this.orderDetaiObj.orderHeader;
            return !DISABLED_RETURN_LIST.includes(status);
        },
        // 是否可以 关单
        disabledCloseOrder() {
            // return false;
            const { status } = this.orderDetaiObj.orderHeader;
            return !DISABLED_CLOSE_ORDER_LIST.includes(status);
        },
        disabledCoupon() {
            return DISABLED_COUPON_LIST.includes(this.orderDetaiObj.orderHeader?.orderCouponInfo?.status);
        },
        // 是否是00锁止订单
        isLockingOrder() {
            return this.orderDetaiObj.orderHeader.isUnlockReturnOrder;
        },
        couponStatus() {
            return COUPON_STATUS[this.orderDetaiObj.orderHeader?.orderCouponInfo?.status];
        },
    },
    watch: {
        activeAnchor(val) {
            const anchorDom = document.getElementById(val);
            anchorDom.click();
        },
    },
    created() {
        this.getOrderDetail();
    },
    methods: {
        couponClickHandler() {
            const { status } = this.orderDetaiObj.orderHeader.orderCouponInfo;
            if (status === 1) {
                this.sendCouponVisible = true;
            }
            if (status === 2) {
                this.withdrawCouponVisible = true;
            }
        },
        closeStopCharge(item) {
            this.isShowStopCharge = item.isShowModel;
        },
        closeOrederModel(item) {
            this.isShowCloseOrderModel = item.isShowModel;
            this.detailLoading = item.detailLoading;
        },

        getContainer() {
            return document.getElementById('order-detail-module');
        },

        async getOrderDetail() {
            try {
                this.detailLoading = true;
                const queryParams = {
                    orderId: this.$route.params.orderId,
                    userId: this.$route.params.userId,
                };
                const { code, data } = await OrderServiceApi.fetchOrderDetail(queryParams);
                if (code === STATUS.SUCCESS && data) {
                    this.orderDetaiObj = data || {};
                    this.lendPoiId = (data.orderLendInfo && data.orderLendInfo.lendPoiId) || '';
                    this.lendPoiName = (data.orderLendInfo && data.orderLendInfo.lendPoiName) || '';
                    this.headerStatus = this.orderDetaiObj.orderHeader.status;
                    this.lendTime = dayjs(data.orderLendInfo.lendTime).format('YYYY-MM-DD HH:mm:ss');
                }
            } catch (e) {
                // this.$mtd.message({
                //     message: 'error',
                //     type: 'warning',
                // });
            } finally {
                this.detailLoading = false;
            }
        },
        // 人工还宝 发起归还
        async confirmArtificialReturn(backForm) {
            const param = {
                ...backForm,
                ...this.userInfo,
                source: this.orderDetaiObj.orderHeader.source,
                payMode: this.orderDetaiObj.orderHeader.payMode,
                batteryDevId: this.orderDetaiObj.orderLendInfo.batterySn,
                userId: this.orderDetaiObj.orderHeader.userId,
                orderId: this.$route.params.orderId,
                lendTime: this.orderDetaiObj.orderLendInfo.lendTime,
            };
            // console.log('调接口-人工还宝', param);
            // return;
            const { code } = await OrderServiceApi.manualReturn(param);
            if (code === STATUS.SUCCESS) {
                this.backVisible = false;
                this.detailLoading = true;
                setTimeout(() => {
                    window.location.reload();// reload
                }, 5000);
            }
        },
        // 发起退款
        async confirmRebund(refundForm) {
            const param = {
                ...refundForm,
                ...this.userInfo,
                source: this.orderDetaiObj.orderHeader.source,
                payMode: this.orderDetaiObj.orderHeader.payMode,
                batteryDevId: this.orderDetaiObj.orderLendInfo.batterySn,
                refundFee: refundForm.refundFee * 100, //国内金额单位为’分‘，输入为元，这里要乘以100
                userId: this.orderDetaiObj.orderHeader.userId,
                orderId: this.$route.params.orderId,
                lendTime: this.orderDetaiObj.orderLendInfo.lendTime,
                payTime: this.orderDetaiObj.orderHeader.payTime,
                operatorId: '1', // 后端问题 必传字段
            };
            // console.log('调接口-发起退款:params', param);
            // return;
            const { code } = await OrderServiceApi.refundFee(param);
            if (code === STATUS.SUCCESS) {
                this.refundVisible = false;
                this.detailLoading = true;
                setTimeout(() => {
                    window.location.reload();// reload
                }, 5000);
            }
        },

        async confirmDelSuspend() {
            // 删除暂停计费接口
            const param = {
                ...this.userInfo,
                source: this.orderDetaiObj.orderHeader.source,
                userId: this.orderDetaiObj.orderHeader.userId,
                orderId: this.$route.params.orderId,
                reason: '',
                remark: '',
            };
            const { code, data } = await OrderServiceApi.delSuspend(param);
            // console.log(code, message, data);
            if (code === STATUS.SUCCESS && data) {
                this.getOrderDetail();
            }
        },
    },
};
</script>

<style lang="less" scoped>
  .order-detail-module {
  .order-summary {
    padding: 20px;

    .anchor-action {
      &>a {
        margin-left: 10px;
      }
    }
  }

  .order-sub-module {
    height: calc(100vh - 350px);
    overflow: auto;

    &>div {
      margin: 20px;
      background: #f8f9fc;
      padding: 20px;
    }
  }

  .return-drawer-form,
  .refund-drawer-form,
  .charge-drawer-form,
  .closeorder-drawer-form {
    .mtd-drawer-close {
      left: 16px !important;
      right: auto;
    }

    .text-left {
      text-align: left;
    }

    .drawer-action {
      text-align: right;

      &>button {
        min-width: 80px;
      }

      button+button {
        margin-left: 12px;
      }
      .title {
        font-weight: 500;
        font-size: 16px;
        text-align: center;
      }
    }

    .mtd-form {
      margin-top: 20px;
    }
  }
}

  .consumptionTime {
    display: flex;
    align-items: center;
  }
  .refund-tips{
    margin-right: 82px;
    color: red;
  }
</style>
