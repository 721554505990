import { uploadFile } from './s3upload';

import stateStore from '../store';
import { indexTypes as types } from '../store/action-types';

export const debounce = (fn: any, delay: number, ...args: any) => {
    let timer = 0;
    return () => {
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(() => {
            fn.apply(fn, ...args);
        }, delay);
    };
};

export const changeSideListVisible = (visible: boolean) => {
    stateStore.commit(types.SET_SIDE_VISIBLE, visible);
};

export const getUrlParam = (key: string) => {
    const reg = new RegExp(`(\\?|&)${key}=([^&]*)(&|$)`, 'i');
    const result = window.location.href.match(reg);
    if (result !== null) {
        return decodeURIComponent(result[2]);
    }
    return null;
};

export const getDateString = (date = new Date()) => {
    const today = date;
    const year = today.getFullYear();
    let month: any = today.getMonth() + 1; // getMonth()是从0开始计数的，所以需要加1
    let day: any = today.getDate();

    // 如果月份和日期小于10，前面补0，使得格式始终为2位
    if (month < 10) {
        month = `0${month}`;
    }
    if (day < 10) {
        day = `0${day}`;
    }

    // 拼接成年月日格式的字符串
    return `${year}${month}${day}`;
};

export default {
    uploadFile,
    debounce,
    changeSideListVisible,
    getUrlParam,
    getDateString,
};
