










































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { Editor, Toolbar } from '@wangeditor/editor-for-vue';
import { i18nChangeLanguage } from '@wangeditor/editor';
import '@wangeditor/editor/dist/css/style.css';

import config from '@/common/config';

import { State } from 'vuex-class';

import i18n from '@/common/i18n';
import { request } from '../../../common/request';

@Component({
    components: {
        Editor,
        Toolbar,
    },
})
export default class agentList extends Vue {
    @State((state) => state.common.agentConfig) agentConfig: any;

    form: any = {
        language: '',
        title: '',
    };

    editor: any = null;

    mode: string = 'default';

    toolbarConfig: any = {
        excludeKeys: ['group-video'],
    };

    editorConfig: any = {
        placeholder: this.$t('setting.faq.placeholder'),
        MENU_CONF: {
            uploadImage: {
                async customUpload(file: File, insertFn: any) {
                    const fileParam = new FormData();
                    fileParam.append('file', file);
                    const { data } = await request.post(`${config.faqBaseURL}/mapi/manager/upload/upload-poi-pic`, fileParam, {
                        headers: {
                            'Content-type': 'multipart/form-data',
                        },
                    });
                    insertFn(data.fileUrl, i18n.t('setting.faq.picture'), '');
                },
            },
        },
    };

    addForm: any = {
        language: '',
        title: '',
        content: '',
    }

    modalTitle: string = this.$t('setting.faq.addQuestion') as string;

    lang: string = window.navigator.language;

    tableData: any = [];

    total: number = 0;

    detailContent: string = '';

    pagination: any = {
        pageNum: 1,
        pageSize: 10,
    }

    addRules: any = {
        language: {
            required: true,
            message: this.$t('setting.faq.required'),
        },
        title: {
            required: true,
            message: this.$t('setting.faq.required'),
        },
        content: {
            required: true,
            message: this.$t('setting.faq.required'),
        },
    };

    addModal: boolean = false;

    detailModal: boolean = false;

    get langList() {
        return (this.agentConfig.clanguageList || []).map((item: any) => ({
            value: item.code,
            label: item.name,
        }));
    }

    async created() {
        this.getList();
        i18nChangeLanguage(this.$i18n.locale);
    }

    search() {
        this.getList();
    }

    reset() {
        this.form = {
            language: '',
            title: '',
        };
        this.getList();
    }

    add() {
        this.addModal = true;
    }

    onCreated(editor: any) {
        this.editor = Object.seal(editor);
    }

    async getList() {
        const { code, data } = await this.$request.post(`${config.faqBaseURL}/api-c/manager/question-info/query-list`, {
            ...this.form,
            ...this.pagination,
        });
        if (code === 0) {
            this.tableData = data.data;
            this.total = data.totalCount;
        }
    }

    change() {
        this.getList();
    }

    showDetail(row: any) {
        this.detailModal = true;
        this.detailContent = row.content;
    }

    edit(row: any) {
        this.addModal = true;
        this.modalTitle = this.$t('setting.faq.editQuestion') as string;
        this.addForm = {
            ...row,
        };
    }

    addItem() {
        (this.$refs.addForm as any).validate(async (valid: boolean) => {
            if (valid) {
                let url = '/api-c/manager/question-info/create';
                if (this.addForm.id) {
                    url = '/api-c/manager/question-info/update-info';
                }
                // 提交
                const { code } = await this.$request.post(`${config.faqBaseURL}${url}`, {
                    ...this.addForm,
                });
                if (code === 0) {
                    this.addModal = false;
                    this.$mtd.message.success(this.$t('setting.faq.save') as string);
                    this.getList();
                    this.addForm = {
                        language: '',
                        title: '',
                        content: '',
                    };
                }
            }
        });
    }

    deleteItem(row: any) {
        this.$mtd.confirm({
            title: this.$t('setting.faq.warning') as string,
            message: this.$t('setting.faq.deleteContent') as string,
            okButtonText: this.$t('setting.faq.confirm') as string,
            onOk: this.handelDelete.bind(this, row),
        });
    }

    async handelDelete(row: any) {
        const { code } = await this.$request.post(`${config.faqBaseURL}/api-c/manager/question-info/delete`, {
            id: row.id,
        });
        if (code === 0) {
            this.$mtd.message.success(this.$t('setting.faq.deleteTip') as string);
            this.getList();
        }
    }

    async changeStatus(row: any) {
        this.$mtd.confirm({
            title: this.$t('setting.faq.warning') as string,
            message: `${row.status ? this.$t('setting.faq.enableTip') : this.$t('setting.faq.disableTip')}`,
            okButtonText: this.$t('setting.faq.confirm') as string,
            onOk: this.handelStatus.bind(this, row),
            onCancel: () => {
                row.status = !row.status;
            },
        });
    }

    async handelStatus(row: any) {
        const { code } = await this.$request.post(`${config.faqBaseURL}/api-c/manager/question-info/update-status`, {
            id: row.id,
            status: row.status,
        });
        if (code === 0) {
            this.$mtd.message.success(this.$t('setting.faq.switchtip') as string);
            this.getList();
        }
    }

    beforeDestroy() {
        const editor = this.editor;
        if (editor === null) return;
        editor.destroy();
    }
}
