import { createVuexTypes } from './vuex-utils';

export const indexTypes = createVuexTypes({
    GET_USER_INFO: 'async',
    GET_UAC_MENUS: 'async',
    SET_SIDE_VISIBLE: 'async',
    SET_AGENT_CONFIG: 'async',
}, 'INDEX');

export default {
    indexTypes,
};
