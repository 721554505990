const STATUS = {
    SUCCESS: 0, // 请求成功
    API_FAILED: 1001, // 请求失败
    PARAMETER_ERROR: 2001, // 参数错误
    SERVER_ERROR: 2002, // 服务繁忙

    CABIN_LENGTH_TOO_LONG: 2003, // 机柜条数超限
    MERCHANT_LENGTH_TOO_LONG: 2003, // 上传门店条数超限
    PARAM_ERROR: 5001, // 参数错误
    RULE_NAME_DUPLICATE: 5002, // 规则名称重复
    FREE_TIME_ERROR: 5003, // 免费时长参数有误
    RULE_NAME_OVER_LENGTH: 5004, // 规则名称超长, 最大支持 30 个字
    PRICE_PERIOD_ERROR: 5005, // 计价周期错误
    UNIT_PRICE_RANGE_ERROR: 5006, // 单价错误
    DAY_CAPPED_PRICE_ERROR: 5007, // 24h 消费金额上限错误
    CAPPED_PRICE_ERROR: 5008, // 消费金额上限错误
    SALE_OPTIONAL_ERROR: 5009, // 上单可选错误
    RULE_CATEGORY_ERROR: 5010, // 类别计价规则所属类别错误
    BIND_DATA_SIZE_ERROR: 5011, // 绑定数据长度错误
    CATEGORY_PRICE_RULE_NOT_EXIST: 5012, // 计价规则不存在错误
    CATEGORY_ID_IS_NULL: 5013, // 类别 id 为空
    RULE_ID_IS_NULL: 5014, // 计价规则 id 为空
    RULE_SOURCE_IS_NULL: 5015, // 计价规则来源为空
    PRICE_RULE_DELETE_FAILURE: 5016, // 删除失败
    PRICE_RULE_NOT_FOUND: 5017, // 计价规则不存在
    DEFAULT_RULE_ACCESS: 5018, // 默认规则不允许删除
    EXIST_BIND_CABIN: 5019, // 请先删除计价规则下的全部机柜，再删除规则
    CAPPED_PRICE_DAY_ERROR: 5019, // 消费金额上限错误
    LOGIN_ERROR: 401, // 未登录
    AUTH_ERROR: 403, // 没有请求权限
    ADVERTISEID_ERROR: 1002,
};

export default STATUS;
