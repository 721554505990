





































import { Component, Vue, Prop } from 'vue-property-decorator';
import MenuItemSelf from './MenuItemSelf.vue';
import { debounce, getUrlParam } from '../common/utils';

@Component({
    components: {
        MenuItemSelf,
    },
})
export default class AppMenu extends Vue {
    @Prop({
        type: Array,
        default: () => [],
    })
    sideList: any[];

    @Prop({
        type: Object,
        default: () => ({}),
    })
    menuPathMap: any;

    @Prop({
        type: Array,
        default: () => [],
    })
    defaultExpandedNames: string[];

    isCollapse = false;

    sideHeight = 800

    get activeName() {
        const { menuPathMap } = this;
        const _menuItem = menuPathMap[this.$route.fullPath];
        let activeCode = _menuItem && _menuItem.nodeCode;
        if (!activeCode) {
            const paramMenuPath = getUrlParam('menuPath');
            if (this.$route.query.menuPath || paramMenuPath) {
                const menuPath = this.$route.query.menuPath || paramMenuPath;
                const { path } = this.$route;
                const _item = menuPathMap[`${path}/#/${menuPath}`] || menuPathMap[`${path}#/${menuPath}`];
                activeCode = _item && _item.nodeCode;
            }
        }
        return activeCode;
    }

    set activeName(nodeCode: string) {
        if (this.defaultExpandedNames.includes(nodeCode) && !this.$route.query.menuPath) return;
        this.$emit('changeMenu', nodeCode);
    }

    mounted() {
        this.setSideHeight();
        window.onresize = debounce(this.setSideHeight, 200);
    }

    onCollapse() {
        this.isCollapse = !this.isCollapse;
        this.$emit('onCollapse', this.isCollapse);
    }

    setSideHeight() {
        const clientHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        this.sideHeight = clientHeight - 48;
    }

    getNodeCode() {
        return this.activeName;
    }
}
