



























































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { State } from 'vuex-class';

import userItem from './user-item.vue';

import agentSelect from '../common/agentSelect.vue';

@Component({
    components: {
        userItem,
        agentSelect,
    },
})
export default class userList extends Vue {
    @State((state) => state.common.user) user: any;

    @State((state) => state.common.uacMenusMap) uacMenusMap: any;

    filter = {
        agentId: null,
        account: null,
        name: null,
        email: null,
        roleId: null,
    };

    tableData = [];

    pagination = {
        pageNum: 1,
        pageSize: 10,
    }

    roleList: any = [];

    total: number = 0;

    userVisable: boolean = false;

    passwordVisable: boolean = false;

    modalTitle = this.$t('agentUser.addUser');

    rowData: any = {};

    resetForm = {
        empId: '',
        password: '',
        account: '',
    }

    get agentList() {
        if (this.user && this.user.agentId) {
            this.filter.agentId = this.user.agentId;
            // 获取role
            this.getRoleList(this.user.agentId);
            return [
                {
                    id: this.user.agentId,
                    name: this.user.agentName,
                },
            ];
        }
        return [];
    }

    get isAgent() {
        return this.user.identityCode === 4 || this.user.identityCode === 5;
    }

    @Watch('filter.agentId')
    onAgentChange() {
        this.getRoleList(this.filter.agentId as any);
    }

    mounted() {
        this.search();
    }

    handlePageChange() {
        this.search();
    }

    async search() {
        this.pagination = {
            pageNum: 1,
            pageSize: 10,
        };
        Object.keys(this.filter).forEach((key) => {
            if (this.filter[key] === '') {
                this.filter[key] = null;
            }
        });
        const { data } = await this.$request.get('/mapi/manager/user/list-emp', {
            params: {
                ...this.filter,
                ...this.pagination,
            },
        });
        if (data && data.data) {
            this.tableData = data.data;
            this.total = data.total;
        } else {
            this.tableData = [];
            this.total = 0;
        }
    }

    async getRoleList(id: number) {
        const { success, data } = await this.$request.get('/mapi/manager/role/list-role', {
            params: {
                agentId: id,
            },
        });
        if (success && data.data) {
            this.roleList = data.data;
        }
    }

    reset() {
        (this.$refs.form as any).resetFields();
        this.filter = {
            agentId: this.user.agentId,
            account: null,
            name: null,
            email: null,
            roleId: null,
        };
        this.search();
    }

    add() {
        this.modalTitle = this.$t('agentUser.addUser');
        this.userVisable = true;
        this.rowData = {};
    }

    hiddenModal(refresh: boolean) {
        this.userVisable = false;
        if (refresh) {
            this.search();
        }
    }

    async edit(row: any) {
        this.modalTitle = this.$t('agentUser.editUserInfo');
        this.userVisable = true;
        const { id } = row;
        const { success, data } = await this.$request.get('/mapi/manager/user/query-emp-detail', {
            params: {
                empId: id,
            },
        });
        if (success && data) {
            this.rowData = data;
        }
    }

    resetPassword(row: any) {
    // reset password logic
        this.resetForm.empId = row.id;
        this.resetForm.account = row.account;
        this.passwordVisable = true;
    }

    async changePassword() {
        const { empId, password } = this.resetForm;
        const { success, data } = await this.$request.post('/mapi/manager/user/reset-agent-emp-pwd', {
            empId,
            password,
        });
        if (success && data) {
            this.passwordVisable = false;
            this.$mtd.message.success(this.$t('agentUser.resetSuccess') as any);
            this.resetForm.password = '';
        }
    }
}
