<template>
    <!-- 退款 弹窗 -->
    <div>
        <div class="drawer-action">
            <header class="title">
                {{ $t('order.orderDetail.refund') }}
            </header>
            <mtd-form
                ref="refundForm"
                :model="refundForm"
                :rules="rules"
            >
                <!-- 弹出时间 -->
                <mtd-form-item
                    :label="$t('order.orderDetail.lendTime')"
                >
                    <span>{{ lendTime }}</span>
                </mtd-form-item>
                <!-- 暂停计费 -->
                <mtd-form-item
                    v-if="suspendInfo.canDeleteSuspend"
                    :label="$t('order.orderDetail.stopCharge')"
                >
                    <span>{{ suspendStartTimeShow }}</span>
                    <span> ~ </span>
                    <span>{{ suspendEndTimeShow }}</span>
                    <!-- <span
                        class="del-suspend"
                        @click="ensureDelSuspend"
                    > {{$t('order.orderDetail.deleteStopCharge')}} </span> -->
                </mtd-form-item>
                <!-- 归还时间 -->
                <!-- <mtd-form-item
                    label="归还时间"
                >
                    <span>{{ refundForm.returnTimeOnShow }}</span>
                </mtd-form-item> -->
                <!-- 原支付金额 -->
                <mtd-form-item
                    :label="$t('order.orderDetail.originalFee')"
                >
                    <span>{{ originalFee }}</span>
                </mtd-form-item>
                <!-- 可退金额 -->
                <mtd-form-item
                    :label="$t('order.orderDetail.canRefundFee')"
                >
                    <span>{{ canRefundFee ? `${priceUnit} ${canRefundFee}` : '-' }}</span>
                </mtd-form-item>
                <!-- 退款金额 -->
                <mtd-form-item
                    :label="$t('order.orderDetail.refundFee')"
                    prop="refundFee"
                >
                    <mtd-input
                        v-model="refundForm.refundFee"
                        type="number"
                    />
                </mtd-form-item>
                <!-- <mtd-form-item
                    label="操作原因"
                    prop="refundReason"
                >
                    <mtd-select
                        v-model="refundForm.refundReason"
                        clearable
                        filterable
                    >
                        <mtd-option
                            v-for="refundReason in refundForm.selectableRefundReasons"
                            :key="refundReason"
                            :label="refundReason"
                            :value="refundReason"
                        />
                    </mtd-select>
                </mtd-form-item> -->
                <mtd-form-item :label="$t('order.orderDetail.remark')">
                    <mtd-textarea
                        v-model="refundForm.remark"
                        style="width: 260px;"
                        rows="3"
                        maxlength="50"
                    />
                </mtd-form-item>
            </mtd-form>
            <mtd-button
                :disabled="isDisableRefund"
                type="primary"
                @click="openRefundConfirm"
            >
                {{ $t('order.orderDetail.confirm') }}
            </mtd-button>
            <mtd-button @click="handleClickCancel">
                {{ $t('order.orderDetail.cancel') }}
            </mtd-button>
        </div>
    </div>
</template>

<script>
import dayjs from '@/common/dayjs';
import OrderServiceApi from '@/api/order/orderService';
import utils from '@/common/util';
import STATUS from '@/constants/status';
import { PRICE_UNIT, AREA_TYPE } from '@/constants/orderConstants';

export default {
    props: {
        userId: {
            type: String,
            default: '',
        },

        lendTime: {
            type: String,
            default: '',
        },

        payInfo: {
            type: Object,
            default: () => {},
        },

        orderLendInfo: {
            type: Object,
            default: () => {},
        },

        summaryData: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            refundForm: {
                consumptionDays: 0,
                consumptionHours: 0,
                consumptionMinutes: 0,
                consumptionSeconds: 0,
                returnTime: '',
                returnTimeOnShow: '--',
                selectableRefundReasons: [],
                refundReason: '',
                refundFee: '',
                remark: '',
            },
            rules: {
                // refundReason: { required: true, message: '必填' },
                refundFee: { required: true, message: this.$t('order.orderDetail.mustInput') },
            },
            refundTimes: 0,
            originComputedRefundFee: '',
            computedRefundFee: '',
            canRefundFee: '', // 当前可退款金额 支付-退款
            refundFee: '', // 当前退款总金额
        };
    },
    computed: {
        //  原支付金额
        originalFee() {
            const { originActualFee = 0 } = this.payInfo || {};
            return `${this.priceUnit} ${utils.formatPrice(originActualFee)}`;
        },

        isDisableRefund() {
            return false;
            // const { source } = this.payInfo || {};
            // const { payTime } = this.summaryData || {};

            // if (!payTime) return false;

            // const targetTime = dayjs(payTime).add(365 * 24, 'hour').unix();
            // return source === SOURCE.AlipayMp && targetTime < dayjs().unix();
        },

        priceUnit() {
            return PRICE_UNIT[this.summaryData.areaType || AREA_TYPE.Japan];
        },

        suspendInfo() {
            return this.orderLendInfo?.suspendInfo || {};
        },

        suspendStartTimeShow() {
            if (this.suspendInfo.canDeleteSuspend) {
                return dayjs(this.suspendInfo.suspendStartTime).format('YYYY-MM-DD HH:mm:ss');
            }
            return '';
        },
        suspendEndTimeShow() {
            if (this.suspendInfo.canDeleteSuspend) {
                return dayjs(this.suspendInfo.suspendEndTime).format('YYYY-MM-DD HH:mm:ss');
            }
            return '';
        },
    },
    watch: {
    },
    created() {
        this.initReturnInfoByLendInfo();
        // this.getSelectableRefundReasons();
        this.getReturnFeeInfo();
    },
    methods: {
        initReturnInfoByLendInfo() {
            this.refundForm.returnTimeOnShow = this.lendTime;
            this.refundForm.returnTime = dayjs(this.lendTime).valueOf();
        },

        // setConsumptionTime() {
        //     const {
        //         consumptionDays = 0, consumptionHours = 0, consumptionMinutes = 0, consumptionSeconds = 0,
        //     } = this.refundForm;

        //     const returnTime = dayjs(this.lendTime)
        //         .add(consumptionDays * 24, 'hour')
        //         .add(consumptionHours, 'hour')
        //         .add(consumptionMinutes, 'minute')
        //         .add(consumptionSeconds, 'second');

        //     this.refundForm.returnTime = returnTime.valueOf();
        //     this.refundForm.returnTimeOnShow = returnTime.format('YYYY-MM-DD HH:mm:ss');
        // },

        openRefundConfirm() { // 退款确认提示
            this.$refs.refundForm.validate((valid) => {
                if (!valid) return;

                // if (this.refundForm.returnTime > Date.now()) {
                //     this.$mtd.message({
                //         message: '归还时间必须小于当前时间',
                //         type: 'warning',
                //     });
                //     return;
                // }
                this.computedRefundFee = this.refundForm.refundFee;
                // 0元不支持退款
                if (this.computedRefundFee <= 0) {
                    this.$mtd.message({
                        message: this.$t('order.orderDetail.computedRefundFee'),
                        type: 'warning',
                    });
                    return;
                }

                let messageText = this.$t('order.orderDetail.refundConfirmMsg1', [this.priceUnit + this.computedRefundFee]);
                if (this.refundTimes > 0) {
                    messageText = this.$t('order.orderDetail.refundConfirmMsg2', [this.priceUnit + this.refundFee, this.priceUnit + this.computedRefundFee]);
                }

                this.$mtd.confirm({
                    title: this.$t('order.orderDetail.refundConfirm'),
                    message: messageText,
                    width: '430px',
                    type: 'warning',
                    showCancelButton: true,
                    onOk: () => {
                        // console.log(1111, this.refundForm1);
                        this.$emit('confirm-rebund', { ...this.refundForm }, this.originComputedRefundFee);
                    },
                });
            });
        },

        async getReturnFeeInfo() { // 获取应退金额已退金额信息
            try {
                const params = {
                    userId: this.userId,
                    orderId: this.$route.params.orderId,
                    // bizReturnTime: this.refundForm.returnTime, // 日本项目，没有归还时间，直接输入退款金额
                    // optType: 1,// 后端临时要求
                };
                const res = await OrderServiceApi.getReturnFee(params);
                const { data, code } = res;
                if (code === STATUS.SUCCESS && data) {
                    this.fee = utils.formatPrice(data.fee); // 人工还宝实收金额
                    this.refundTimes = data.refundTimes; // 退款次数
                    this.originComputedRefundFee = data.computedRefundFee;// 原始计算出来的退款金额，单位分
                    this.computedRefundFee = utils.formatPrice(data.computedRefundFee); // 计算出来的退款金额
                    this.canRefundFee = utils.formatPrice(data.canRefundFee); // 当前可退款金额支付-退款
                    this.refundFee = utils.formatPrice(data.refundFee); // 当前退款总金额
                }
            } catch (e) {
                // this.$mtd.message({
                //     message: 'error',
                //     type: 'warning',
                // });
            }
        },

        // async getSelectableRefundReasons() {
        //     try {
        //         const { data, status, message } = await OrderServiceApi.getRefundReasons();

        //         if (status !== STATUS.SUCCESS || !data) throw new Error(message);

        //         this.refundForm.selectableRefundReasons = data;
        //     } catch (e) {
        //         this.$mtd.message({
        //             message: e.message || '出现异常',
        //             type: 'error',
        //         });
        //     }
        // },

        handleClickCancel() {
            this.$emit('on-click-canel');
        },

        // ensureDelSuspend() {
        //     this.$mtd.confirm({
        //         title: this.$t('order.orderDetail.deleteStopCharge'),
        //         message: this.$t('order.orderDetail.deleteStopChargeConfirm'),
        //         width: '430px',
        //         type: 'warning',
        //         showCancelButton: true,
        //         onOk: () => {
        //             this.$emit('confirm-del-suspend');
        //         },
        //     });
        // },
    },
};
</script>

<style lang="less" scoped>
@import '../detail.less';
.cost-detail-table {
  td {
    border: solid 1px #DCDCDC;
    text-align: center;
    width: 120px;
  }
}
.del-suspend{
  font-weight: bolder;
  color: #0a70f5;
  margin-left: 20px;
  cursor: pointer;
}
</style>
