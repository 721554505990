
































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';
import agentSelect from '../common/agentSelect.vue';

@Component({
    components: {
        agentSelect,
    },
})
export default class agentList extends Vue {
    @State((state) => state.common.user) user: any;

    form = {
        deviceSn: '',
        belongAgentId: '',
        deviceType: '',
        deviceState: '',
        belongEmpId: '',
    }

    cabinVisable: boolean = false;

    batteryVisable: boolean = false;

    batchVisable: boolean = false;

    logVisable: boolean = false;

    tableData = [];

    cabinDetail: any = {};

    batteryDetail: any = {};

    total: number = 0;

    pagination = {
        pageNum: 1,
        pageSize: 10,
    }

    networkStatusMap = {
        0: this.$t('deviceAssetManagement.offline'),
        1: this.$t('deviceAssetManagement.online'),
        100: this.$t('deviceAssetManagement.offCabin'),
        101: this.$t('deviceAssetManagement.inCabin'),
    }

    deviceTypeMap = {
        1: this.$t('deviceAssetManagement.cabinet'),
        2: this.$t('deviceAssetManagement.battery'),
        3: this.$t('deviceAssetManagement.support'),
        0: this.$t('deviceAssetManagement.unkown'),
    };

    disableStatusMap = {
        0: this.$t('deviceAssetManagement.addrAble'),
        1: this.$t('deviceAssetManagement.addrDisable'),
    }

    deviceTypeOption = Object.keys(this.deviceTypeMap).filter((key: any) => key !== '0').map((key) => ({ value: key, label: this.deviceTypeMap[key] }));

    deviceStatusMap = {
        OUT_WAREHOUSE: this.$t('deviceAssetManagement.returnToWarehouse'),
        WAREHOUSE: this.$t('deviceAssetManagement.warehouse'),
        EN_ROUTE: this.$t('deviceAssetManagement.enRoute'),
        ENTER_SHOP_FINISH: this.$t('deviceAssetManagement.enterShopFinish'),
        LENDING: this.$t('deviceAssetManagement.lending'),
        // USER_SHOP: this.$t('deviceAssetManagement.userShop'),
        // USER_LOSS: this.$t('deviceAssetManagement.userLoss'),
        // REPAIR: this.$t('deviceAssetManagement.repairing'),
        // LOSS: this.$t('deviceAssetManagement.loss'),
        // SCRAP: this.$t('deviceAssetManagement.scrap'),
        // NOT_EXIST: this.$t('deviceAssetManagement.notExist'),
    };

    deviceStatusOption = Object.keys(this.deviceStatusMap).map((key) => ({ value: key, label: this.deviceStatusMap[key] }));

    logData = [];

    logTotal = 0;

    unlockOption = false;

    logPagination = {
        pageNum: 1,
        pageSize: 10,
    }

    logDeviceSn = '';

    targetSubject = null;

    fileList: any = []

    async created() {
        this.getTableData();
    }

    async getTableData() {
        Object.keys(this.form).forEach((key) => {
            if (this.form[key] === '') {
                this.form[key] = null;
            }
        });
        const { data } = await this.$request.get('/device-api/device/manage/v1/list', {
            params: {
                ...this.form,
                ...this.pagination,
            },
        });
        if (data && data.list) {
            this.tableData = data.list;
            this.total = data.total;
        } else {
            this.tableData = [];
            this.total = 0;
        }
    }

    async httpRequest(option: any) {
        const fileParam = new FormData();
        fileParam.append('file', option.file);
        const { data, success } = await this.$request.post('/device-api/device/manage/v1/upload', fileParam, {
            headers: {
                'Content-type': 'multipart/form-data',
            },
        });
        if (success && data) {
            this.fileList = [data];
            // this.poiDetail.poiUrl = data.fileUrl;
        } else {
            this.fileList = [];
        }
    }

    async exportData() {
        Object.keys(this.form).forEach((key) => {
            if (this.form[key] === '') {
                this.form[key] = null;
            }
        });
        const { error, data } = await this.$request.post('/device-api/device/manage/v1/export', {
            ...this.form,
        }, {
            timeout: 60 * 1000,
        });
        if (!error && data) {
            window.open(data);
        }
    }

    search() {
        this.pagination = {
            pageNum: 1,
            pageSize: 10,
        };
        this.getTableData();
    }

    reset() {
        this.form = {
            deviceSn: '',
            belongAgentId: '',
            deviceType: '',
            deviceState: '',
            belongEmpId: '',
        };
        (this.$refs.form as any).resetFields();
        this.getTableData();
    }

    change() {
        this.getTableData();
    }

    clearFiles() {

    }

    checkDetail(row: any) {
        if (row.deviceType === 2) {
            this.batteryVisable = true;
            this.getBatteryInfo(row.deviceSn);
        } else {
            this.cabinVisable = true;
            this.getCabinInfo(row.deviceSn);
        }
    }

    async getBatteryInfo(deviceSn: string) {
        const { success, data } = await this.$request.get('/device-api/device/manage/v1/battery-detail', {
            params: {
                batterySn: deviceSn,
            },
        });
        if (success && data) {
            this.batteryDetail = data;
        }
    }

    async returnToWarehouse() {
        if (!this.fileList.length) {
            this.$mtd.message.error(this.$t('deviceAssetManagement.batchReturnErrorTip') as any);
        }
        const { success, data } = await this.$request.post('/device-api/device/manage/v1/batch-out-warehouse', {
            targetAgentId: this.targetSubject,
            excelUrl: this.fileList.join(''),
        });
        if (success) {
            // this.$mtd.message.success(this.$t('deviceAssetManagement.batchOperationSuccess') as any);
            this.targetSubject = null;
            this.fileList = [];
            if (data) {
                this.actionConfirm(data);
            }
        }
    }

    async deviceTransfer() {
        if (!this.targetSubject || !this.fileList.length) {
            this.$mtd.message.error(this.$t('deviceAssetManagement.batchErrorTip') as any);
        }
        const { success, data } = await this.$request.post('/device-api/device/manage/v1/batch-transfer', {
            targetAgentId: this.targetSubject,
            excelUrl: this.fileList.join(''),
        });
        if (success) {
            // this.$mtd.message.success(this.$t('deviceAssetManagement.batchOperationSuccess') as any);
            this.targetSubject = null;
            this.fileList = [];
            if (data) {
                this.actionConfirm(data);
            }
        }
    }

    async batchWarehousing() {
        if (!this.targetSubject || !this.fileList.length) {
            this.$mtd.message.error(this.$t('deviceAssetManagement.batchErrorTip') as any);
        }
        const { success, data } = await this.$request.post('/device-api/device/manage/v1/batch-warehouse', {
            targetAgentId: this.targetSubject,
            excelUrl: this.fileList.join(''),
        });
        if (success) {
            // this.$mtd.message.success(this.$t('deviceAssetManagement.batchOperationSuccess') as any);
            this.targetSubject = null;
            this.fileList = [];
            if (data) {
                this.actionConfirm(data);
            }
        }
    }

    async getCabinInfo(deviceSn: string) {
        const { success, data } = await this.$request.get('/device-api/device/manage/v1/cabin-detail', {
            params: {
                cabinSn: deviceSn,
            },
        });
        if (success && data) {
            this.cabinDetail = data;
        }
    }

    async popAll(row: any) {
        let props: any = {};
        if (row && row.addrNum) {
            props = {
                popNum: 1,
                addrNum: row.addrNum,
            };
        }
        this.$mtd.confirm({
            className: 'pop-confirm',
            title: (this.$t('deviceAssetManagement.popOption')) as any,
            message: '',
            width: '430px',
            okButtonText: (this.$t('deviceAssetManagement.popUnlock')) as any,
            cancelButtonText: (this.$t('deviceAssetManagement.popLock')) as any,
            showCancelButton: true,
        }).then(() => {
            props.unlockOption = true;
            this.handlePop(props);
        }).catch((err) => {
            if (err.action === 'close') {
                return;
            }
            props.unlockOption = false;
            this.handlePop(props);
        });
    }

    actionConfirm(data: string) {
        this.$mtd.confirm({
            title: (this.$t('deviceAssetManagement.actionTitle')) as any,
            message: data,
            width: '430px',
            okButtonText: (this.$t('deviceAssetManagement.actionBtn')) as any,
            showCancelButton: false,
        });
    }

    async handlePop(props = {}) {
        const { error, data } = await this.$request.post('/device-api/device/manage/v1/batch-pop', {
            cabinSn: this.cabinDetail.cabinSn,
            ...props,
        });
        if (!error && data) {
            this.$mtd.message.success(this.$t('deviceAssetManagement.popUpSuccess') as any);
            this.getCabinInfo(this.cabinDetail.cabinSn);
        }
    }

    checkLog(row: any) {
        this.logDeviceSn = row.deviceSn;
        this.logPagination = {
            pageNum: 1,
            pageSize: 10,
        };
        this.logData = [];
        this.logTotal = 0;
        this.getLogTableData();
        this.logVisable = true;
    }

    async getLogTableData() {
        const { success, data } = await this.$request.get('/device-api/device/manage/trace/v1/list', {
            params: {
                deviceSn: this.logDeviceSn,
                ...this.logPagination,
            },
        });
        if (success && data) {
            this.logData = data.list;
            this.logTotal = data.total;
        }
    }

    downloadTemplate() {
        window.open('https://pc-cdn.mtcharge.jp/static/device-batch-operate-template.xlsx');
    }

    logPaginationChange() {
        this.getLogTableData();
    }

    batchOperation() {
        this.batchVisable = true;
    }
}
