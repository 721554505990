import Vue from 'vue';

import VueI18n from 'vue-i18n';

import MTD from '@ss/mtd-vue';

import { i18nAddResources } from '@wangeditor/editor';

import zhCN from '../config/lang/zh-CN';
import en from '../config/lang/en';
import ja from '../config/lang/ja';
import de from '../config/lang/de';

Vue.use(VueI18n);

export const langList = ['ja', 'zh-CN', 'en', 'de'];

const curLang = window.localStorage.getItem('locale') || window.navigator.language;

// 默认展示日语
const navLang = langList.includes(curLang) ? curLang : 'zh-CN';

const conf = {
    'zh-CN': { ...zhCN },
    en: { ...en },
    ja: { ...ja },
    de: { ...de },
};

// 编辑器
i18nAddResources('zh-CN', zhCN.editor);
i18nAddResources('en', en.editor);
i18nAddResources('de', de.editor);

(MTD as any).locale(conf[navLang]);

const i18n = new VueI18n({
    locale: navLang, // 设置地区
    messages: conf, // 设置地区信息
});

export default i18n;
