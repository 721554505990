











































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';

@Component({ components: {} })
export default class agentList extends Vue {
    @State((state) => state.common.user) user: any;

    @State((state) => state.common.uacMenusMap) uacMenusMap: any;

    form = {
        name: null,
        email: null,
        account: null,
    }

    columns = [
        { title: this.$t('poiAccount.merchantAccount'), dataIndex: 'account' },
        { title: this.$t('poiAccount.email'), dataIndex: 'email' },
        { title: this.$t('poiAccount.merchantName'), dataIndex: 'name' },
        { title: this.$t('poiAccount.cooperationStoreNumber'), dataIndex: 'bindNum' },
        { title: this.$t('poiAccount.bankName'), dataIndex: 'bankName' },
        { title: this.$t('poiAccount.branchName'), dataIndex: 'branchName' },
        { title: this.$t('poiAccount.accountType'), dataIndex: 'bankType' },
        { title: this.$t('poiAccount.bankAccount'), dataIndex: 'bankNumber' },
        { title: this.$t('poiAccount.accountName'), dataIndex: 'bankAccount' },
        { title: this.$t('poiAccount.withdrawableAmount'), dataIndex: 'balance' },
    ]

    infoVisable: boolean = false;

    tableData = [];

    accountInfo: any = {
        accountId: '',
        email: '',
        merchantName: '',
    };

    benefit: number | string = '';

    total: number = 0;

    pagination = {
        pageNum: 1,
        pageSize: 10,
    }

    rules = {
        account: [{ required: true, message: this.$t('poiAccount.pleaseEnterAccount'), trigger: 'blur' }],
        name: [{ required: true, message: this.$t('poiAccount.pleaseEnterName'), trigger: 'blur' }],
        email: [{
            required: true, pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, message: this.$t('poiAccount.pleaseEnterCorrectEmailFormat'), trigger: 'change',
        }],
        bankName: [{ required: true, message: this.$t('poiAccount.pleaseEnterBankName'), trigger: 'blur' }],
        branchName: [{ required: true, message: this.$t('poiAccount.pleaseEnterBranchName'), trigger: 'blur' }],
        bankType: [{
            required: true, type: 'number', message: this.$t('poiAccount.pleaseEnterBankType'), trigger: 'blur',
        }],
        bankAccount: [{ required: true, message: this.$t('poiAccount.pleaseEnterBankAccount'), trigger: 'blur' }],
        bankNumber: [{ required: true, message: this.$t('poiAccount.pleaseEnterBankNumber'), trigger: 'blur' }],
    }

    async created() {
        this.getTableData();
    }

    async getTableData() {
        Object.keys(this.form).forEach((key) => {
            if (this.form[key] === '') {
                this.form[key] = null;
            }
        });
        const { data } = await this.$request.get('/mapi/manager/account/list-account', {
            params: {
                ...this.form,
                ...this.pagination,
            },
        });
        if (data && data.data) {
            this.tableData = data.data;
            this.total = data.total;
        } else {
            this.tableData = [];
            this.total = 0;
        }
    }

    createAgent() {
        this.$router.push('/agent/create');
    }

    search() {
        this.pagination = {
            pageNum: 1,
            pageSize: 10,
        };
        this.getTableData();
    }

    change() {
        this.getTableData();
    }

    async edit(row: any) {
        const { data } = await this.$request.get('/mapi/manager/account/query-account-detail', {
            params: {
                id: row.id,
            },
        });
        this.infoVisable = true;
        this.accountInfo = data;
    }

    async changeInfo() {
        (this.$refs.accountInfo as any).validate(async (valid: boolean) => {
            if (valid) {
                const { data } = await this.$request.post('/mapi/manager/account/update-account', {
                    ...this.accountInfo,
                });
                if (data === true) {
                    this.infoVisable = false;
                    this.$mtd.message.success(this.$t('poiAccount.updateSuccess') as any);
                    this.getTableData();
                }
            } else {
                this.$mtd.message.error(this.$t('common.pleaseFillInCompletely') as any);
            }
        });
    }

    async reset(row: any) {
        const { data } = await this.$request.post('/mapi/manager/account/reset-password', {
            id: row.id,
        });
        if (data === true) {
            this.$mtd.message.success(this.$t('poiAccount.resendPasswordSuccess') as any);
        }
    }
}
