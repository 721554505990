import i18n from '../common/i18n';

export default [
    {
        deep: 2,
        iconName: 'avatar-group',
        menuURL: undefined,
        name: i18n.t('common.menu.agent'),
        nodeCode: 1,
        childItem: [{
            base: '/',
            childItem: [],
            deep: 2,
            hashPath: true,
            iconName: 'list-bulleted',
            menuURL: 'agent/list',
            name: i18n.t('common.menu.agentList'),
            nodeCode: 2,
            pathName: 'agent/list',
        },
        {
            base: '/',
            childItem: [],
            deep: 2,
            hashPath: true,
            iconName: 'list-numbered',
            menuURL: 'user/list',
            name: i18n.t('common.menu.EmployeeManagement'),
            nodeCode: 3,
            pathName: 'user/list',
        }],
    },
    {
        deep: 2,
        iconName: 'avatar-fill',
        menuURL: undefined,
        name: i18n.t('common.menu.orderManage'), //订单管理
        nodeCode: 38,
        childItem: [{
            base: '/',
            childItem: [],
            deep: 2,
            hashPath: true,
            iconName: 'list-bulleted',
            menuURL: 'order/list',
            name: i18n.t('common.menu.orderList'), //订单列表
            nodeCode: 39,
            pathName: 'order/list',
        }],
    },
    {
        deep: 2,
        iconName: 'location',
        menuURL: undefined,
        name: i18n.t('common.menu.store'),
        nodeCode: 12,
        childItem: [{
            base: '/',
            childItem: [],
            deep: 2,
            hashPath: true,
            iconName: 'table-fill',
            menuURL: 'poi/list',
            name: i18n.t('common.menu.storeList'),
            nodeCode: 14,
            pathName: 'poi/list',
        }, {
            base: '/',
            childItem: [],
            deep: 2,
            hashPath: true,
            iconName: 'original',
            menuURL: 'poi/type',
            name: i18n.t('common.menu.storeType'),
            nodeCode: 13,
            pathName: 'poi/type',
        }, {
            base: '/',
            childItem: [],
            deep: 2,
            hashPath: true,
            iconName: 'setting-fill',
            menuURL: 'account/list',
            name: i18n.t('common.menu.storeAccount'),
            nodeCode: 15,
            pathName: 'account/list',
        }],
    },
    {
        deep: 2,
        iconName: 'lock-fill',
        menuURL: undefined,
        name: i18n.t('common.menu.permit'),
        nodeCode: 26,
        childItem: [{
            base: '/',
            childItem: [],
            deep: 2,
            hashPath: true,
            iconName: 'table-fill',
            menuURL: 'permit/list',
            name: i18n.t('common.menu.permitList'),
            nodeCode: 27,
            pathName: 'permit/list',
        }],
    },
    {
        deep: 2,
        iconName: 'phone',
        menuURL: undefined,
        name: i18n.t('common.menu.device'),
        nodeCode: 31,
        childItem: [{
            base: '/',
            childItem: [],
            deep: 2,
            hashPath: true,
            iconName: 'table-fill',
            menuURL: 'asset/management',
            name: i18n.t('common.menu.asset'),
            nodeCode: 32,
            pathName: 'asset/management',
        }],
    },
    {
        deep: 2,
        iconName: 'formatbold',
        menuURL: undefined,
        name: i18n.t('common.menu.bill'),
        nodeCode: 33,
        childItem: [{
            base: '/',
            childItem: [],
            deep: 2,
            hashPath: true,
            iconName: 'serikeethrough',
            menuURL: 'benefit/index',
            name: i18n.t('common.menu.benefit'),
            nodeCode: 34,
            pathName: 'benefit/index',
        }, {
            base: '/',
            childItem: [],
            deep: 2,
            hashPath: true,
            iconName: 'table-fill',
            menuURL: 'cashout/log',
            name: i18n.t('common.menu.cashout'),
            nodeCode: 35,
            pathName: 'cashout/log',
        }],
    },
    {
        deep: 3,
        iconName: 'setting',
        menuURL: undefined,
        name: i18n.t('common.menu.setting'),
        nodeCode: 45,
        childItem: [{
            base: '/',
            childItem: [],
            deep: 2,
            hashPath: true,
            iconName: 'question-circle-o',
            menuURL: 'faq/list',
            name: i18n.t('common.menu.faq'),
            nodeCode: 46,
            pathName: 'faq/list',
        }, {
            base: '/',
            childItem: [],
            deep: 2,
            hashPath: true,
            iconName: 'bar-chart',
            menuURL: 'setting/material',
            name: i18n.t('common.menu.material'),
            nodeCode: 47,
            pathName: 'setting/material',
        }, {
            base: '/',
            childItem: [],
            deep: 2,
            hashPath: true,
            iconName: 'copy-o',
            menuURL: 'setting/price',
            name: i18n.t('common.menu.price'),
            nodeCode: 48,
            pathName: 'setting/price',
        }],
    },
];
