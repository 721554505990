import { Request, registGlobalResponseHook, registGlobalRequestHook } from '@mtfe/microx-axios';

import { Message } from '@ss/mtd-vue';

import config from './config';

import i18n from './i18n';

export const request = new Request({
    baseURL: config.baseURL,
    timeout: 30000,
});

registGlobalResponseHook([(res: any) => {
    if (res && res.headers && res.headers['manager-token']) {
        const token = res.headers['manager-token'];
        window.localStorage.setItem('manager-token', token);
        registGlobalRequestHook([(req: any) => {
            if (req && req.headers) {
                req.headers['manager-token'] = token;
            }
            return req;
        }]);
    }
    if (res && res.data) {
        const { code, success, msg } = res.data;
        if (code === 1006) {
            // 登陆失效,重新登陆
            window.location.href = '/login';
        } else if (!success) {
            Message.error(msg);
        }
    }
}]);

registGlobalRequestHook([(req: any) => {
    if (req && req.headers) {
        req.headers['accept-language'] = i18n.locale;
        req.headers['client-tag'] = config.AWS_DEPLOY_AREA;
    }
    return req;
}]);

export const registHook = (fns: Function[]) => {
    registGlobalRequestHook([...fns] as any);
};

export default Request;
