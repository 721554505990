


























import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
    },
})
export default class LoginIndex extends Vue {
    mounted() {
    }

    toAndroidPage() {
        window.location.href = 'https://www.pgyer.com/cXLX7c';
    }

    toIosPage() {
        window.location.href = 'https://www.pgyer.com/of7KgF';
    }
}
