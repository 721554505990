
















































































































































import { Component, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';

const getPermitId: any = (menus: any) => {
    const idList = [];
    for (let i = 0; i < menus.length; i++) {
        const item = menus[i];
        idList.push(item.id);
        if (item.children && item.children.length > 0) {
            idList.push(...getPermitId(item.children));
        }
    }
    return idList;
};

@Component({ components: {} })
export default class permitList extends Vue {
    @State((state) => state.common.uacMenusMap) uacMenusMap: any;

    @State((state) => state.common.user) user: any;

    columns = [
        // { title: this.$t('permitIndex.serialNumber'), dataIndex: 'id' },
        // { title: this.$t('permitIndex.roleName'), dataIndex: 'name' },
        // { title: this.$t('permitIndex.roleIdentification'), dataIndex: 'code' },
        // { title: this.$t('permitIndex.roleDescription'), dataIndex: 'desc' },
    ]

    addVisable: boolean = false;

    permitVisable: boolean = false;

    tableData = [];

    total: number = 0;

    permitConf = [];

    pagination = {
        pageNum: 1,
        pageSize: 10,
    }

    form = {
        name: '',
        code: '',
        desc: '',
    }

    rules = {
        name: [
            { required: true, message: this.$t('permitIndex.pleaseEnterRoleName'), trigger: 'blur' },
        ],
        code: [
            { required: true, message: this.$t('permitIndex.pleaseEnterRoleIdentification'), trigger: 'blur' },
        ],
    }

    isEdit: boolean = false;

    checkedPermit: any = [];

    permitRoleId = '';

    parentMap: any = {};

    async created() {
        this.getTableData();
    }

    createItem() {
        this.addVisable = true;
        this.isEdit = false;
    }

    async getTableData() {
        const { data } = await this.$request.get('/mapi/manager/role/list-role', {
            params: {
                ...this.pagination,
                agentId: this.user.agentId,
            },
        });
        if (data && data.data) {
            this.tableData = data.data;
            this.total = data.total;
        }
    }

    addPermit() {
        // eslint-disable-next-line consistent-return
        (this.$refs.form as any).validate(async (valid: boolean) => {
            if (valid) {
                this.submitPermit();
            } else {
                this.$mtd.message.error(this.$t('permitIndex.pleaseFillInCompletely') as any);
                return false;
            }
        });
    }

    async submitPermit() {
        const url = this.isEdit ? '/mapi/manager/role/update-role' : '/mapi/manager/role/create-role';
        const { success } = await this.$request.post(url, {
            ...this.form,
        });
        if (success === true) {
            this.$mtd.message.success(this.$t('permitIndex.saveSuccess') as any);
            (this.$refs.form as any).resetFields();
            this.addVisable = false;
            this.getTableData();
            this.form = {
                name: '',
                code: '',
                desc: '',
            };
        }
    }

    change() {
        this.getTableData();
    }

    edit(row: any) {
        this.form = { ...row };
        this.addVisable = true;
        this.isEdit = true;
    }

    resetForm() {
        (this.$refs.form as any).resetFields();
        this.addVisable = false;
        this.form = {
            name: '',
            code: '',
            desc: '',
        };
    }

    filterConf(conf: any) {
        for (let i = 0; i < conf.length; i++) {
            const item = conf[i];
            item.title = item.name;
            this.filterConf(item.children || []);
        }
        return conf;
    }

    async editPermit(row: any) {
        this.permitVisable = true;
        this.permitRoleId = row.id;
        this.checkedPermit = [];
        this.getPermitTree();
        this.getPermitNode(this.permitRoleId);
    }

    async getPermitTree() {
        const { success, data } = await this.$request.get('/mapi/manager/menu/list-menu-conf');
        if (success && data) {
            const conf = JSON.parse(data);
            this.permitConf = this.filterConf(conf);
            this.getParentNodeIdMap(conf, null);
        }
    }

    getParentNodeIdMap(conf: any, parentId: number | null) {
        const list = [...conf];
        while (list.length) {
            const item = list.pop();
            if (parentId) {
                this.parentMap[item.id] = parentId;
            }
            if (item.children) {
                this.getParentNodeIdMap(item.children, item.id);
            }
        }
    }

    async getPermitNode(roleId: any) {
        const { success, data } = await this.$request.get('/mapi/manager/menu/get-list-menu', {
            params: {
                roleId,
            },
        });
        if (success && data) {
            const conf = JSON.parse(data);
            const idList = getPermitId(conf);
            this.checkedPermit = [...idList];
        }
    }

    handleChekced(checkedId: any) {
        const list = [];
        const checkeds = [...checkedId];
        for (let i = 0; i < checkeds.length; i++) {
            const id = checkeds[i];
            list.indexOf(id) === -1 && list.push(id);
            const parentId = this.parentMap[id];
            if (parentId) {
                list.indexOf(parentId) === -1 && list.push(parentId);
                checkeds.push(this.parentMap[id]);
            }
        }
        this.checkedPermit = [...list];
    }

    async updatePermit() {
        const { success, data } = await this.$request.post('/mapi/manager/menu/conf-role-menu', {
            roleId: this.permitRoleId,
            menuIdList: this.checkedPermit,
        });
        if (success && data) {
            this.permitVisable = false;
            this.$mtd.message.success(this.$t('permitIndex.updateSuccess') as any);
        }
    }
}
