

























































































import {
    Component, Vue, Prop, Watch,
} from 'vue-property-decorator';

import { State } from 'vuex-class';

import { debounce } from '../common/utils';

@Component({
    components: {},
})
export default class AppHeader extends Vue {
    @Prop({
        type: Object,
        default: () => ({}),
    })
    headerInfo: any;

    @Prop({
        type: String,
        default: '',
    })
    activeNav: string;

    @Prop({
        type: Array,
        default: [],
    })
    navList: any[];

    @Prop({
        type: Boolean,
        default: false,
    })
    sideListVisible: boolean

    @State((state) => state.common.manageTowerPermission) manageTowerPermission: any;

    hideNavList: any[] = [];

    showHideMenu = false;

    timer = 0;

    get showEllipsisBtn() {
        return this.hideNavList.length > 0;
    }

    get activeName() {
        return this.activeNav;
    }

    set activeName(newVal: string) {
        this.$emit('changeNav', newVal);
    }

    @Watch('navList')
    onNavChange() {
        this.hideNavList.length === 0 && this.$nextTick(this.caculateMenu);
    }

    mounted() {
        window.onresize = debounce(this.caculateMenu, 200);
    }

    caculateMenu() {
        const { navList, hideNavList } = this;
        const { length } = navList;
        const navBoundRight = (this.$refs.navBar as any).getBoundingClientRect().right - 20;
        for (let i = length - 1; i >= 0; i--) {
            const _el = (this.$refs as any)[`menuItem${i}`][0].$el;
            const itemRect = _el.getBoundingClientRect();
            if (itemRect.right > navBoundRight) {
                const _index = hideNavList.findIndex(
                    (item: any) => navList[i].nodeCode === item.nodeCode,
                );
                if (_index === -1) {
                    hideNavList.unshift(navList[i]);
                }
                this.$set(navList[i], 'hide', true);
            } else {
                this.$set(navList[i], 'hide', false);
            }
        }
    }

    onMousemove() {
        this.timer && clearTimeout(this.timer);
        this.showHideMenu = true;
    }

    onMouseLeave() {
        this.timer = setTimeout(() => {
            this.showHideMenu = false;
        }, 200);
    }
}
