





























































































































































import { Component, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';
import config from '@/common/config';
import agentSelect from '../common/agentSelect.vue';

@Component({
    components: {
        agentSelect,
    },
})
export default class agentList extends Vue {
    @State((state) => state.common.user) user: any;

    @State((state) => state.common.uacMenusMap) uacMenusMap: any;

    form = {
        agentName: '',
        type: '',
        account: '',
        managerName: '',
    }

    isJpVersion: boolean = config.isJpVersion;

    columns = [
        { title: this.$t('agentList.agentId'), dataIndex: 'id', width: 100 },
        { title: this.$t('agentList.agentAccount'), dataIndex: 'account', width: 120 },
        { title: this.$t('agentList.superiorAgentName'), dataIndex: 'headAgentName', width: 140 },
        { title: this.$t('agentList.agentName'), dataIndex: 'name', width: 100 },
        { title: this.$t('agentList.type'), dataIndex: 'type', width: 100 },
        { title: this.$t('agentList.principalName'), dataIndex: 'managerName', width: 100 },
        { title: this.$t('agentList.principalPhoneNumber'), dataIndex: 'managerMobile', width: 140 },
        { title: this.$t('agentList.email'), dataIndex: 'email', width: 120 },
        { title: this.$t('agentList.belongingPlace'), dataIndex: 'address' },
        { title: this.$t('agentList.headCompanyProfitRatio'), dataIndex: 'benefit', width: 140 },
        { title: this.$t('agentList.bankName'), dataIndex: 'bankName' },
        { title: this.$t('agentList.branchName'), dataIndex: 'branchName' },
        { title: this.$t('agentList.accountType'), dataIndex: 'bankType' },
        { title: this.$t('agentList.bankAccount'), dataIndex: 'bankNumber' },
        { title: this.$t('agentList.accountName'), dataIndex: 'bankAccount' },
        { title: this.$t('agentList.withdrawableAmount'), dataIndex: 'balance', width: 100 },
        { title: this.$t('agentList.cooperationStatus'), dataIndex: 'coopStatus' },
        { title: this.$t('agentList.updateTime'), dataIndex: 'updateTime', width: 160 },
        { title: this.$t('agentList.createTime'), dataIndex: 'createTime', width: 160 },
    ]

    benfitVisable: boolean = false;

    tableData = [];

    benefitRow: any = {};

    benefit: number | string = '';

    total: number = 0;

    pagination = {
        pageNum: 1,
        pageSize: 10,
    }

    async created() {
        this.getTableData();
    }

    async getTableData() {
        Object.keys(this.form).forEach((key) => {
            if (this.form[key] === '') {
                this.form[key] = null;
            }
        });
        const { data } = await this.$request.get('/mapi/manager/agent/list-agent', {
            params: {
                ...this.form,
                ...this.pagination,
            },
        });
        if (data && data.data) {
            this.tableData = data.data;
            this.total = data.total;
        } else {
            this.tableData = [];
            this.total = 0;
        }
    }

    createAgent() {
        this.$router.push('/agent/create');
    }

    getBankTypeString(type: number) {
        if (type === 1) {
            return this.$t('agentCreate.ordinary');
        } else if (type === 2) {
            return this.$t('agentCreate.savings');
        } else {
            return type === null ? '-' : type;
        }
    }

    getTypeString(type: number) {
        if (type === 1) {
            return this.$t('agentList.headCompany');
        } else if (type === 2) {
            return this.$t('agentList.agent');
        } else {
            return type === null ? '-' : type;
        }
    }

    search() {
        this.pagination = {
            pageNum: 1,
            pageSize: 10,
        };
        this.getTableData();
    }

    change() {
        this.getTableData();
    }

    editProfitInfo(row: any) {
        this.benfitVisable = true;
        this.benefitRow = row;
        this.benefit = row.benefit;
    }

    editAgentInfo(row: any) {
        this.$router.push(`/agent/edit?id=${row.id}`);
    }

    async changeBenfit() {
        if (!/^(100|[1-9]?\d)$/.test(`${this.benefit}`)) {
            this.$mtd.message.error(this.$t('agentList.changeTip') as any);
            return;
        }
        const { success, data } = await this.$request.post('/mapi/manager/agent/update-benefit', {
            agentId: this.benefitRow.id,
            benefit: Number(this.benefit),
        });
        if (success && data) {
            this.benefitRow.benefit = this.benefit;
            this.benfitVisable = false;
            this.$mtd.message.success(this.$t('agentList.modificationSuccess') as any);
        }
    }
}
