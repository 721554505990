import Vue from 'vue';

import MTD from '@ss/mtd-vue';

import router from './router/index';

import App from './App.vue';

import store from './store/index';

import { login } from './common/boot';

// mtd sytles
import '@ss/mtd-vue/lib/theme2/index.css';

import i18n from './common/i18n';

import { request } from './common/request';

Vue.use(MTD);

Vue.prototype.$request = request;

const bootstrap = () => {
    new Vue({
        router,
        store,
        i18n,
        render: (h) => h(App),
    }).$mount('#app');
};

login().then(() => {
    bootstrap();
});
