import createUpload from '@ai/mss-upload-js';

import config from './config';

export const uploadFile = (fileParams) => {
    const {
        acceptTypes = [],
        onSuccess = (fileUrl) => { console.log(fileUrl); },
        onError = (errorMsg) => { console.log(errorMsg); },
        onStart = () => { console.log('You are going to upload file.'); },
        onProgress = () => {},
        isTest = !config.isProduction,
        file,
        hashMode = true,
        bucket = 'cdb-mall-order-list-bucket',
    } = fileParams;
    const params = {
        signatureUrl: '/api/sale_pb/upload/getPostToken',
        bucket,
        isTest,
        tenant_id: isTest ? 'mss_75eba3ca18e440f9bac5cf53a48c5acd' : 'mss_76c9ad6a0ad841d8a1f59c5d0c2a1c2d',
        accept: acceptTypes,
        file,
        onStart,
        hashMode,
        onSuccess(fileUrl) {
            onSuccess(fileUrl, file);
        },
        onProgress,
        onError,
    };
    const uploadInstance = createUpload(params, 1);
    uploadInstance.upload();
};

export default {
    uploadFile,
};
