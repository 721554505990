








































import {
    Component, Vue, Prop,
} from 'vue-property-decorator';
import { Mutation, State } from 'vuex-class';
import { indexTypes as types } from '../store/action-types';
import AppHeader from './AppHeader.vue';
import AppSide from './AppSide.vue';
// import { getUrlParam } from '../common/utils';

import menusConfig from '../config/menus';

const getPermitId: any = (menus: any) => {
    const idList = [];
    for (let i = 0; i < menus.length; i++) {
        const item = menus[i];
        idList.push(item.id);
        if (item.children && item.children.length > 0) {
            idList.push(...getPermitId(item.children));
        }
    }
    return idList;
};

@Component({
    components: {
        AppHeader,
        AppSide,
    },
})
export default class AppContainer extends Vue {
    @Mutation(types.GET_UAC_MENUS) getUacMenus: (munesMap: any) => void

    @Prop({
        type: Object,
        default: () => ({ layout: 'left' }),
    })
    menuInfo: any

    @Prop({
        type: Boolean,
        default: false,
    })
    isLogin: boolean

    @State((state) => state.common.sideListVisible) sideListVisible: boolean;

    get defaultDeep() {
        return this.menuInfo.layout === 'left-top' ? 0 : 1;
    }

    get defaultExpandedNames() {
        const result = [];
        let curNode = this.menuPathMap[this.$route.fullPath];
        while (curNode) {
            result.push(curNode.nodeCode);
            curNode = curNode.parentNode;
        }
        if (this.menuInfo.layout === 'left-top') {
            this.activeNav = result.length > 1 ? result.pop() : this.navList[0].nodeCode;
        }
        return result;
    }

    get showSideList() {
        return this.sideList.length > 0;
    }

    get sideList() {
        const { menuList, activeNav } = this;
        if (this.menuInfo.layout === 'left-top' && activeNav) {
            const pathItem = menuList.find((item: any) => item.nodeCode === activeNav);
            return [...pathItem.childItem];
        }
        return [...menuList];
    }

    headerInfo: any = {
        env: '',
        title: '',
    }

    navList: any[] = []

    activeNav = ''

    menuList: any[] = []

    menuPathMap: any = {}

    confNodeMap: any = {}

    contentCollapse: boolean = false

    permitIdList: any = [];

    async getMenus() {
    // 从UAC获取权限结构，当做菜单的结构
        // const { data: menus } = await this.getMenusByApi();
        /**
     * 从Portm获取配置菜单列表，可以覆盖除了code之外的显示信息。用于控制菜单
     * nodeCode: 与UAC的code关联
     * name: 菜单的名字
     * iconName: 菜单的icon
     * pathName: 菜单在自己的服务中的路径
     * base: 菜单的基本路径
     *       原运营平台的 base = /home/index.html#
     *       结算管理  base = /tob-operation/page
     *       计价管理  base = /price-web/page
     * 规则：
     * 1、只有在配置列表中的菜单才会被显示。
     * 2、如果子菜单显示，则自身也会显示。
     */
        // 先获取到配置的节点
        this.confNodeMap = {};

        if (this.isLogin) {
            return [];
        }

        const { success, data } = await this.$request.get('/mapi/manager/menu/list-menu-login');
        if (success && data) {
            try {
                const menuConf = JSON.parse(data);
                this.permitIdList = getPermitId(menuConf);
            } catch (err) {
                console.error(err);
            }
        }

        const menusItem = menusConfig;

        const permitMenus = [];

        const menuPathMapConf = {};

        for (let i = 0; i < menusItem.length; i++) {
            const firstItem = menusItem[i];
            const child = [...firstItem.childItem];

            firstItem.childItem = [];
            if (this.permitIdList.indexOf(firstItem.nodeCode) === -1) {
                // eslint-disable-next-line no-continue
                continue;
            }
            for (let j = 0; j < child.length; j++) {
                const item = child[j];
                if (this.permitIdList.indexOf(item.nodeCode) > -1) {
                    firstItem.childItem.push(item);
                    const {
                        name, nodeCode, menuURL, iconName = 'menus-o', base, pathName,
                    } = item;
                    const result = {
                        name,
                        nodeCode,
                        _nodeCode: nodeCode,
                        menuURL,
                        iconName,
                        base,
                        pathName,
                        parentNode: {
                            nodeCode: firstItem.nodeCode,
                        },
                    };
                    const path = result.base + (result.pathName || menuURL || nodeCode);
                    menuPathMapConf[path] = result;
                }
                this.confNodeMap[item.nodeCode] = item;
            }
            permitMenus.push(firstItem);
        }
        this.menuPathMap = menuPathMapConf;

        this.getUacMenus(this.permitIdList);
        return permitMenus;
    }

    changeNavBar(nodeCode: string) {
        const pathItem = this.menuList.find((item: any) => item.nodeCode === nodeCode);
        let pathNode = { ...pathItem };
        while (pathNode.childItem && pathNode.childItem.length > 0) {
            pathNode = pathNode.childItem[0];
        }
        this.routeSwitch(pathNode);
    }

    changeMenu(nodeCode: string) {
        const menuItem = this.confNodeMap[nodeCode];
        this.routeSwitch(menuItem);
    }

    routeSwitch(menuItem: any) {
        const {
            base, pathName,
        } = menuItem;
        const path = base + pathName;
        this.$router.push(path);
    }

    async mounted() {
        this.menuList = await this.getMenus();
        const { layout } = this.menuInfo;
        if (layout === 'left-top') {
            this.navList = [...this.menuList];
        }
        Object.assign(this.headerInfo, this.menuInfo);
    }

    onCollapse(isCollapse: boolean) {
        this.contentCollapse = isCollapse;
    }
}
