const { AWP_DEPLOY_ENV = 'local', AWS_DEPLOY_AREA = 'DE-1015' } = process.env;

const isProd = AWP_DEPLOY_ENV === 'production';

const isStaging = AWP_DEPLOY_ENV === 'staging';

const isLocal = AWP_DEPLOY_ENV === 'local';

const isJpVersion = AWS_DEPLOY_AREA === 'JP-HOOK';

const isCommonVersion = AWS_DEPLOY_AREA === 'DE-1015';

// eslint-disable-next-line no-nested-ternary
const baseURL = isProd ? 'https://pc-api.mtcharge.jp' : (isStaging ? 'https://st-pc-api.mtcharge-eu.com' : 'https://test-enter-api.mtcharge.jp');

// eslint-disable-next-line no-nested-ternary
const billBaseURL = isProd ? 'https://settle-api.mtcharge.jp' : (isStaging ? 'https://st-settle-api.mtcharge-eu.com' : 'https://test-settle-api.mtcharge.jp');

// eslint-disable-next-line no-nested-ternary
const faqBaseURL = isProd ? 'https://pc-api.mtcharge.jp' : (isStaging ? 'https://st-enter-api.mtcharge-eu.com' : 'https://test-enter-api.mtcharge.jp');

export default {
    AWP_DEPLOY_ENV,
    AWS_DEPLOY_AREA,
    isProd,
    isStaging,
    isLocal,
    baseURL,
    billBaseURL,
    faqBaseURL,
    isCommonVersion,
    isJpVersion,
};
