




































































































































































import { Component, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';

@Component({ components: {} })
export default class typeList extends Vue {
    @State((state) => state.common.uacMenusMap) uacMenusMap: any;

    form = {
        typeName: null,
        typeStatus: null,
    }

    detailForm: any = {
        typeName: '',
        typeStatus: 1,
        remarks: '',
    }

    columns = [
        { title: this.$t('poiType.name'), dataIndex: 'typeName' },
        { title: this.$t('poiType.remark'), dataIndex: 'remarks' },
        { title: this.$t('poiType.status'), dataIndex: 'typeStatus' },
        { title: this.$t('poiType.createTime'), dataIndex: 'createTime' },
    ]

    tableData = [];

    total: number = 0;

    isAdd: boolean = false;

    pagination = {
        pageNum: 1,
        pageSize: 10,
    }

    detailVisible: boolean = false;

    rules = {
        typeName: [
            { required: true, message: this.$t('poiType.pleaseEnterTypeName'), trigger: 'blur' },
        ],
        typeStatus: [
            {
                required: true, type: 'number', message: this.$t('poiType.pleaseEnterTypeStatus'), trigger: 'blur',
            },
        ],
    }

    async created() {
        this.getTableData();
    }

    async getTableData() {
        Object.keys(this.form).forEach((key) => {
            if (this.form[key] === '') {
                this.form[key] = null;
            }
        });
        const { data } = await this.$request.get('/mapi/manager/poi/list-poi-type', {
            params: {
                ...this.form,
                ...this.pagination,
            },
        });
        if (data && data.data) {
            this.tableData = data.data;
            this.total = data.total;
        } else {
            this.tableData = [];
            this.total = 0;
        }
    }

    edit(row: any) {
        this.isAdd = false;
        this.detailVisible = true;
        const {
            id, typeName, remarks, typeStatus,
        } = row;
        this.detailForm = {
            id,
            typeName,
            typeStatus,
            remarks,
        };
    }

    add() {
        this.isAdd = true;
        this.detailVisible = true;
        this.detailForm = {
            typeName: '',
            typeStatus: 1,
            remarks: '',
        };
    }

    async submit() {
        (this.$refs.form as any).validate(async (valid: boolean) => {
            if (valid) {
                const url = this.isAdd ? '/mapi/manager/poi/create-poi-type' : '/mapi/manager/poi/update-poi-type';
                const { success } = await this.$request.post(url, {
                    ...this.detailForm,
                });
                if (success === true) {
                    this.$mtd.message.success(this.$t('poiType.submitSuccess') as any);
                    this.detailVisible = false;
                    this.getTableData();
                }
            }
        });
    }

    search() {
        this.pagination = {
            pageNum: 1,
            pageSize: 10,
        };
        this.getTableData();
    }

    change() {
        this.getTableData();
    }
}
