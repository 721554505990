
























import {
    Component, Vue, Prop,
} from 'vue-property-decorator';

@Component({ components: {} })
export default class agentSelect extends Vue {
    @Prop({
        type: String || Number,
        default: '',
    })
    value: string;

    @Prop({
        type: String,
        default: 'id',
    })
    keyIds: string;

    @Prop({
        type: String,
        default: 'name',
    })
    keyNames: string;

    @Prop({
        type: String,
        default: '/mapi/manager/agent/query-agent-keyword',
    })
    url: string;

    @Prop({
        type: Boolean,
        default: true,
    })
    clearable: boolean;

    @Prop({
        type: Array,
        default: () => [],
    })
    defaultList: any;

    @Prop({
        type: Boolean,
        default: false,
    })
    disabled: boolean;

    loading: boolean = false;

    get selectList() {
        if (this.agentList.length) {
            return this.agentList;
        } else if (Array.isArray(this.defaultList) && this.defaultList.length) {
            return [...this.defaultList];
        }
        return [];
    }

    agentList: any = [];

    update(value: any) {
        this.$emit('input', value);
    }

    async remoteMethod(query: string) {
        if (query) {
            this.loading = true;
            const { data } = await this.$request.get(this.url, {
                params: {
                    keyword: query,
                },
            });
            this.agentList = data;
            this.loading = false;
        } else {
            this.agentList = [];
        }
    }
}
