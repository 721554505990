































import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
    name: 'MenuItemSelf',
})
export default class MenuItemSelf extends Vue {
    @Prop() node!: any
}
