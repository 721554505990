import { render, staticRenderFns } from "./AppSide.vue?vue&type=template&id=274b3899&scoped=true&"
import script from "./AppSide.vue?vue&type=script&lang=ts&"
export * from "./AppSide.vue?vue&type=script&lang=ts&"
import style0 from "./AppSide.vue?vue&type=style&index=0&id=274b3899&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "274b3899",
  null
  
)

export default component.exports